import React, { Component } from 'react';
// react plugin used to create switch buttons
import { push } from 'react-router-redux';
import { connect } from 'react-redux';
import { Button } from 'reactstrap';

class Botonera extends Component {
	constructor(props) {
		super(props);
		this.state = {
			classes: 'dropdown',
			darkMode: false,
		};
	}
	handleClick2 = () => {
		if (this.state.classes === 'dropdown') {
			this.setState({ classes: 'dropdown show' });
		} else {
			this.setState({ classes: 'dropdown' });
		}
	};
	handleActiveMode = () => {
		this.setState({
			darkMode: !this.state.darkMode,
		});
		document.body.classList.toggle('white-content');
	};

	goToUrl = url => {
		window.open(url, '_blank');
	};

	handlerClick(e) {
		const { goTo } = this.props;
		switch (e) {
			case 'cupones':
				goTo('/cupones');
				break;
			case 'events':
				goTo('/eventos');
				break;
			case 'pa':
				goTo('/paseos');
				break;
			case 'playas':
				goTo('/playas');
				break;
			case 'alojamiento':
				goTo('/alojamientos');
				break;
			case 'gastronomia':
				goTo('/gastronomia');
				break;
			case 'guia':
				goTo('/guia-comercial');
				break;
			case 'mapa':
				goTo('/mapa');
				break;
			case 'inmobiliarias':
				goTo('/inmobiliarias');
				break;
			case 'home':
				goTo('/');
				break;
			default:
				return;
		}
	}

	render() {
		return (
			<div
				className="fixed-plugin"
				style={{ background: 'none', backgroundColor: 'rgba(0,0,0,0.8)', width: '100px'}}
			>
				<div className={this.state.classes} style={{marginTop:"20px"}}>
					<a
						href="#matias"
						onClick={e => {
							e.preventDefault();
							this.handleClick2();
						}}
					>
					<h2>
						<i className="tim-icons icon-align-center visible-on-sidebar-mini text-success" />
                  		<i className="tim-icons icon-bullet-list-67 visible-on-sidebar-regular text-success"/>
					</h2>
					<h6 style={{marginTop:"-20px"}}>que buscas?</h6>
					</a>
					<ul
						className="dropdown-menu show"
						style={{
							width: '220px',
							background: 'none',
							backgroundColor: 'rgba(0,0,0,0.8)',
							marginRight: '30px',
						}}
					>
						<li className="button-container" style={{height: '60px'}}>
							<Button
								className="btn-simple"
								style={{ border: 'none', height: '80px', width: '200px', margin: '0px' }}
								onClick={e => {
									this.handlerClick('home');
								}}
							>
								<img
									alt=""
									src="http://appsuy.com/images_vivi_piria/news-icon.png"
									style={{ width: '50px', height: '50px', float: 'left' }}
								/>
								<h6 style={{ paddingTop: '20px', float: 'left', paddingLeft: '10px' }}>Inicio</h6>
							</Button>
						</li>
                        <li className="button-container" style={{height: '60px'}}>
						<Button
							className="btn-simple"
							style={{ border: 'none', height: '80px', width: '200px', margin: '0px' }}
							onClick={e => {
								this.handlerClick('cupones');
							}}
						>
							<img
								alt=""
								src="http://appsuy.com/images_vivi_piria/cupones_icon.png"
								style={{ width: '50px', height: '50px', float: 'left' }}
							></img>
							<h6 style={{ paddingTop: '20px', float: 'left', paddingLeft: '10px' }}>Cupones</h6>
						</Button>
</li>
						<li className="button-container" style={{height: '60px'}}>
							<Button
								className="btn-simple"
								style={{ border: 'none', height: '80px', width: '200px', margin: '0px' }}
							onClick={e => {
								this.handlerClick('events');
							}}
						>
							<img alt="" src="http://appsuy.com/images_vivi_piria/eventos_icon.png" 
                            style={{ width: '50px', height: '50px', float: 'left' }}
                            />
							<h6 style={{ paddingTop: '20px', float: 'left', paddingLeft: '10px' }}>Eventos</h6>
						</Button>
                        </li>
                        <li className="button-container" style={{height: '60px'}}>
							<Button
								className="btn-simple"
								style={{ border: 'none', height: '80px', width: '200px', margin: '0px' }}
							onClick={e => {
								this.handlerClick('pa');
							}}
						>
							<img alt="" src="http://appsuy.com/images_vivi_piria/paseos_posta.png"  style={{ width: '50px', height: '50px', float: 'left' }}
                            />
							<h6 style={{ paddingTop: '20px', float: 'left', paddingLeft: '10px' }}>Paseos</h6>
						</Button>
</li>
						<li className="button-container" style={{height: '60px'}}>
						<Button
							className="btn-simple"
							style={{ border: 'none', height: '80px', width: '200px', margin: '0px' }}
							onClick={e => {
								this.handlerClick('playas');
							}}
						>
							<img alt="" src="http://appsuy.com/images_vivi_piria/paseos_icon.png" style={{ width: '50px', height: '50px', float: 'left' }}
                            />
							<h6 style={{ paddingTop: '20px', float: 'left', paddingLeft: '10px' }}>Playas</h6>
						</Button>
</li>
						<li className="button-container" style={{height: '60px'}}>
						<Button
							className="btn-simple"
							style={{ border: 'none', height: '80px', width: '200px', margin: '0px' }}
							onClick={e => {
								this.handlerClick('alojamiento');
							}}
						>
							<img
								alt=""
								src="http://appsuy.com/images_vivi_piria/alojamiento-icon.png"
								style={{ width: '50px', height: '50px', float: 'left' }}
                            />
							<h6 style={{ paddingTop: '20px', float: 'left', paddingLeft: '10px' }}>Alojamiento</h6>
						</Button>
</li>
						<li className="button-container" style={{height: '60px'}}>
						<Button
							className="btn-simple"
							style={{ border: 'none', height: '80px', width: '200px', margin: '0px' }}
							onClick={e => {
								this.handlerClick('gastronomia');
							}}
						>
							<img
								alt=""
								src="http://appsuy.com/images_vivi_piria/gastronomia_icon.png"
								style={{ width: '50px', height: '50px', float: 'left' }}
                            />
							<h6 style={{ paddingTop: '20px', float: 'left', paddingLeft: '10px' }}>Gastronomia</h6>
						</Button>
</li>
						<li className="button-container" style={{height: '60px'}}>
						<Button
							className="btn-simple"
							style={{ border: 'none', height: '80px', width: '200px', margin: '0px' }}
							onClick={e => {
								this.handlerClick('guia');
							}}
						>
							<img alt="" src="http://appsuy.com/images_vivi_piria/guia-icon.png" style={{ width: '50px', height: '50px', float: 'left' }}
                            />
							<h6 style={{ paddingTop: '20px', float: 'left', paddingLeft: '10px' }}>Comercios</h6>
						</Button>
</li>
						<li className="button-container" style={{height: '60px'}}>
						<Button
							className="btn-simple"
							style={{ border: 'none', height: '80px', width: '200px', margin: '0px' }}
							onClick={e => {
								this.handlerClick('inmobiliarias');
							}}
						>
							<img
								alt=""
								src="http://appsuy.com/images_vivi_piria/inmobiliaria-icon.png"
								style={{ width: '50px', height: '50px', float: 'left' }}
                            />
							<h6 style={{ paddingTop: '20px', float: 'left', paddingLeft: '10px' }}>Inmobiliarias</h6>
						</Button>
</li>
						<li className="button-container" style={{height: '80px'}}>
						<Button
							className="btn-simple"
							style={{ border: 'none', height: '80px', width: '200px', margin: '0px' }}
							onClick={e => {
								this.handlerClick('mapa');
							}}
						>
							<img alt="" src="http://appsuy.com/images_vivi_piria/mapa-icon.png" style={{ width: '50px', height: '50px', float: 'left' }}
                            />
							<h6 style={{ paddingTop: '20px', float: 'left', paddingLeft: '10px' }}>Mapa</h6>
						</Button>
                        </li>
					</ul>
				</div>
			</div>
		);
	}
}

const mapStateToProps = state => {
	//console.log(JSON.stringify(state));
	return {
		...state.reducers.homeReducer,
	};
};

const mapDispatchToProps = dispatch => {
	return {
		goTo: path => {
			dispatch(push(path));
		},
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(Botonera);
