import React from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';

import { Button, Card, CardBody, CardFooter, Container, Row, Col, Input } from 'reactstrap';
import Switch from 'react-bootstrap-switch';
import { getNoticias } from '../../actions/';
import { push } from 'react-router-redux';
import AuthNavbar from '../../components/Navbars/AuthNavbar';
import SliderImagenes from '../../views/components/SliderImagenes.jsx';
import Footer from '../../components/Footer/Footer';
class Eventos extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			buscar: '',
			categoria: { value: 'Paseos', label: 'Todas' },
			orderBy: 'desc',
			orderByValue: false,
			scrolling: false,
			page:1
		};
	}

	componentDidMount() {
		this.props.getNoticias();
	}

	componentWillMount() {
		this.scrollListener = window.addEventListener('scroll',(e)=>{
			this.handleScroll(e);
		})
	}

	handleScroll= (e) =>{
		const {scrolling} = this.state;
		if(scrolling) return;

		const lastLi = document.querySelector('div > div:last-child');
		const lastLiOffset = lastLi.offsetTop + lastLi.clientHeight;
		const pageOffset  = window.pageYOffset  + window.innerHeight;
		const bottomOffset = 350;
		if(pageOffset > lastLiOffset - bottomOffset) this.loadMore();

	}
	loadMore= () =>{
		this.setState(prevState=>({
			page:prevState.page + 1,
			scrolling:true,
		}),this.loadMoreResult);
	}

	loadMoreResult=()=>{
		this.setState({
			scrolling:false,
		});
	}


	handlerOnChange(e) {
		this.setState({ ...this.state, buscar: e.target.value });
	}
	handlerCategoria(value) {
		console.log(value);
		this.setState({ ...this.state, categoria: value });
	}

	handlerOrderBy(value) {
		if (value) {
			this.setState({ ...this.state, orderBy: 'desc', orderByValue: true });
		} else {
			this.setState({ ...this.state, orderBy: '', orderByValue: false });
		}
	}
	reemplazarAcentos = cadena => {
		const chars = {
			á: 'a',
			é: 'e',
			í: 'i',
			ó: 'o',
			ú: 'u',
			à: 'a',
			è: 'e',
			ì: 'i',
			ò: 'o',
			ù: 'u',
			ñ: 'n',
			Á: 'A',
			É: 'E',
			Í: 'I',
			Ó: 'O',
			Ú: 'U',
			À: 'A',
			È: 'E',
			Ì: 'I',
			Ò: 'O',
			Ù: 'U',
			Ñ: 'N',
		};
		const expr = /[áàéèíìóòúùñ]/gi;
		const res = cadena.replace(expr, e => {
			return chars[e];
		});
		return res;
	};

	handlerEvent = x => {
		this.setState({ ...this.state, selected: x });
		this.props.changeToEventosById(x);
	};

	eventos() {
		const { noticias } = this.props;
		const soloEventos = _.filter(noticias, x => x.tipo.includes('Even'));
		const filterByStatus = _.filter(soloEventos, x => {
			const noticia = x.status ? x : null;
			return noticia;
		});
		const filterByTextNombre = _.filter(filterByStatus, x =>
			this.reemplazarAcentos(x.titulo.toLowerCase()).includes(
				this.reemplazarAcentos(this.state.buscar.toLowerCase())
			)
		);
		const flOrder = this.state.orderByValue
			? _.orderBy(filterByTextNombre, 'rating', this.state.orderBy)
			: filterByTextNombre;

		const listNoticiasLoader = _.take(flOrder , (this.state.page * 9));

		const listNoticias = _.map(listNoticiasLoader, x => {

			return (
				<div className="ml-auto mr-auto text-center" key={x._id}>
					<Card
						className="card-pricing card-primary card-black"
						style={{
							width: '340px',
							height: '450px',
							margin: '20px',
							backgroundColor: '#161619',
							backgroundImage: 'url("http://appsuy.com/images_vivi_piria/bg.png")',
						}}
					>
						<SliderImagenes imagenes={x.imagen} event={true} padding={"80px"}/>
						<div
							style={{
								float: 'right',
								marginTop: '-41px',
								height: '42px',
							}}
						>
							<Button className="btn-simple" color="warning" style={{ border: '0px' }}>
								<i className={'tim-icons icon-shape-star'} style={{ fontSize: '15px' }} />
								{x.rating}
							</Button>
							<Button className="btn-simple" color="success" style={{ border: '0px' }}>
								<i className={'tim-icons icon-tap-02'} style={{ fontSize: '15px' }} />
								{x.cantidadVotos}
							</Button>
						</div>
						<CardBody>
							<h4 style={{ margin: '10px' }}>
								<b>{x.titulo}</b>
							</h4>
							<p style={{ padding: '3px' }}>{_.truncate(x.descripcionCorta,{'length': 80, 'separator': ' ...'})}</p>
						</CardBody>
						<CardFooter>
							<div style={{ marginTop: '-30px' }}>
								<Button
									className="btn-simple"
									color="success"
									style={{ borderRadius: '5px', margin: '10px' }}
									onClick={e => {
										this.handlerEvent(x);
									}}
								>
									MÁS INFORMACIÓN
								</Button>
							</div>
						</CardFooter>
					</Card>
				</div>
			);
		});
		return listNoticias;
	}

	render() {
		return (
			<div>
				<AuthNavbar />
				<Container fluid>
					<Row className="ml-auto mr-auto text-center" md="12">
						<Col className="ml-auto mr-auto text-center" md="3" style={{ marginTop: '0px' }}>
							<h4 className="title text-left">
								<img
									alt="Paseos piriapolis , que ver , que hacer en piriapolis "
									src="http://appsuy.com/images_vivi_piria/eventos_icon.png"
									width="50px"
								/>
								Eventos en Piriápolis
							</h4>

							<div style={{ marginLeft: '60px', marginTop: '15px' }}>
								<h6 className="title text-left text-warning">
									{this.state.buscar === '' ? '' : 'Resultados busqueda : ' + this.state.buscar}{' '}
								</h6>
							</div>
						</Col>
						<Col className="ml-auto mr-auto text-left" md="3" sm="3" style={{ marginTop: '15px' }}></Col>
						<Col className="ml-auto mr-auto text-left" md="3" sm="3" style={{ marginTop: '15px' }}></Col>
						<Col className="ml-auto mr-auto text-left" md="3" sm="3" style={{ marginTop: '15px' }}>
							<h6>Buscar Evento</h6>
							<Input
								className="success"
								placeholder="BUSCAR..."
								onChange={e => {
									this.handlerOnChange(e);
								}}
								style={{ borderColor: '#0AF4E9' }}
							/>
						</Col>
					</Row>
					<Row className="ml-auto mr-auto text-center" md="12">
						<Col className="ml-auto mr-auto text-left" md="6" sm="6"></Col>
						<Col className="ml-auto mr-auto text-right" md="6" sm="6">
							<div className="title text-right" style={{ marginRight: '5px', marginTop: '10px' }}>
								<h6>Ordenar por mejor calificación</h6>
								<Switch
									value={this.state.orderByValue}
									offColor=""
									offText={<i className="tim-icons icon-simple-remove" />}
									onColor=""
									onText={<i className="tim-icons icon-check-2" />}
									onChange={e => this.handlerOrderBy(e.value())}
								/>
							</div>
						</Col>
					</Row>
					<Row className="ml-auto mr-auto text-center" style={{ marginTop: '10px' }}>
						{this.eventos()}
					</Row>
				</Container>
			<Footer />
			</div>
		);
	}
}

const mapStateToProps = state => {
	//console.log(JSON.stringify(state));
	return {
		...state.reducers.homeReducer,
	};
};

const mapDispatchToProps = dispatch => {
	return {
		changeToEventosById: x => {
			dispatch(push('/evento/' + x._id));
		},
		getNoticias: () => {
			dispatch(getNoticias());
		},
		goTo: path => {
			dispatch(push(path));
		},
	};
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(Eventos);
