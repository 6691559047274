import axios from "axios";

function client() {
  let token = localStorage.getItem("token");
  if(!token || token===""){
    token = localStorage.getItem("tokenEmpresa");
  }
  const axiosInstance = axios.create({
    // eslint-disable-next-line no-undef
    headers: {
      "Authorization": "Bearer " + token,
      "Pragma": "no-cache",
      "Cache-Control": "no-cache"
    }
  });
  axiosInstance.interceptors.response.use(
    response => { return response; },
    error => {
      if (
        error.request.responseType === "blob" &&
          error.response.data instanceof Blob &&
          error.response.data.type &&
          error.response.data.type.toLowerCase().indexOf("json") !== -1
      )
      {
        // eslint-disable-next-line no-undef
        return new Promise((resolve, reject) => {
          let reader = new FileReader();
          reader.onload = () => {
            error.response.data = JSON.parse(reader.result);
            // eslint-disable-next-line no-undef
            resolve(Promise.reject(error));
          };

          reader.onerror = () => {
            reject(error);
          };

          reader.readAsText(error.response.data);
        });
      }

      // eslint-disable-next-line no-undef
      return Promise.reject(error);
    }
  );
  return axiosInstance;
}

export default client;
