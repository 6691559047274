import React from 'react';
import { connect } from 'react-redux';
import { getMensajes , changeStatusMensaje, getMensajesEnviados } from '../../actions/';
import Footer from '../../components/Footer/Footer';
import AuthNavbar from '../../components/Navbars/AuthNavbar';
import _ from 'lodash';
//import UploadImages from './UploadImages.jsx';
// reactstrap components
import {
	Container,
	Row,
	Col,
} from 'reactstrap';

class Mensajes extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
            mensajes:{},
            mensajesEnviados:{},
            user:{},
            empresa:{}
		};
	}


    static getDerivedStateFromProps(nextProps, prevState) {
		if (nextProps.mensajes !== prevState.mensajes) {
			const mensajes = _.reverse(nextProps.mensajes);
			return { mensajes: mensajes };
        } else if (nextProps.mensajesEnviados !== prevState.mensajesEnviados) {
			const mensajesEnviados = _.reverse(nextProps.mensajesEnviados);
			return { mensajesEnviados: mensajesEnviados };
        }
        else if (nextProps.user !== prevState.user) {
			const user = nextProps.user;
			return { user: user };
        }
        else if (nextProps.empresa !== prevState.empresa) {
			const empresa = nextProps.empresa;
			return { empresa: empresa };
		}
        else return null;
	}

	componentDidUpdate(prevProps, prevState) {
		if (prevProps.mensajesEnviados !== this.props.mensajesEnviados) {
			const mensajesEnviados = _.reverse(this.props.mensajesEnviados);
			this.setState({...this.state, mensajesEnviados: mensajesEnviados });
		}else if (prevProps.mensajes !== this.props.mensajes) {
			const mensajes = _.reverse(this.props.mensajes);
			this.setState({...this.state, mensajes: mensajes });
        }else if (prevProps.user !== this.props.user) {
			const user = this.props.user;
			this.setState({...this.state, user: user });
        }
        else if (prevProps.empresa !== this.props.empresa) {
			const empresa = this.props.empresa;
			this.setState({...this.state, empresa: empresa });
        }
    }
    
    componentDidMount(){
        console.log('datos');
        this.loadMessages();
        
    }
    loadMessages(){
        const { user ,empresa, getMensajesEnviados, getMensajes} = this.props;
        const id = !_.isEmpty(user) ? user._id : !_.isEmpty(empresa) ?empresa._id :"";
        if(!id) return;

        getMensajesEnviados(id);
        getMensajes(id);

    }

    MustroMensajes = () =>{
        return <h6>Lista Mensajes</h6>
    }

	
	render() {
        console.log(this.state);
		return (
			<>
				<AuthNavbar />
				<Container>
					<Row className="ml-auto mr-auto text-center">
						<Col md="12">
							<div style={{ paddingTop: '30px' }}>
								<img
									alt="..."
									src={'http://appsuy.com/images_vivi_piria/icono_vivipiria_130.png'}
									width="80px"
								/>
							</div>
							<div>
								<h3 className="info-title">Mensajes</h3>
							</div>
						</Col>
					</Row>
				</Container>
				<div className="content" style={{ marginTop: '30px' }}>
					<Container>
                    {this.MustroMensajes()}
					</Container>
				</div>
				<Footer />
			</>
		);
	}
}




const mapStateToProps = state => {
	return {
		...state.reducers.homeReducer,
	};
};

const mapDispatchToProps = dispatch => {
	return {
        getMensajes:(x)=>{
            dispatch(getMensajes(x));
          },
        getMensajesEnviados:(x)=>{
            dispatch(getMensajesEnviados(x));
          },
        changeStatusMensaje:(x)=>{
            dispatch(changeStatusMensaje(x));
          }  
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(Mensajes);