/*eslint-disable*/
import React from "react";
import { Container, Row, Col,Button } from "reactstrap";
// used for making the prop types of this component
import PropTypes from "prop-types";

class Footer extends React.Component {

  goToUrl = url => {
		window.open(url, '_blank');
  };
  
  render() {
    return (
      <footer
        className={"footer" + (this.props.default ? " footer-default" : "")} style={{marginTop:"200px",  backgroundColor:"rgba(0, 0, 0, 0.6)"}}
      >
        <Container fluid={true}>
        <Row className="ml-auto mr-auto text-left" md="12">
        <Col className="ml-auto mr-auto text-left" md="3">
              <h6>Contacto</h6>
              <p>contacto@vivipiria.uy</p>
              <p>
              <img
											alt=""
											src={'http://appsuy.com/images_vivi_piria/icono-whatsapp.png'}
											width="30px"
										/>
              <a href={'https://api.whatsapp.com/send?phone=59892826776'} target={'_blank'}>
											Contacto via WhatsApp
										</a>
                  </p>
              <Button
										className="btn-icon btn-simple"
										color="facebook"
										onClick={e => {
											this.goToUrl('https://www.facebook.com/vivipiriapolis/');
										}}
									>
										<i className="fab fa-facebook-square ml-auto" />
									</Button>
									
									<Button
										className="btn-icon btn-simple"
										color="primary"
										onClick={e => {
											this.goToUrl('https://www.instagram.com/vivipiria/');
										}}
									>
										<i className="fab fa-instagram ml-auto mr-auto" />
									</Button>
                  <Button
										className="btn-icon btn-simple"
                    style={{color:"red",borderColor:"red"}}
										onClick={e => {
											this.goToUrl('#');
										}}
									>
										<i className="fab fa-youtube ml-auto mr-auto" />
									</Button>
            </Col>
          <Col className="ml-auto mr-auto text-left" md="3">
          <h6>Sobre Vivipiria.uy</h6>
            <div>
            <a href="https://vivipiria.uy/politicas-de-privacidad" target="_self"> Politicas de Privacidad</a>
            </div>
            <div>
            <a href="https://vivipiria.uy/terminos-y-condiciones" target="_self"> Terminos y condiciones</a>
            </div>
            <div>
            <a href="#" target="_self"> Sugerencias</a>
            </div>
            <div>
            <a href="#" target="_blank"> Que es Vivipiria.uy</a>
            </div>
            <div>
            <a href="#" target="_blank"> Trabaja con nosotros</a>
            </div>
            </Col>
            <Col className="ml-auto mr-auto text-left" md="3"> 
            <h6>Sumá tu empresa a Vivipiria!</h6>
            <div>
            <a href="https://vivipiria.uy/registrar-empresa" target="_blank"> Sumate aquí! </a>
            </div>
            <br/>
            <h6>Como funciona Vivipiria?</h6>
            <div>
            <a href="#" target="_blank"> Empresas asociadas</a>
            </div>
            <div>
            <a href="#" target="_blank"> Usuarios generales</a>
            </div>
            </Col>
            <Col className="ml-auto mr-auto text-left" md="3">
           {/*  <h6>Descarga nuestra App </h6>
            <p>Una ves descargada puede funcionar 100% offline y es totalmente GRATIS!</p>
            <Button
										className="btn-simple"
										style={{ padding: '0px', border: '0' }}
										onClick={e => {
											console.log('link  apple');
										}}
									>
										<img
											alt="Piriápolis vivipiria apple store"
											src="http://appsuy.com/images_vivi_piria/apple.png"
											width="120px"
										/>
									</Button>
									<Button
										className="btn-simple"
										style={{ padding: '0px', border: '0' }}
										onClick={e => {
											this.goToUrl(
												'https://play.google.com/store/apps/details?id=com.appsuy.vivipiria'
											);
										}}
									>
										<img
											alt="Piriápolis vivipiria google play"
											src="http://appsuy.com/images_vivi_piria/googleplayLogo.png"
											width="120px"
										/>
									</Button> */}
            </Col>
          </Row>
        </Container>
        <Container fluid={this.props.fluid ? true : false}>
          <div className="copyright">
            © {new Date().getFullYear()} made with{" "}
            <i className="tim-icons icon-heart-2" /> by{" "}
            <a href="http://appsuy.com" target="_blank">
              AppsUY
            </a>{" "}
            for a better web.
          </div>
        </Container>
      </footer>
    );
  }
}

Footer.propTypes = {
  default: PropTypes.bool,
  fluid: PropTypes.bool
};

export default Footer;
