import React from 'react';
import moment from 'moment';
import { connect } from 'react-redux';
import Weather from 'simple-react-weather';
import { Container, Col, Row, Card } from 'reactstrap';

const imagesText = [
	{ title: 'texto 1', texto: ' description 1', image: 'http://appsuy.com/images_vivi_piria/slider/1.jpg' },
	{ title: 'texto 2', texto: ' description 2 ', image: 'http://appsuy.com/images_vivi_piria/slider/2.jpg' },
	{ title: 'texto 3', texto: 'description 3', image: 'http://appsuy.com/images_vivi_piria/slider/3.jpg' },
	{ title: 'texto 4', texto: 'description 4', image: 'http://appsuy.com/images_vivi_piria/slider/4.jpg' },
	{ title: 'texto 5', texto: 'description 5', image: 'http://appsuy.com/images_vivi_piria/slider/5.jpg' },
	{ title: 'texto 6', texto: 'description 6', image: 'http://appsuy.com/images_vivi_piria/slider/5.jpg' },
	{ title: 'texto 7', texto: 'description 7', image: 'http://appsuy.com/images_vivi_piria/slider/6.jpg' },
	{ title: 'texto 8', texto: 'description 8', image: 'http://appsuy.com/images_vivi_piria/slider/7.jpg' },
	{ title: 'texto 9', texto: 'description 9', image: 'http://appsuy.com/images_vivi_piria/slider/8.jpg' },
	{ title: 'texto 10', texto: 'description 10', image: 'http://appsuy.com/images_vivi_piria/slider/9.jpg' },
	{ title: 'texto 11', texto: 'description 11', image: 'http://appsuy.com/images_vivi_piria/slider/10.jpg' },
	{ title: 'texto 12', texto: 'description 12', image: 'http://appsuy.com/images_vivi_piria/slider/11.jpg' },
	{ title: 'texto 13', texto: 'description 13', image: 'http://appsuy.com/images_vivi_piria/slider/12.jpg' },
	{ title: 'texto 14', texto: 'description 14', image: 'http://appsuy.com/images_vivi_piria/slider/13.jpg' },
];

class SliderImage extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			cotizaciones: null,
			indexImage: Math.round(Math.random() * (imagesText.length - 1)),
			selected: false,
			date: moment(),
			dimensions: {
				width: '',
				height: '',
			},
		};
	}

	static getDerivedStateFromProps(nextProps, prevState) {
		if (nextProps.cotizaciones !== prevState.cotizaciones) {
			return { cotizaciones: nextProps.cotizaciones.rates };
		} else return null;
	}

	componentDidUpdate(prevProps, prevState) {
		if (prevProps.cotizaciones !== this.props.cotizaciones) {
			this.setState({ cotizaciones: this.props.cotizaciones.rates });
		}
	}
	componentWillUnmount() {}

	goToUrl = url => {
		window.open(url, '_blank');
	};

	render() {
		const { indexImage, cotizaciones } = this.state;
		const random = Math.round(Math.random() * (imagesText.length - 1));
		setTimeout(() => {
			if (this.state.date.minute() < moment().minute()) {
				this.setState({ indexImage: random, date: moment() });
			}
		}, 62000);

		const image = imagesText[indexImage].image;
		//const titulo = imagesText[indexImage].title;
		//const description = imagesText[indexImage].texto;

		return (
			<>
				<Container style={{width:"100%",height:750,backgroundImage:`url(${image})`,backgroundSize:"cover", backgroundPosition:"right" }} fluid>
					
					<Row >
						<Col className="ml-auto mr-auto text-left" md="6">
							 <Card
								style={{
									padding: '5px',
									width: '140px',
									height: '90px',
									marginTop: '30px',
									backgroundColor: 'rgba(0, 0, 0, 0.7)',
								}}
							>
								<h6>
									Fecha{' '}
									{moment()
										.format('DD-MM-YYYY')
										.toString()}{' '}
								</h6>
								<h6>tiempo en Piriápolis</h6>
								<Weather
									unit="C"
									lat={-34.8604573}
									lon={-55.2794588}
									appid="e9c8f3fe99f7821b3f3e43784e3376e7"
								/>
							</Card> 
						</Col>
						<Col className="ml-auto mr-auto text-right" md="5" style={{ marginTop: '30px' }}>
						</Col>
					</Row>
					
				</Container>
			</>
		);
	}
}

const mapStateToProps = state => {
	//console.log(JSON.stringify(state));
	return {
		...state.reducers.homeReducer,
	};
};

const mapDispatchToProps = dispatch => {
	return {};
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(SliderImage);


/*

<Button
										className="btn-simple"
										style={{ padding: '0px', border: '0' }}
										onClick={e => {
											this.goToUrl(
												'https://play.google.com/store/apps/details?id=com.appsuy.vivipiria'
											);
										}}
									>
										<img
											alt="Piriápolis vivipiria google play"
											src="http://appsuy.com/images_vivi_piria/googleplayLogo.png"
											width="120px"
										/>
									</Button>

*/