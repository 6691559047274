
import React from 'react';
import { connect } from 'react-redux';
import classnames from 'classnames';
import { 
	Button,
	Container,
	Row,
	Col,
	Card,
	CardHeader,
	CardBody,
	CardTitle,
	Nav,
	TabContent,
	TabPane,
	InputGroup,
	InputGroupAddon,
	InputGroupText,
	Input,
} from 'reactstrap';

import { goBack } from 'react-router-redux';
import AuthNavbar from '../../components/Navbars/AuthNavbar';

import { gastronomiaById, verificarEmpresa} from '../../actions/';
import { withScriptjs, withGoogleMap, GoogleMap, Marker } from 'react-google-maps';

import YouTube from 'react-youtube';
import _ from 'lodash';
import ReactBSAlert from 'react-bootstrap-sweetalert';
import Footer from '../../components/Footer/Footer';
import SliderImagenes from '../../views/components/SliderImagenes.jsx';
import Comments from '../../components/comments/comments';
import MensajesPrivados from '../../components/Mensajes/MensajesPrivados.jsx';


import Shared from '../../views/components/Shared.jsx';
import NotificationAlert from 'react-notification-alert';

const opts = {
	width: '100%',
	playerVars: {
		// https://developers.google.com/youtube/player_parameters
		autoplay: 1,
	},
};

const SatelliteMap = withScriptjs(
	withGoogleMap(props => {
		try {
			if (props.selected) {
				return (
					<GoogleMap
						defaultZoom={16}
						defaultCenter={{ lat: Number(props.selected.latitude), lng: Number(props.selected.longitude) }}
						defaultOptions={{
							scrollwheel: false,
						}}
					>
						<Marker
							position={{ lat: Number(props.selected.latitude), lng: Number(props.selected.longitude) }}
						/>
					</GoogleMap>
				);
			} else {
				return '';
			}
		} catch (err) {
			console.log(err);
		}
	})
);

class GastronomiaById extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			msg: '',
			verificar: false,
			selected: {},
			alert: null,
			horizontalTabs: 'mensaje',
			horizontalTabs2: 'platosrecomendados',
		};
	}

	static getDerivedStateFromProps(nextProps, prevState) {
		if (nextProps.selected !== prevState.selected) {
			return { selected: nextProps.selected };
		}
		if (nextProps.msg !== prevState.msg) {
			return { msg: nextProps.msg };
		} else return null;
	}

	componentDidUpdate(prevProps, prevState) {
		if (prevProps.selected !== this.props.selected) {
			this.setState({ selected: this.props.selected });
		}
		if (prevProps.msg !== this.props.msg) {
			this.notify('br', this.state.msg);
		}
	}

	componentDidMount() {
        const { ids } = this.props;
        this.props.changeToGastronomiaById(ids);
    }

	componentWillUnmount() {}

	changeActiveTab = (e, tabState, tadName) => {
		e.preventDefault();
		this.setState({
			[tabState]: tadName,
		});
	};

	handlerClick() {
        this.goToUrl("https://vivipiria.uy/gastronomia", "_self");
    }

	goToUrl = (url, target = '_blank') => {
		window.open(url, target);
	};

	htmlAlert = (title, idVideo) => {
		this.setState({
			alert: (
				<ReactBSAlert
					style={{ display: 'block' }}
					title={title}
					onConfirm={() => this.hideAlert()}
					onCancel={() => this.hideAlert()}
					showConfirm={false}
					btnSize=""
				>
					<YouTube videoId={idVideo} opts={opts} onReady={this._onReady} />
				</ReactBSAlert>
			),
		});
	};

	hideAlert = () => {
		this.setState({
			alert: null,
		});
	};

	getImage = imagenes => {
		try {
			const imagenesXseparado = imagenes.split(',');
			return imagenesXseparado[0];
		} catch (err) {
			return '';
		}
	};


	handlerEventVerificar() {
		this.setState({ ...this.state, verificar: true });
	}

	notify = (place, message) => {
		//var color = Math.floor(Math.random() * 5 + 1);

		let color = 2;
		let type;
		switch (color) {
			case 1:
				type = 'primary';
				break;
			case 2:
				type = 'success';
				break;
			case 3:
				type = 'danger';
				break;
			case 4:
				type = 'warning';
				break;
			case 5:
				type = 'info';
				break;
			default:
				break;
		}
		let options = {};
		options = {
			place: place,
			message: (
				<div>
					<div>
						<b>Verificaste este Local Gastronomico! </b>
						<p>En no mas de 24 horas, alguien del equipo se contactara con Usted.</p>
					</div>
				</div>
			),
			type: type,
			icon: 'tim-icons icon-alert-circle-exc',
			autoDismiss: 10,
		};
		this.refs.notificationAlert.notificationAlert(options);
	};

	change = (event, stateName, type, stateNameEqualTo, maxValue) => {
		switch (type) {
			case 'email':
				if (this.verifyEmail(event.target.value)) {
					this.setState({ [stateName + 'State']: 'has-success' });
				} else {
					this.setState({ [stateName + 'State']: 'has-danger' });
				}
				break;
			case 'length':
				if (this.verifyLength(event.target.value, stateNameEqualTo)) {
					this.setState({ [stateName + 'State']: 'has-success' });
				} else {
					this.setState({ [stateName + 'State']: 'has-danger' });
				}
				break;
			case 'number':
				if (this.verifyNumber(event.target.value, stateNameEqualTo)) {
					this.setState({ [stateName + 'State']: 'has-success' });
				} else {
					this.setState({ [stateName + 'State']: 'has-danger' });
				}
				break;
			case 'equal':
				if (this.verifyPasswords(event.target.value, stateNameEqualTo)) {
					this.setState({ [stateName + 'State']: 'has-success' });
				} else {
					this.setState({ [stateName + 'State']: 'has-danger' });
				}
				break;
			default:
				break;
		}
		this.setState({ [stateName]: event.target.value });
	};

	verifyEmail = value => {
		var emailRex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
		if (emailRex.test(value)) {
			return true;
		}
		return false;
	};

	// function that verifies if a string has a given length or not
	verifyLength = (value, length) => {
		if (value.length >= length) {
			return true;
		}
		return false;
	};

	verifyNumber = value => {
		var numberRex = new RegExp('^[0-9]+$');
		if (numberRex.test(value)) {
			return true;
		}
		return false;
	};

	isValidated = () => {
		if (
			this.state.nombreState === 'has-success' &&
			this.state.emailState === 'has-success' &&
			this.state.phoneState === 'has-success'
		) {
			return true;
		} else {
			if (this.state.nombreState !== 'has-success') {
				this.notify('br', 'Completa el nombre');
				this.setState({ nameCompanyState: 'has-danger' });
			}
			if (this.state.emailState !== 'has-success') {
				this.notify('br', 'Email incorrecto');
				this.setState({ emailState: 'has-danger' });
			}
			if (this.state.phoneState !== 'has-success') {
				this.notify('br', 'Danos un telefono o celular');
				this.setState({ phoneState: 'has-danger' });
			}
			return false;
		}
	};

	handlerSend = e => {
		if (this.isValidated()) {
			const data = {
				nombre: this.state.nombre,
				email: this.state.email,
				telefono: this.state.phone,
				referencia: this.props.selected,
			};
			this.props.verificarEmpresaAlojamiento(data);
		}
	};

	verificateText() {
		const { selected } = this.props;
		const { verificar } = this.state;

		if (verificar) {
			return (
				<div>
					<h4>
						<b>Verificar {selected.nombre}</b>
					</h4>
					<p>
						Usted tiene que ser propietario o encargado para verificar este establecimiento, ya que nosotros
						llamaremos y verificaremos los datos a continuación:
					</p>
					<br />
					<InputGroup
						className={classnames(this.state.nombreState, {
							'input-group-focus': this.state.nombreFocus,
						})}
					>
						<InputGroupAddon addonType="prepend">
							<InputGroupText>
								<i className="tim-icons icon-single-02" />
							</InputGroupText>
						</InputGroupAddon>
						<Input
							name="nombre"
							placeholder="Nombre de contacto ..."
							type="text"
							onChange={e => this.change(e, 'nombre', 'length', 1)}
							onFocus={e => this.setState({ nombreFocus: true })}
							onBlur={e => this.setState({ nombreFocus: false })}
						/>
					</InputGroup>
					<InputGroup
						className={classnames(this.state.emailState, {
							'input-group-focus': this.state.emailFocus,
						})}
					>
						<InputGroupAddon addonType="prepend">
							<InputGroupText>
								<i className="tim-icons icon-email-85" />
							</InputGroupText>
						</InputGroupAddon>
						<Input
							name="email"
							placeholder="Email de contacto..."
							type="email"
							onChange={e => this.change(e, 'email', 'email')}
							onFocus={e => this.setState({ emailFocus: true })}
							onBlur={e => this.setState({ emailFocus: false })}
						/>
					</InputGroup>

					<InputGroup
						className={classnames(this.state.phoneState, {
							'input-group-focus': this.state.phoneFocus,
						})}
					>
						<InputGroupAddon addonType="prepend">
							<InputGroupText>
								<i className="tim-icons icon-mobile" />
							</InputGroupText>
						</InputGroupAddon>
						<Input
							name="phone"
							placeholder="Telefono / celular de contacto..."
							type="number"
							onChange={e => this.change(e, 'phone', 'number')}
							onFocus={e => this.setState({ phoneFocus: true })}
							onBlur={e => this.setState({ phoneFocus: false })}
						/>
					</InputGroup>
					<br />
					<Button
						block
						className="btn-simple mb-3"
						color="success"
						style={{ borderRadius: '5px' }}
						onClick={e => {
							this.handlerSend(e);
						}}
						size="lg"
					>
						VERIFICAR {selected && selected.nombre.toUpperCase()}
					</Button>
				</div>
			);
		} else {
			return (
				<p>
					<h4>
						<b>{selected && selected.nombre} no esta Verificado.</b>
					</h4>
					Si eres propietario o encargado puedes verificarlo <br />
					<b style={{ color: '#6AFF03' }}> ES GRATIS!</b>
					<br />
					<i className="icon-ok-circle" style={{ fontSize: '15px', color: '#6AFF03' }} /> Acceso al Panel de
					Admin
					<br />
					<i className="icon-ok-circle" style={{ fontSize: '15px', color: '#6AFF03' }} /> Completar toda la
					información
					<br />
					<i className="icon-ok-circle" style={{ fontSize: '15px', color: '#6AFF03' }} /> Carta y Platos recomendados
					<br />
					<i className="icon-ok-circle" style={{ fontSize: '15px', color: '#6AFF03' }} /> Crear cupones y
					beneficios
					<br />
					<i className="icon-ok-circle" style={{ fontSize: '15px', color: '#6AFF03' }} /> Agregar descuentos
					de Tarjetas
					<br />
					<i className="icon-ok-circle" style={{ fontSize: '15px', color: '#6AFF03' }} /> Sistema de Analytics
					<br />
					<i className="icon-ok-circle" style={{ fontSize: '15px', color: '#6AFF03' }} /> Sistema de Reservas
					<br />
					<i className="icon-ok-circle" style={{ fontSize: '15px', color: '#6AFF03' }} /> Recibir Mensajes de
					usuarios
					<br />
					<i className="icon-ok-circle" style={{ fontSize: '15px', color: '#6AFF03' }} /> Convertir tu cuenta
					en Premium
					<br />
					<br />
					Email de contacto: contacto@vivipiria.uy
					{/* <Button
						block
						className="btn-simple mb-3"
						style={{ borderRadius: '5px', margin: '10px', color: '#6AFF03', borderColor: '#6AFF03' }}
						size="lg"
						onClick={e => {
							this.handlerEventVerificar();
						}}
					>
						VERIFICAR {selected && selected.nombre.toUpperCase()}
					</Button> */}
				</p>
			);
		}
	}


	informacionGeneral(){
		const {selected} = this.props;
		return (<Card>
			<CardHeader>
				<h5 className="card-category text-info">
					{' '}
					<i
						className={'tim-icons icon-badge'}
						style={{ fontSize: '25px' }}
					/>{' '}
					Informacion general {selected ? ' ' + selected.nombre : ''}{' '}
				</h5>
				<CardTitle tag="h3"></CardTitle>
			</CardHeader>
			<CardBody>
				{selected ? (
					selected.telefono ? (
						<p className="text-left">
							<i
								className={'tim-icons icon-mobile text-primary'}
								style={{ fontSize: '25px' }}
							/>
							{selected ? ' ' + selected.telefono : ''}
						</p>
					) : (
						''
					)
				) : (
					''
				)}
				{selected ? (
					selected.horarios ? (
						<p className="text-left">
							<i
								className={'tim-icons icon-watch-time text-success'}
								style={{ fontSize: '25px' }}
							/>
							{selected
								? selected.horarios
									? '  ' + selected.horarios
									: ''
								: ''}
						</p>
					) : (
						''
					)
				) : (
					''
				)}
				{selected ? (
					selected.web ? (
						<p className="text-left">
							<a href={selected ? ' ' + selected.web : ''} target={'_blank'}>
								<i
									className={'tim-icons icon-world text-info'}
									style={{ fontSize: '25px' }}
								/>
								{selected
									? selected.web
										? ' Pagina web: ' + selected.nombre
										: ''
									: ''}
							</a>
						</p>
					) : (
						''
					)
				) : (
					''
				)}

				{selected ? (
					selected.email ? (
						<p className="text-left">
							<i
								className={'tim-icons icon-email-85 text-warning'}
								style={{ fontSize: '25px' }}
							/>
							{selected ? (selected.email ? '  ' + selected.email : '') : ''}
						</p>
					) : (
						''
					)
				) : (
					''
				)}
				{selected ? (
					selected.whatsapp ? (
						<p className="text-left">
							<img
								alt=""
								src={
									'http://appsuy.com/images_vivi_piria/icono-whatsapp.png'
								}
								width="30px"
							/>
							<a
								href={
									selected
										? 'https://api.whatsapp.com/send?phone=598' +
										  selected.whatsapp.replace(/ /g, '') +
										  ''
										: ''
								}
								target={'_blank'}
							>
								{selected
									? selected.whatsapp
										? ' Contacto via WhatsApp '
										: ''
									: ''}
							</a>
						</p>
					) : (
						''
					)
				) : (
					''
				)}

				<div>
					{selected ? (
						selected.facebook || selected.twitter || selected.instagram ? (
							<h6 className="text-left" style={{ marginTop: '30px' }}>
								Redes sociales de {selected ? ' ' + selected.nombre : ''}
							</h6>
						) : (
							''
						)
					) : (
						''
					)}

					{selected ? (
						selected.facebook ? (
							<Button
								className="btn-icon btn-simple"
								color="facebook"
								style={{ float: 'left' }}
								onClick={e => {
									this.goToUrl(selected ? selected.facebook : '');
								}}
							>
								<i className="fab fa-facebook-square ml-auto" />
							</Button>
						) : (
							''
						)
					) : (
						''
					)}

					{selected ? (
						selected.twitter ? (
							<Button
								className="btn-icon btn-simple"
								color="twitter"
								style={{ float: 'left' }}
								onClick={e => {
									this.goToUrl(selected ? selected.twitter : '');
								}}
							>
								<i className="fab fa-twitter ml-auto" />
							</Button>
						) : (
							''
						)
					) : (
						''
					)}

					{selected ? (
						selected.instagram ? (
							<Button
								className="btn-icon btn-simple"
								color="primary"
								style={{ float: 'left' }}
								onClick={e => {
									this.goToUrl(selected ? selected.instagram : '');
								}}
							>
								<i className="fab fa-instagram ml-auto mr-auto" />
							</Button>
						) : (
							''
						)
					) : (
						''
					)}
				</div>
				<br />
				<div></div>
			</CardBody>
		</Card>
	);
	}

	render() {
		const { selected } = this.state;
		let videos = [];
		let cantidadVotos = [];
		if (selected && selected.videos) {
			videos = selected.videos.split(',');
		}
		if (selected && selected.cantidadvotos) {
			cantidadVotos = selected.cantidadvotos.split(' ');
		}

		return this.props.loading ? (
			<>
				<Container fluid style={{ marginTop: '200px' }}>
					<Row className="ml-auto mr-auto text-center" md="12">
						<Col className="ml-auto mr-auto text-center" md="12" style={{ marginTop: '50px' }}>
							<img alt="..." src={require('../../assets/img/loader.gif')} width={'60px'} />
						</Col>
					</Row>
				</Container>
			</>
		) : (
			<>
				<div className="rna-container">
					<NotificationAlert ref="notificationAlert" />
				</div>
				<div className="content">{this.state.alert}</div>
				<AuthNavbar />
				<Container fluid style={{ marginTop: '20px' }}>
					<Row className="ml-auto mr-auto text-left" md="12">
						<Col className="ml-auto mr-auto text-left" md="12">
							<h4 className="title text-left">
							<img
                                    alt="Gastronomia , restaurantes, comida, mariscos, pizzerias, en Piriápolis"
                                    src="http://appsuy.com/images_vivi_piria/gastronomia_icon.png"
                                    width="50px"
                                />
								{selected ? ' ' + selected.nombre : ''}
							</h4>
							{selected && selected.verificado && (
								<Button
									className="btn-simple"
									style={{ border: '0px', color: '#6AFF03', float: 'left' }}
								>
									<i className="icon-check-circle" style={{ color: '#6AFF03', fontSize: '25px' }} />
									Verificado
								</Button>
							)}
							{selected && selected.premium && (
								<Button
									className="btn-simple"
									style={{ border: '0px', color: '#FF0348', float: 'left', marginLeft: '-30px' }}
								>
									<i className="icon-award" style={{ color: '#FF0348', fontSize: '25px' }} />
									Premium
								</Button>
							)}
							<h4 style={{ float: 'left', marginTop: '15px' }}>
								<i className={'tim-icons icon-tap-02 text-success'} style={{ fontSize: '25px' }} />
								{selected ? cantidadVotos[0] : ''}
								<i
									className={'tim-icons icon-shape-star text-warning'}
									style={{ marginLeft: '10px', fontSize: '25px' }}
								/>
								{selected ? selected.calificacion : ''}
							</h4>
						</Col>
					</Row>
					<Row>
						<Col className="ml-auto mr-auto text-center" md="8">
							{selected ? (
								selected.image ? (
									<SliderImagenes big={true} imagenes={selected.image} />
								) : (
									require('../../assets/img/loader.gif')
								)
							) : (
								require('../../assets/img/loader.gif')
							)}
						</Col>
						<Col md="4">
						{selected && selected.verificado ? (<Card>
								<CardBody>
									<Nav className="nav-pills-info" pills>
										<div>
											<Button
												data-toggle="tab"
												className="btn-simple"
												style={
													this.state.horizontalTabs === 'reservas'
														? {
																borderRadius: '20px',
																float: 'left',
																borderColor: '#ff8d72',
																height: '50px',
														  }
														: {
																borderRadius: '20px',
																float: 'left',
																borderColor: 'rgba(255, 255, 255, 0.8)',
																height: '50px',
														  }
												}
												onClick={e => this.changeActiveTab(e, 'horizontalTabs', 'reservas')}
											>
												<h5
													className={
														this.state.horizontalTabs === 'reservas' ? 'text-warning' : ''
													}
												>
													<i
														className={'tim-icons icon-calendar-60 '}
														style={{ fontSize: '25px' }}
													/>{' '}
													Reservar mesa
												</h5>
											</Button>

											<Button
												className="btn-simple"
												style={
													this.state.horizontalTabs === 'mensaje'
														? {
																borderRadius: '20px',
																float: 'left',
																borderColor: '#00f2c3',
																height: '50px',
														  }
														: {
																borderRadius: '20px',
																float: 'left',
																borderColor: 'rgba(255, 255, 255, 0.8)',
																height: '50px',
														  }
												}
												onClick={e => this.changeActiveTab(e, 'horizontalTabs', 'mensaje')}
											>
												<h5
													className={
														this.state.horizontalTabs === 'mensaje' ? 'text-success' : ''
													}
												>
													{' '}
													<i
														className={'tim-icons icon-send '}
														style={{ fontSize: '25px' }}
													/>{' '}
													Mensaje
												</h5>
											</Button>
										</div>
									</Nav>
									<TabContent className="tab-space" activeTab={this.state.horizontalTabs}>
										<TabPane tabId="mensaje">
										<p>
												Enviale un Mensaje a  {selected ? ' ' + selected.nombre : ''} <br />
											</p>
											
											<MensajesPrivados ids={this.props.ids} />
										</TabPane>
										<TabPane tabId="reservas">
											<h6>Reserva en {selected ? ' ' + selected.nombre : ''}</h6>
											{
												//reserva mesa restaurante
											}
										</TabPane>
									</TabContent>
								</CardBody>
							</Card>

							): (
								<Card>
								<CardBody>{this.verificateText()}</CardBody>
									</Card>
								
							)}
						</Col>
					</Row>
					<Row className="ml-auto mr-auto text-left" md="12" style={{ marginTop: '0px' }}>
						<Col md="8">
							<h3>Descripción {selected ? ' ' + selected.nombre : ''} </h3>
							<p className="text-left">{selected ? ' ' + selected.descripcionLarga : ''}</p>
						</Col>
						<Col md="4">
							{this.informacionGeneral()}
						</Col>
					</Row>
					<Row className="ml-auto mr-auto text-left" md="12" style={{ marginTop: '0px' }}>
						<Col md="8">
							<Card>
								<CardBody>
									<Nav className="nav-pills-info" pills>
										<div>
											<Button
												data-toggle="tab"
												className="btn-simple"
												style={
													this.state.horizontalTabs2 === 'platosrecomendados'
														? {
																borderRadius: '20px',
																float: 'left',
																borderColor: '#00f2c3',
																height: '50px',
														  }
														: {
																borderRadius: '20px',
																float: 'left',
																borderColor: 'rgba(255, 255, 255, 0.8)',
																height: '50px',
														  }
												}
												onClick={e =>
													this.changeActiveTab(e, 'horizontalTabs2', 'platosrecomendados')
												}
											>
												<h5
													className={
														this.state.horizontalTabs2 === 'platosrecomendados'
															? 'text-success'
															: ''
													}
												>
													<i
														className={'tim-icons icon-shape-star '}
														style={{ fontSize: '25px' }}
													/>{' '}
													Platos Recomendados
												</h5>
											</Button>

											<Button
												className="btn-simple"
												style={
													this.state.horizontalTabs2 === 'carta'
														? {
																borderRadius: '20px',
																float: 'left',
																borderColor: '#00f2c3',
																height: '50px',
														  }
														: {
																borderRadius: '20px',
																float: 'left',
																borderColor: 'rgba(255, 255, 255, 0.8)',
																height: '50px',
														  }
												}
												onClick={e => this.changeActiveTab(e, 'horizontalTabs2', 'carta')}
											>
												<h5
													className={
														this.state.horizontalTabs2 === 'carta' ? 'text-success' : ''
													}
												>
													{' '}
													<i
														className={'tim-icons icon-map-big '}
														style={{ fontSize: '25px' }}
													/>{' '}
													Carta
												</h5>
											</Button>
											<Button
												className="btn-simple"
												style={
													this.state.horizontalTabs2 === 'bebidas'
														? {
																borderRadius: '20px',
																float: 'left',
																borderColor: '#00f2c3',
																height: '50px',
														  }
														: {
																borderRadius: '20px',
																float: 'left',
																borderColor: 'rgba(255, 255, 255, 0.8)',
																height: '50px',
														  }
												}
												onClick={e =>
													this.changeActiveTab(e, 'horizontalTabs2', 'bebidas')
												}
											>
												<h5
													className={
														this.state.horizontalTabs2 === 'bebidas'
															? 'text-success'
															: ''
													}
												>
													{' '}
													<i
														className={'tim-icons icon-bold '}
														style={{ fontSize: '25px' }}
													/>{' '}
													Bebidas
												</h5>
											</Button>
											<Button
												className="btn-simple"
												style={
													this.state.horizontalTabs2 === 'pago'
														? {
																borderRadius: '20px',
																float: 'left',
																borderColor: '#00f2c3',
																height: '50px',
														  }
														: {
																borderRadius: '20px',
																float: 'left',
																borderColor: 'rgba(255, 255, 255, 0.8)',
																height: '50px',
														  }
												}
												onClick={e => this.changeActiveTab(e, 'horizontalTabs2', 'pago')}
											>
												<h5
													className={
														this.state.horizontalTabs2 === 'pago' ? 'text-success' : ''
													}
												>
													{' '}
													<i
														className={'tim-icons icon-money-coins '}
														style={{ fontSize: '25px' }}
													/>{' '}
													Metodos de pago
												</h5>
											</Button>
										</div>
									</Nav>
									<TabContent className="tab-space" activeTab={this.state.horizontalTabs2}>
										<TabPane tabId="platosrecomendados">
										{selected && 
											<Card
												style={{
													fontSize: '11px',
													color: '#E9E9A7',
													width: '100%',
													textAlign: 'center',
													float: 'left',
													margin: '-10px',
													backgroundColor: 'rgba(255, 0, 0, 0)',
												}}
											>
												<CardBody>
													Listado carta platosrecomendados
												</CardBody>
											</Card>
											}
											
										</TabPane>
										<TabPane tabId="carta">
										{selected && 
											<Card
												style={{
													fontSize: '11px',
													color: '#E9E9A7',
													width: '100%',
													textAlign: 'center',
													float: 'left',
													margin: '-10px',
													backgroundColor: 'rgba(255, 0, 0, 0)',
												}}
											>
												<CardBody>
													Listado carta carta
												</CardBody>
											</Card>
											}
										</TabPane>
										<TabPane tabId="bebidas">
										{selected && 
											<Card
												style={{
													fontSize: '11px',
													color: '#E9E9A7',
													width: '100%',
													textAlign: 'center',
													float: 'left',
													margin: '-10px',
													backgroundColor: 'rgba(255, 0, 0, 0)',
												}}
											>
												<CardBody>
													Listado carta Bebidas
												</CardBody>
											</Card>
											}
										</TabPane>
										<TabPane tabId="pago">
										{selected && selected.servicios && selected.servicios.efectivo &&
											<Card
												style={{
													fontSize: '12px',
													color: '#E9E9A7',
													width: '80px',
													height: '100px',
													textAlign: 'center',
													float: 'left',
													margin: '-10px',
													backgroundColor: 'rgba(255, 0, 0, 0)',
												}}
											>
												<CardBody>
												<i className={'tim-icons icon-check-2'} style={{ color: 'rgb(0, 242, 195)',fontSize: '25px', marginRight: '15px' }}
												/>
													Efectivo
												</CardBody>
											</Card>}
											<Card
												style={{
													fontSize: '12px',
													color: '#E9E9A7',
													width: '100%',
													height: '50px',
													textAlign: 'left',
													float: 'left',
													margin: '-10px',
													backgroundColor: 'rgba(255, 0, 0, 0)',
												}}
											>
												<CardBody>
												<h6>Tarjetas de Credito</h6>
												</CardBody>
												</Card>
											
											{selected && selected.servicios && selected.servicios.targetascredito.visa &&
											<Card
												style={{
													fontSize: '12px',
													color: '#E9E9A7',
													width: '80px',
													height: '100px',
													textAlign: 'center',
													float: 'left',
													margin: '3px',
													backgroundColor: 'rgba(255, 0, 0, 0)',
												}}
											>
												<CardBody>
												<img
														alt="alt"
														src="https://d39etc8ensw3fs.cloudfront.net/fit-in/50x50/visa.png"
													/>
													VISA
												</CardBody>
											</Card>}
											{selected && selected.servicios && selected.servicios.targetascredito.mastercard &&
											<Card
												style={{
													fontSize: '12px',
													color: '#E9E9A7',
													width: '110px',
													height: '100px',
													textAlign: 'center',
													float: 'left',
													margin: '3px',
													backgroundColor: 'rgba(255, 0, 0, 0)',
												}}
											>
												<CardBody>
												<img
														alt="alt"
														src="https://d39etc8ensw3fs.cloudfront.net/fit-in/50x50/mastercard.png"
													/>
													MasterCard
												</CardBody>
											</Card>}
											{selected && selected.servicios && selected.servicios.targetascredito.oca &&
											<Card
												style={{
													fontSize: '12px',
													color: '#E9E9A7',
													width: '80px',
													height: '100px',
													textAlign: 'center',
													float: 'left',
													margin: '3px',
													backgroundColor: 'rgba(255, 0, 0, 0)',
												}}
											>
												<CardBody>
												<img
														alt="alt"
														src="https://d39etc8ensw3fs.cloudfront.net/fit-in/50x50/oca.png"
													/>
													OCA
												</CardBody>
											</Card>}
											{selected && selected.servicios && selected.servicios.targetascredito.lider &&
											<Card
												style={{
													fontSize: '12px',
													color: '#E9E9A7',
													width: '80px',
													height: '100px',
													textAlign: 'center',
													float: 'left',
													margin: '3px',
													backgroundColor: 'rgba(255, 0, 0, 0)',
												}}
											>
												<CardBody>
												<img
														alt="alt"
														src="https://d39etc8ensw3fs.cloudfront.net/fit-in/50x50/lider.png"
													/>
													Lider
												</CardBody>
											</Card>}
											
											{selected && selected.servicios && selected.servicios.targetascredito.diners &&
											<Card
												style={{
													fontSize: '12px',
													color: '#E9E9A7',
													width: '110px',
													height: '100px',
													textAlign: 'center',
													float: 'left',
													margin: '3px',
													backgroundColor: 'rgba(255, 0, 0, 0)',
												}}
											>
												<CardBody>
												<img
														alt="alt"
														src="https://d39etc8ensw3fs.cloudfront.net/fit-in/50x50/dinersclub.png"
													/>
													Diners Club
												</CardBody>
											</Card>}
											{selected && selected.servicios && selected.servicios.targetascredito.americanexpress &&
											<Card
												style={{
													fontSize: '12px',
													color: '#E9E9A7',
													width: '90px',
													height: '100px',
													textAlign: 'center',
													float: 'left',
													margin: '3px',
													backgroundColor: 'rgba(255, 0, 0, 0)',
												}}
											>
												<CardBody>
												<img
														alt="alt"
														src="https://d39etc8ensw3fs.cloudfront.net/fit-in/50x50/americanexpress.png"
													/>
													American Express
												</CardBody>
											</Card>}
											
											{selected && selected.servicios && selected.servicios.targetascredito.anda &&
											<Card
												style={{
													fontSize: '12px',
													color: '#E9E9A7',
													width: '90px',
													height: '100px',
													textAlign: 'center',
													float: 'left',
													margin: '3px',
													backgroundColor: 'rgba(255, 0, 0, 0)',
												}}
											>
												<CardBody>
												<img
														alt="alt"
														src="https://d39etc8ensw3fs.cloudfront.net/fit-in/50x50/anda.png"
													/>
													ANDA
												</CardBody>
											</Card>}
											{selected && selected.servicios && selected.servicios.targetascredito.discover &&
											<Card
												style={{
													fontSize: '12px',
													color: '#E9E9A7',
													width: '90px',
													height: '100px',
													textAlign: 'center',
													float: 'left',
													margin: '3px',
													backgroundColor: 'rgba(255, 0, 0, 0)',
												}}
											>
												<CardBody>
												<img
														alt="alt"
														src="https://d39etc8ensw3fs.cloudfront.net/fit-in/50x50/discover.png"
													/>
													Discover
												</CardBody>
											</Card>}
											<Card
												style={{
													fontSize: '12px',
													color: '#E9E9A7',
													width: '100%',
													height: '50px',
													textAlign: 'left',
													float: 'left',
													margin: '10px',
													backgroundColor: 'rgba(255, 0, 0, 0)',
												}}
											>
												<CardBody>
												<h6>Tarjetas Debito</h6>
												</CardBody>
												</Card>
												{selected && selected.servicios && selected.servicios.targetasdebito.visa &&
											<Card
												style={{
													fontSize: '12px',
													color: '#E9E9A7',
													width: '80px',
													height: '100px',
													textAlign: 'center',
													float: 'left',
													margin: '3px',
													backgroundColor: 'rgba(255, 0, 0, 0)',
												}}
											>
												<CardBody>
												<img
														alt="alt"
														src="https://d39etc8ensw3fs.cloudfront.net/fit-in/50x50/visa.png"
													/>
													VISA
												</CardBody>
											</Card>}
											{selected && selected.servicios && selected.servicios.targetasdebito.mastercard &&
											<Card
												style={{
													fontSize: '12px',
													color: '#E9E9A7',
													width: '110px',
													height: '100px',
													textAlign: 'center',
													float: 'left',
													margin: '3px',
													backgroundColor: 'rgba(255, 0, 0, 0)',
												}}
											>
												<CardBody>
												<img
														alt="alt"
														src="https://d39etc8ensw3fs.cloudfront.net/fit-in/50x50/mastercard.png"
													/>
													MasterCard
												</CardBody>
											</Card>}


											{selected && selected.servicios && selected.servicios.targetasdebito.anda &&
											<Card
												style={{
													fontSize: '12px',
													color: '#E9E9A7',
													width: '90px',
													height: '100px',
													textAlign: 'center',
													float: 'left',
													margin: '3px',
													backgroundColor: 'rgba(255, 0, 0, 0)',
												}}
											>
												<CardBody>
												<img
														alt="alt"
														src="https://d39etc8ensw3fs.cloudfront.net/fit-in/50x50/anda.png"
													/>
													ANDA
												</CardBody>
											</Card>}
										</TabPane>
									</TabContent>
								</CardBody>{' '}
							</Card>
						</Col>
						<Col md="4">
							
						</Col>
					</Row>

					<Row className="ml-auto mr-auto text-left" md="12" style={{ marginTop: '0px' }}>
						<Col md="8"></Col>

						<Col className="ml-auto mr-auto text-left" md="4"></Col>
					</Row>
					{selected && selected.tenerencuenta && 
						<Row className="ml-auto mr-auto text-left" md="12">
						<Col className="ml-auto mr-auto text-left" md="8" style={{ marginTop: '20px' }}>
							<Card>
								<CardHeader>
									<h3>Tener en cuenta {selected ? ' ' + selected.nombre : ''} </h3>
									<p>{selected ? ' ' + selected.tenerencuenta : ''}</p>
								</CardHeader>
								<CardBody></CardBody>
							</Card>
						</Col>
						<Col className="ml-auto mr-auto text-center" md="4"></Col>
					</Row>
					}
					<Row className="ml-auto mr-auto text-left" md="12">
						<Col className="ml-auto mr-auto text-left" md="8" style={{ marginTop: '20px' }}>
							<Card>
								<CardHeader>
									<h3>
										{' '}
										<i className={'tim-icons icon-tag text-primary'} style={{ fontSize: '25px' }} /> Cupones y
										Beneficios de {selected ? ' ' + selected.nombre : ''}{' '}
									</h3>
								</CardHeader>
								<CardBody></CardBody>
							</Card>
						</Col>
						<Col className="ml-auto mr-auto text-center" md="4"></Col>
					</Row>

					<Row className="ml-auto mr-auto text-center" md="12">
						<Col className="ml-auto mr-auto text-left" md="9" style={{ marginTop: '10px' }}>
							{selected ? (
								selected.videos ? (
									<div>
										<i
											className={'tim-icons icon-video-66 text-info'}
											style={{ fontSize: '25px', float: 'left', marginRight: '15px' }}
										/>
										<h6> Videos de {selected.name}</h6>
									</div>
								) : (
									''
								)
							) : (
								''
							)}
						</Col>
					</Row>
					<Row className="ml-auto mr-auto text-center" md="12">
						<Col className="ml-auto mr-auto text-left" md="9" style={{ marginTop: '10px' }}>
							{selected
								? selected.videos
									? _.map(videos, x => {
											return (
												<>
													<Button
														key={x}
														className="btn-simple"
														style={{ padding: '0px', border: '0' }}
														onClick={e => {
															this.htmlAlert(selected.name, x);
														}}
													>
														<img
															key={x}
															alt={x}
															src={
																'https://d39etc8ensw3fs.cloudfront.net/fit-in/200x100/' +
																x +
																'.png'
															}
															width="200px"
														/>
													</Button>
												</>
											);
									  })
									: ''
								: ''}
						</Col>
					</Row>
					<Row className="ml-auto mr-auto text-center" md="12"></Row>
					<Row className="ml-auto mr-auto text-center" md="12">
						<Col className="ml-auto mr-auto text-center" md="12" style={{ marginTop: '50px' }}>
							<h6 className="text-left">
								<i className={'tim-icons icon-square-pin text-success'} style={{ fontSize: '25px' }} />
								Mapa ubicación
							</h6>
							<p className="text-left">{selected ? 'Dirección: ' + selected.direccion : ''}</p>
							<SatelliteMap
								selected={selected}
								googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyCuRvqGwte0YrAD0azdqKRO1FbvNlvHBTs"
								loadingElement={<div style={{ height: `100%` }} />}
								containerElement={
									<div
										style={{
											height: `500px`,
											borderRadius: `.2857rem`,
											overflow: `hidden`,
										}}
									/>
								}
								mapElement={<div style={{ height: `100%` }} />}
							/>
						</Col>
					</Row>
					<Row className="ml-auto mr-auto text-left" md="12">
						<Col className="ml-auto mr-auto text-left" md="12" style={{ marginTop: '50px' }}>
							<Card>
								<CardHeader>
									<h5 className="card-category">Compartir en tus redes sociales </h5>
									<CardTitle tag="h3"></CardTitle>
								</CardHeader>
								<CardBody>
									{selected ? (
										<div>
											<Shared
												shareUrl={
													'https://vivipiria.uy/alojamiento/' +
													this.props.ids
												}
												title={selected.nombre}
												exampleImage={this.getImage(selected.image)}
											/>
										</div>
									) : (
										''
									)}
								</CardBody>
							</Card>
						</Col>
					</Row>
				</Container>
				<Container fluid style={{ marginTop: '20px' }}>
					<Row className="ml-auto mr-auto text-left" md="12">
						<Col className="ml-auto mr-auto text-left" md="12" style={{ marginTop: '50px' }}>
							<Card>
								<CardHeader>
									<h5 className="card-category">
										Comentatios y calificaciones de {selected ? ' ' + selected.nombre : ''}
									</h5>
									<CardTitle tag="h3"></CardTitle>
								</CardHeader>
								<CardBody>
									<Comments ids={this.props.ids} />
								</CardBody>
							</Card>
						</Col>
					</Row>
				</Container>
				<Footer />
			</>
		);
	}
}
const mapStateToProps = state => {
	//console.log(JSON.stringify(state));
	return {
		...state.reducers.homeReducer,
	};
};

const mapDispatchToProps = dispatch => {
	return {
		changeToGastronomiaById: x => {
			dispatch(gastronomiaById(x));
		},
		goTo: () => {
			dispatch(goBack());
		},
		verificarEmpresaAlojamiento: data => {
			dispatch(verificarEmpresa(data));
		},
	};
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(GastronomiaById);
