import React from "react";



class Admin extends React.Component {
 
  render() {
    return (
      <div>
      </div>
    );
  }
}

export default Admin;
