import React from "react";
import { connect } from 'react-redux';
import { goBack,push } from 'react-router-redux';
// nodejs library that concatenates classes
import classNames from "classnames";
import _ from "lodash";


// reactstrap components
import {
  Collapse,
  Button,
  Navbar,
  Nav,
  Container,
} from "reactstrap";

class AdminNavbar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      collapseOpen: false,
      modalSearch: false,
      color: "navbar-transparent"
    };
  }
  componentDidMount() {
    window.addEventListener("resize", this.updateColor);
  }
  componentWillUnmount() {
    window.removeEventListener("resize", this.updateColor);
  }
  // function that adds color white/transparent to the navbar on resize (this is for the collapse)
  updateColor = () => {
    if (window.innerWidth < 993 && this.state.collapseOpen) {
      this.setState({
        color: "bg-white"
      });
    } else {
      this.setState({
        color: "navbar-transparent"
      });
    }
  };
  // this function opens and closes the collapse on small devices
  toggleCollapse = () => {
    if (this.state.collapseOpen) {
      this.setState({
        color: "navbar-transparent"
      });
    } else {
      this.setState({
        color: !this.state.collapseOpen ? 'navbar-black' : 'navbar-transparent',
      });
    }
    this.setState({
      collapseOpen: !this.state.collapseOpen
    });
  };
  // this function is to open the Search modal
  toggleModalSearch = () => {
    this.setState({
      modalSearch: !this.state.modalSearch
    });
  };

  goToUrl = url => {
		window.open(url, '_self');
  };

  getImage = (imagenes) =>{
		const imagenesXseparado = imagenes.split(',');
		return imagenesXseparado[0];
	}

  handlerGoTo=(go) =>{
    if(go==='mensajes'){
      this.props.goToMensajes();
    }

    if(this.props.user.nombre){
      if(go === 'perfil-usuario'){
        this.props.goToPerfilUser();
      }
      if(go === 'mis-cupones'){
        this.props.goToMisCupones();
      }
      if(go === 'favoritos'){
        this.props.goToFavoritos();
      }
      if(go ==='admin-master'){
        this.props.goToMasterAdmin();
      }
    }
    else{
    const isAlojamiento = this.props.empresa.categoria.toLowerCase().includes('hotel');
    const isGastronomia = this.props.empresa.categoria.toLowerCase().includes('rest');
    const isComercio= this.props.empresa.categoria.toLowerCase().includes('comerc');
    const isInmobiliaria = this.props.empresa.categoria.toLowerCase().includes('inmobiliaria');

  

    if(go === 'crear-cupon'){
      this.props.goToCrearCupon();
     }
     if(go === 'analiticas'){
      this.props.goToAnaliticas();
     }
     if(go === 'clientes'){
      this.props.goToClientes();
     }
     if(go === 'cupones-publicados'){
      this.props.goToCuponesPublicados();
     }

    if(go === 'perfil-empresa' && isAlojamiento){
     this.props.goToPerfilAlojamiento();
    }else if(go === 'perfil-empresa' && isGastronomia){
      this.props.goToPerfilGastronomia();
    }else if(go==='perfil-empresa'&& isComercio && !isInmobiliaria){
      this.props.goToPerfilGuia();
    }
    else if(go==='perfil-empresa'&& isInmobiliaria){
      this.props.goToPerfilInmobiliaria();
    }}
    
  }

  
  userNavBar=()=>{
    const { user} = this.props;
    const images = user.imagen !== '' ? _.split(user.imagen, ',') : [];
        
    const image =
			this.state.color === 'bg-white'
				? 'http://vivipiria.uy/images/logoViviPiria_d.png'
        : 'http://vivipiria.uy/images/logoViviPiria.png';

    return( <Navbar
      className={classNames("navbar-absolute fixed-top", this.state.color)}
      expand="lg"
    >
      <Container fluid>
      <div className="navbar-wrapper">
    <img alt="logo Vivipiria!" src={image} width="160px" />
  </div>
        <div className="navbar-wrapper">
          <div className="navbar-minimize d-inline">
          </div>
        </div>
        <button
          className="navbar-toggler"
          type="button"
          data-toggle="collapse"
          data-target="#navigation"
          aria-expanded="false"
          aria-label="Toggle navigation"
          onClick={this.toggleCollapse}
        >
          <span className="navbar-toggler-bar navbar-kebab" />
          <span className="navbar-toggler-bar navbar-kebab" />
          <span className="navbar-toggler-bar navbar-kebab" />
        </button>
        <Collapse navbar isOpen={this.state.collapseOpen}>
          <Nav className="ml-auto" navbar>
          <div style={{margin:"0px"}}>
          <Button
              className="btn-simple"
              color="primary"
              style={{ borderRadius: '5px', margin: '0px', border:'none'}}
              onClick={e => {
                this.handlerGoTo('mis-cupones');
              }}
            >
              <h6><i className="tim-icons icon-tag" style={{ fontSize: '20px', float: 'left', marginRight:'5px' }}/> Mis Cupones</h6>
            </Button>
          </div>
          <div style={{margin:"0px"}}>
          <Button
              className="btn-simple"
              color="primary"
              style={{ borderRadius: '5px', margin: '0px', border:'none'}}
              onClick={e => {
                this.handlerGoTo('favoritos');
              }}
            >
              <h6><i className="tim-icons icon-heart-2" style={{ fontSize: '20px', float: 'left', marginRight:'5px', marginTop:'-3px' }}/> Favoritos </h6>
            </Button>
          </div>
          <div style={{margin:"0px"}}>
          <Button
              className="btn-simple"
              color="primary"
              style={{ borderRadius: '5px', margin: '0px', border:'none'}}
              onClick={e => {
                this.handlerGoTo('perfil-usuario');
              }}
            >
              <h6><i className="tim-icons icon-single-02" style={{ fontSize: '20px', float: 'left', marginRight:'5px', marginTop:'-3px' }}/> Mi Perfil </h6>
            </Button>
          </div>

          <div style={{marginTop:"0px"}}>
          <Button
              className="btn-simple"
              color="primary"
              style={{ borderRadius: '5px', margin: '0px', border:'none'}}
              onClick={e => {
                this.handlerGoTo('mensajes');
              }}
            >
              <h6><i className="tim-icons icon-email-85" style={{ fontSize: '20px', float: 'left', marginRight:'5px', marginTop:'-3px' }}/> Mensajes </h6>
            </Button>
          </div>
          { user.isAdmin && 
          <div style={{marginTop:"0px"}}>
          <Button
              className="btn-simple"
              color="primary"
              style={{ borderRadius: '5px', margin: '0px', border:'none'}}
              onClick={e => {
                this.handlerGoTo('admin-master');
              }}
            >
              <h6><i className="tim-icons icon-puzzle-10" style={{ fontSize: '20px', float: 'left', marginRight:'5px', marginTop:'-3px' }}/> Master Admin </h6>
            </Button>
          </div>
          }
          <div style={{marginTop:"0px"}}>
          <Button
              className="btn-simple"
              color="primary"
              style={{ borderRadius: '5px', margin: '0px', border:'none'}}
              onClick={e => {
                console.log("borrar localstorage y regargar");
                localStorage.clear();
                this.goToUrl('/');
              }}
            >
              <h6><i className="tim-icons icon-user-run" style={{ fontSize: '20px', float: 'left', marginRight:'5px', marginTop:'-3px' }}/> Salir </h6>
            </Button>
          </div>
          <div className="photo" style={{width:"50px", height:"50px"}}>
              <img alt="..." src={ images === '' || images === undefined || images === null || _.isEmpty(images) ?  "https://d39etc8ensw3fs.cloudfront.net/fit-in/200x200/defaultPerfil.png":"https://d39etc8ensw3fs.cloudfront.net/fit-in/200x200/" + images[0]}/>
          </div>
          </Nav>
        </Collapse>
      </Container>
    </Navbar>);
  }

  empresaNavBar = () =>{
    const { empresa} = this.props;
    const image =
			this.state.color === 'bg-white'
				? 'http://vivipiria.uy/images/logoViviPiria_d.png'
        : 'http://vivipiria.uy/images/logoViviPiria.png';

    return( <Navbar
      className={classNames("navbar-absolute fixed-top", this.state.color)}
      expand="lg"
    >
      <Container fluid>
      <div className="navbar-wrapper">
    <img alt="logo Vivipiria!" src={image} width="160px" />
  </div>
        <div className="navbar-wrapper">
          <div className="navbar-minimize d-inline">
          </div>
        </div>
        <button
          className="navbar-toggler"
          type="button"
          data-toggle="collapse"
          data-target="#navigation"
          aria-expanded="false"
          aria-label="Toggle navigation"
          onClick={this.toggleCollapse}
        >
          <span className="navbar-toggler-bar navbar-kebab" />
          <span className="navbar-toggler-bar navbar-kebab" />
          <span className="navbar-toggler-bar navbar-kebab" />
        </button>
        <Collapse navbar isOpen={this.state.collapseOpen}>
          <Nav className="ml-auto" navbar>
          <div style={{margin:"0px"}}>
          <Button
              className="btn-simple"
              color="primary"
              style={{ borderRadius: '5px', margin: '0px', border:'none'}}
              onClick={e => {
                this.handlerGoTo('analiticas');
              }}
            >
              <h6><i className="tim-icons icon-chart-bar-32" style={{ fontSize: '20px', float: 'left', marginRight:'5px' }}/> Analiticas  </h6>
            </Button>
          </div>
          <div style={{margin:"0px"}}>
          <Button
              className="btn-simple"
              color="primary"
              style={{ borderRadius: '5px', margin: '0px', border:'none'}}
              onClick={e => {
                this.handlerGoTo('clientes');
              }}
            >
              <h6><i className="tim-icons icon-tie-bow" style={{ fontSize: '20px', float: 'left', marginRight:'5px' }}/> Clientes  </h6>
            </Button>
          </div>
          <div style={{margin:"0px"}}>
          <Button
              className="btn-simple"
              color="primary"
              style={{ borderRadius: '5px', margin: '0px', border:'none'}}
              onClick={e => {
                this.handlerGoTo('cupones-publicados');
              }}
            >
              <h6><i className="tim-icons icon-tag" style={{ fontSize: '20px', float: 'left', marginRight:'5px' }}/> Cupones Publicados</h6>
            </Button>
          </div>
          <div style={{margin:"0px"}}>
          <Button
              className="btn-simple"
              color="primary"
              style={{ borderRadius: '5px', margin: '0px', border:'none'}}
              onClick={e => {
                this.handlerGoTo('crear-cupon');
              }}
            >
              <h6><i className="tim-icons icon-simple-add" style={{ fontSize: '20px', float: 'left', marginRight:'5px', marginTop:'-3px' }}/> Crear Cupon </h6>
            </Button>
          </div>
          <div style={{margin:"0px"}}>
          <Button
              className="btn-simple"
              color="primary"
              style={{ borderRadius: '5px', margin: '0px', border:'none'}}
              onClick={e => {
               this.handlerGoTo('perfil-empresa');
              }}
            >
              <h6><i className="tim-icons icon-badge" style={{ fontSize: '20px', float: 'left', marginRight:'5px', marginTop:'-3px' }}/> Perfil Empresa</h6>
            </Button>
          </div>

          <div style={{marginTop:"0px"}}>
          <Button
              className="btn-simple"
              color="primary"
              style={{ borderRadius: '5px', margin: '0px', border:'none'}}
              onClick={e => {
                this.handlerGoTo('mensajes');
              }}
            >
              <h6><i className="tim-icons icon-email-85" style={{ fontSize: '20px', float: 'left', marginRight:'5px', marginTop:'-3px' }}/> Mensajes </h6>
            </Button>
          </div>
          <div style={{marginTop:"0px"}}>
          <Button
              className="btn-simple"
              color="primary"
              style={{ borderRadius: '5px', margin: '0px', border:'none'}}
              onClick={e => {
                localStorage.clear();
                this.goToUrl('/');
              }}
            >
              <h6><i className="tim-icons icon-user-run" style={{ fontSize: '20px', float: 'left', marginRight:'5px', marginTop:'-3px' }}/> Salir </h6>
            </Button>
          </div>
          <div className="photo" style={{width:"50px", height:"50px"}}>
              <img alt="..." src={!empresa.logo || empresa.logo ===""  ? !empresa.image || empresa.image==="" ? "https://d39etc8ensw3fs.cloudfront.net/fit-in/200x200/defaultPerfil.png" : "https://d39etc8ensw3fs.cloudfront.net/fit-in/200x200/"+this.getImage(empresa.image) :"https://d39etc8ensw3fs.cloudfront.net/fit-in/200x200/" + empresa.logo}/>
          </div>
          </Nav>
        </Collapse>
      </Container>
    </Navbar>);
  }




  getNavBar= ()=>{

    const { user, empresa } = this.props; 
    let navBar;
    const tokenEmpresa = localStorage.getItem('tokenEmpresa');
    const token = localStorage.getItem('token');
    
    if(!_.isEmpty(user) && token){
      navBar  = this.userNavBar();
    }
    if(!_.isEmpty(empresa) && tokenEmpresa){
        navBar  = this.empresaNavBar();
    }

    return navBar;
   
  }




  render() {
    return (
      <>
        {this.getNavBar()}
      </>
    );
  }
}


     const mapStateToProps = state => {
      //console.log(JSON.stringify(state));
      return {
        ...state.reducers.homeReducer,
      };
    };
    
    const mapDispatchToProps = dispatch => {
      return {
        goToMasterAdmin: ()=>{
          dispatch(push(`/master-admin-vivipiria/`));
        },
        goToPerfilAlojamiento: ()=>{
          dispatch(push(`/alojamiento-perfil/`));
        },
        goToPerfilGastronomia: ()=>{
          dispatch(push(`/gastronomia-perfil/`));
        },
        goToPerfilGuia: ()=>{
          dispatch(push(`/guia-comercial-perfil/`));
        },
        goToPerfilInmobiliaria: ()=>{
          dispatch(push(`/inmobiliaria-perfil/`));
        },
        goToPerfilUser:()=>{
          dispatch(push(`/usuario-perfil/`));
        },
        goToMensajes:()=>{
          dispatch(push("/mensajes/"));
        },
        goToMisCupones:()=>{
          dispatch(push("/mis-cupones/"));
        },
        goToFavoritos:()=>{
          dispatch(push("/favoritos/"));
        },
        goToCuponesPublicados:()=>{
          dispatch(push("/cupones-publicados/"));
        },
        goToClientes:()=>{
          dispatch(push("/clientes/"));
        },
        goToAnaliticas:()=>{
          dispatch(push("/analiticas/"));
        },
        goToCrearCupon:()=>{
          dispatch(push("/crear-cupon/"));
        },
        goTo: () => {
          dispatch(goBack());
        },
      };
    };
    
    export default connect(
      mapStateToProps,
      mapDispatchToProps
    )(AdminNavbar);

