import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { store, history } from './store';
import { Route, Switch, Redirect } from 'react-router-dom';
import { Router } from 'react-router-dom';
import AlojamientoById from './views/components/AlojamientosById.jsx';
import GastronomiaById from './views/components/GastronomiaById.jsx';
import GuiaById from './views/components/GuiaById.jsx';
import InmobiliariaById from './views/components/InmobiliariaById.jsx';

import AdminLayout from './layouts/Admin/Admin.jsx';
import Home from './views/components/HomeView.jsx';
import Alojamiento from './views/components/Alojamiento.jsx';
import Gastronomia from './views/components/Gastronomia.jsx';
import Guia from './views/components/Guia.jsx';
import Inmobiliarias from './views/components/Inmobiliarias.jsx';
import Mapa from './views/components/Mapa.jsx';
import Paseos from './views/components/Paseos.jsx';
import PaseoById from './views/components/PaseoById.jsx';
import Eventos from './views/components/Eventos.jsx';
import Playas from './views/components/Playas.jsx';
import PlayaById from './views/components/PlayaById.jsx';
import EventoById from './views/components/EventoById.jsx';
import PoliticasPrivacidad from './views/components/PoliticasPrivacidad.jsx';
import TerminosCondiciones from './views/components/TerminosCondiciones';
import Login from  './views/pages/Login.jsx';
import Register from  './views/pages/Register.jsx';
import RegisterUser from  './views/pages/RegisterUser.jsx';
import LoginEmpresa from './views/pages/LoginEmpresa.jsx';

import AlojamientoPerfilById from './views/components/AlojamientoPerfilById.jsx';
import GastronomiaPerfilById from './views/components/GastronomiaPerfilById.jsx';
import GuiaComercialPerfilById from './views/components/GuiaComercialPerfilById.jsx';
import InmobiliariaPerfilById from './views/components/InmobiliariaPerfilById.jsx';
import PlayaPerfilById from './views/components/PlayaPerfilById.jsx';
import PaseoPerfilById from './views/components/PaseoPerfilById.jsx';
import EventoPerfilById from './views/components/EventoPerfilById.jsx';
import PerfilUser from './views/components/PerfilUser.jsx';
import Mensajes from './views/components/Mensajes.jsx';
import CuponesUsuario from './views/components/CuponesUsuario.jsx';
import FavoritosUsuario from './views/components/FavoritosUsuario.jsx';
import CuponesPublicados from './views/components/CuponesPublicados.jsx';
import CrearCupon from './views/components/CrearCupon.jsx';
import Clientes from './views/components/Clientes.jsx';
import Analiticas from './views/components/Analiticas.jsx';
import Crud from './views/components/Crud.jsx';
import ListaAlojamientos from './views/admin/ListaAlojamientos';
import ListaGastronomia from './views/admin/ListaGastronomia';
import ListaComercios from './views/admin/ListaComercios';
import EditAlojamientoAdmin from './views/admin/EditAlojamientoAdmin.jsx';
import EditGastronomiaAdmin from './views/admin/EditGastronomiaAdmin.jsx';
import EditComerciosAdmin from './views/admin/EditComerciosAdmin.jsx';
import ListaInmobiliarias from './views/admin/ListaInmobiliarias.jsx';
import EditInmobiliariaAdmin from './views/admin/EditInmobiliariaAdmin.jsx';

import ListaEventos from './views/admin/ListaEventos';



import './assets/icons/et-line.css';
import './assets/icons/font-icons.css';
import './assets/icons/font-icons-realstate.css';


import './assets/css/nucleo-icons.css';
import './assets/css/black-dashboard-pro-react.css';
import './assets/demo/demo.css';
import 'react-notification-alert/dist/animate.css';

ReactDOM.render(
	<Provider store={store}>
		<Router history={history}>
			<Switch>
				<Route path="/politicas-de-privacidad" render={props => <PoliticasPrivacidad {...props} />} />
				<Route path="/terminos-y-condiciones" render={props => <TerminosCondiciones {...props} />} />
				<Route path="/login" render={props => <Login {...props} />} />
				<Route path="/login-empresa" render={props => <LoginEmpresa {...props} />} />
				<Route path="/registrar-empresa" render={props => <Register {...props} />} />
				<Route path="/registro-usuario" render={props => <RegisterUser {...props} />} />
				<Route path="/admin" render={props => <AdminLayout {...props} />} />
				<Route path="/alojamiento/:id" render={({ match }) => <AlojamientoById ids={match.params.id} />} />
				<Route path="/alojamiento-perfil/" render={({ match }) => <AlojamientoPerfilById id={match.params.id} />} />
				<Route path="/alojamientos" render={props => <Alojamiento {...props} />} />
				<Route path="/gastronomia/:id" render={({ match }) => <GastronomiaById ids={match.params.id} />} />
				<Route path="/gastronomia-perfil/" render={({ match }) => <GastronomiaPerfilById id={match.params.id} />} />
				<Route path="/gastronomia" render={props => <Gastronomia {...props} />} />
				<Route path="/guia-comercial/:id" render={({ match }) => <GuiaById ids={match.params.id} />} />
				<Route path="/guia-comercial-perfil/" render={({ match }) => <GuiaComercialPerfilById id={match.params.id} />} />
				<Route path="/guia-comercial" render={props => <Guia {...props} />} />
				<Route path="/inmobiliaria/:id" render={({ match }) => <InmobiliariaById ids={match.params.id} />} />
				<Route path="/inmobiliaria-perfil/" render={({ match }) => <InmobiliariaPerfilById id={match.params.id} />} />
				<Route path="/inmobiliarias" render={props => <Inmobiliarias {...props} />} />
				<Route path="/playas" render={props => <Playas {...props} />} />
				<Route path="/playa/:id" render={({ match }) => <PlayaById ids={match.params.id} />} />
				<Route path="/playa-perfil/:id" render={({ match }) => <PlayaPerfilById id={match.params.id} />} />
				<Route path="/mapa" render={props => <Mapa {...props} />} />
				<Route path="/mensajes/" render={props => <Mensajes {...props} />} />
				<Route path="/paseos" render={props => <Paseos {...props} />} />
				<Route path="/paseo/:id" render={({ match }) => <PaseoById ids={match.params.id} />} />
				<Route path="/paseo-perfil/:id" render={({ match }) => <PaseoPerfilById id={match.params.id} />} />
				<Route path="/eventos" render={props => <Eventos {...props} />} />
				<Route path="/evento/:id" render={({ match }) => <EventoById ids={match.params.id} />} />
				<Route path="/evento-perfil/:id" render={({ match }) => <EventoPerfilById id={match.params.id} />} />
				<Route path="/usuario-perfil/" render={({ match }) => <PerfilUser id={match.params.id} />} />
				<Route path="/favoritos/" render={({ match }) => <FavoritosUsuario id={match.params.id} />} />
				<Route path="/mis-cupones/" render={({ match }) => <CuponesUsuario id={match.params.id} />} />
				<Route path="/cupones-publicados/" render={({ match }) => <CuponesPublicados id={match.params.id} />} />
				<Route path="/clientes/" render={({ match }) => <Clientes id={match.params.id} />} />
				<Route path="/analiticas/" render={({ match }) => <Analiticas id={match.params.id} />} />
				<Route path="/crear-cupon/" render={({ match }) => <CrearCupon id={match.params.id} />} />
				<Route path="/master-admin-vivipiria/" render={({ match }) => <Crud id={match.params.id} />} />
				<Route path="/lista-alojamientos/" render={({ match }) => <ListaAlojamientos id={match.params.id}  />} />
				<Route path="/lista-gastronomia/" render={({ match }) => <ListaGastronomia id={match.params.id}  />} />
				<Route path="/lista-comercios/" render={({ match }) => <ListaComercios id={match.params.id}  />} />
				<Route path="/lista-inmobiliarias/" render={({ match }) => <ListaInmobiliarias id={match.params.id}  />} />
				<Route path="/edit-alojamientos-admin/:id" render={({ match }) => <EditAlojamientoAdmin ids={match.params.id} />} />
				<Route path="/edit-gastronomia-admin/:id" render={({ match }) => <EditGastronomiaAdmin ids={match.params.id} />} />
				<Route path="/edit-comercio-admin/:id" render={({ match }) => <EditComerciosAdmin ids={match.params.id} />} />
				<Route path="/edit-inmobiliaria-admin/:id" render={({ match }) => <EditInmobiliariaAdmin ids={match.params.id} />} />
				<Route path="/lista-eventos/" render={({ match }) => <ListaEventos id={match.params.id}  />} />
				<Route path="/" render={props => <Home {...props} />} />
				<Redirect from="/dashboard" to="/admin/dashboard" />
			</Switch>
		</Router>
	</Provider>,
	document.getElementById('root')
);
