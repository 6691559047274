export const actions = {
	IMAGE_NAME:"IMAGE_NAME",
	IMAGE_NAME_SUCCESS:"IMAGE_NAME_SUCCESS",
	IMAGE_NAME_ERROR:"IMAGE_NAME_ERROR",
	GET_EMPRESAS: 'GET_EMPRESAS',
	GET_EMPRESAS_SUCCESS: 'GET_EMPRESAS_SUCCESS',
	GET_EMPRESAS_ERROR: 'GET_EMPRESAS_ERROR',
	GET_CUPONES: 'GET_CUPONES',
	GET_CUPONES_SUCCESS: 'GET_CUPONES_SUCCESS',
	GET_CUPONES_ERROR: 'GET_CUPONES_ERROR',
	GET_NOTICIAS: 'GET_NOTICIAS',
	GET_NOTICIAS_SUCCESS: 'GET_NOTICIAS_SUCCESS',
	GET_NOTICIAS_ERROR: 'GET_NOTICIAS_ERROR',
	LOGIN: 'LOGIN',
	LOGIN_SUCCESS: 'LOGIN_SUCCESS',
	LOGIN_ERROR: 'LOGIN_ERROR',
	GET_COTIZACIONES: 'GET_COTIZACIONES',
	GET_COTIZACIONES_SUCCESS: 'GET_COTIZACIONES_SUCCESS',
	GET_COTIZACIONES_ERROR: 'GET_COTIZACIONES_ERROR',
	ALOJAMIENTO_BY_ID: 'ALOJAMIENTO_BY_ID',
	ALOJAMIENTO_BY_ID_SUCCESS: 'ALOJAMIENTO_BY_ID_SUCCESS',
	ALOJAMIENTO_BY_ID_ERROR: 'ALOJAMIENTO_BY_ID_ERROR',
	GASTRONOMIA_BY_ID: 'GASTRONOMIA_BY_ID',
	GASTRONOMIA_BY_ID_SUCCESS: 'GASTRONOMIA_BY_ID_SUCCESS',
	GASTRONOMIA_BY_ID_ERROR: 'GASTRONOMIA_BY_ID_ERROR',
	GUIA_BY_ID: 'GUIA_BY_ID',
	GUIA_BY_ID_SUCCESS: 'GUIA_BY_ID_SUCCESS',
	GUIA_BY_ID_ERROR: 'GUIA_BY_ID_ERROR',
	INMOBILIARIA_BY_ID: 'INMOBILIARIA_BY_ID',
	INMOBILIARIA_BY_ID_SUCCESS: 'INMOBILIARIA_BY_ID_SUCCESS',
	INMOBILIARIA_BY_ID_ERROR: 'INMOBILIARIA_BY_ID_ERROR',
	PASEO_BY_ID: 'PASEO_BY_ID',
	PASEO_BY_ID_SUCCESS: 'PASEO_BY_ID_SUCCESS',
	PASEO_BY_ID_ERROR: 'PASEO_BY_ID_ERROR',
	EVENTO_BY_ID:"EVENTO_BY_ID",
	EVENTO_BY_ID_SUCCESS:"EVENTO_BY_ID_SUCCESS",
	EVENTO_BY_ID_ERROR:"EVENTO_BY_ID_ERROR",
	REGISTRAR_EMPRESA:"REGISTRAR_EMPRESA",
	REGISTRAR_EMPRESA_SUCCESS:"REGISTRAR_EMPRESA_SUCCESS",
	REGISTRAR_EMPRESA_ERROR:"REGISTRAR_EMPRESA_ERROR",
	REGISTRAR_USUARIO:"REGISTRAR_USUARIO",
	REGISTRAR_USUARIO_SUCCESS:"REGISTRAR_USUARIO_SUCCESS",
	REGISTRAR_USUARIO_ERROR:"REGISTRAR_USUARIO_ERROR",
	LOGIN_EMPRESA:"LOGIN_EMPRESA",
	LOGIN_EMPRESA_SUCCESS:"LOGIN_EMPRESA_SUCCESS",
	LOGIN_EMPRESA_ERROR:"LOGIN_EMPRESA_ERROR",
	PLAYAS_BY_ID:"PLAYAS_BY_ID",
	PLAYAS_BY_ID_SUCCESS:"PLAYAS_BY_ID_SUCCESS",
	PLAYAS_BY_ID_ERROR:"PLAYAS_BY_ID_ERROR",
	USER_DATA:"USER_DATA",
	USER_DATA_SUCCESS:"USER_DATA_SUCCESS",
	USER_DATA_ERROR:"USER_DATA_ERROR",
	EMPRESA_DATA:"EMPRESA_DATA",
	EMPRESA_DATA_SUCCESS:"EMPRESA_DATA_SUCCESS",
	EMPRESA_DATA_ERROR:"EMPRESA_DATA_ERROR",
	GET_COMMENTS:"GET_COMMENTS",
	GET_COMMENTS_SUCCESS:"GET_COMMENTS_SUCCESS",
	GET_COMMENTS_ERROR:"GET_COMMENTS_ERROR",
	SEND_COMMENTS:"SEND_COMMENTS",
	SEND_COMMENTS_SUCCESS:"SEND_COMMENTS_SUCCESS",
	SEND_COMMENTS_ERROR:"SEND_COMMENTS_ERROR",
	UPDATE_EMPRESAS:"UPDATE_EMPRESAS",
	UPDATE_EMPRESAS_SUCCESS:"UPDATE_EMPRESAS_SUCCESS",
	UPDATE_EMPRESAS_ERROR:"UPDATE_EMPRESAS_ERROR",
	UPDATE_USER:"UPDATE_USER",
	UPDATE_USER_SUCCESS:"UPDATE_USER_SUCCESS",
	UPDATE_USER_ERROR:"UPDATE_USER_ERROR",
	GET_MENSAJES:"GET_MENSAJES",
	GET_MENSAJES_SUCCESS:"GET_MENSAJES_SUCCESS",
	GET_MENSAJES_ERROR:"GET_MENSAJES_ERROR",
	SEND_MENSAJE:"SEND_MENSAJE",
	SEND_MENSAJE_SUCCESS:"SEND_MENSAJE_SUCCESS",
	SEND_MENSAJE_ERROR:"SEND_MENSAJE_ERROR",
	CHANGE_STATUS_MENSAJE:"CHANGE_STATUS_MENSAJE",
	CHANGE_STATUS_MENSAJE_SUCCESS:"CHANGE_STATUS_MENSAJE_SUCCESS",
	CHANGE_STATUS_MENSAJE_ERROR:"CHANGE_STATUS_MENSAJE_ERROR",
	GET_MENSAJES_ENVIADOS:"GET_MENSAJES_ENVIADOS",
	GET_MENSAJES_ENVIADOS_SUCCESS:"GET_MENSAJES_ENVIADOS_SUCCESS",
	GET_MENSAJES_ENVIADOS_ERROR:"GET_MENSAJES_ENVIADOS_ERROR",
	CHANGE_EMPRESA_STATUS:"CHANGE_EMPRESA_STATUS",
	CHANGE_EMPRESA_STATUS_SUCCESS:"CHANGE_EMPRESA_STATUS_SUCCESS",
	CHANGE_EMPRESA_STATUS_ERROR:"CHANGE_EMPRESA_STATUS_ERROR",
	BORRAR_EMPRESA:"BORRAR_EMPRESA",
	BORRAR_EMPRESA_SUCCESS:"BORRAR_EMPRESA_SUCCESS",
	BORRAR_EMPRESA_ERROR:"BORRAR_EMPRESA_ERROR",
	BORRAR_NOTICIA:"BORRAR_NOTICIA",
	BORRAR_NOTICIA_SUCCESS:"BORRAR_NOTICIA_SUCCESS",
	BORRAR_NOTICIA_ERROR:"BORRAR_NOTICIA_ERROR",
	CHANGE_NOTICIA_STATUS:"CHANGE_NOTICIA_STATUS",
	CHANGE_NOTICIA_STATUS_SUCCESS:"CHANGE_NOTICIA_STATUS_SUCCESS",
	CHANGE_NOTICIA_STATUS_ERROR:"CHANGE_NOTICIA_STATUS_ERROR",
	CHANGE_EMPRESA_PREMIUM:"CHANGE_EMPRESA_PREMIUM",
	CHANGE_EMPRESA_PREMIUM_SUCCESS:"CHANGE_EMPRESA_PREMIUM_SUCCESS",
	CHANGE_EMPRESA_PREMIUM_ERROR:"CHANGE_EMPRESA_PREMIUM_ERROR",
	CHANGE_EMPRESA_VERIFICATE:"CHANGE_EMPRESA_VERIFICATE",
	CHANGE_EMPRESA_VERIFICATE_SUCCESS:"CHANGE_EMPRESA_VERIFICATE_SUCCESS",
	CHANGE_EMPRESA_VERIFICATE_ERROR:"CHANGE_EMPRESA_VERIFICATE_ERROR",
	VERIFICAR_EMPRESA:"VERIFICAR_EMPRESA",
	VERIFICAR_EMPRESA_SUCCESS:"VERIFICAR_EMPRESA_SUCCESS",
	VERIFICAR_EMPRESA_ERROR:"VERIFICAR_EMPRESA_ERROR"
};