import React from 'react';
import { connect } from 'react-redux';
//import classNames from 'classnames';
// reactstrap components
import { Button, Container, Row, Col } from 'reactstrap';
import ReactDatetime from 'react-datetime';
import Select from 'react-select';
import { push } from 'react-router-redux';
import NotificationAlert from 'react-notification-alert';
import { sendMensaje } from '../../actions/';
import moment from 'moment';
import 'moment/locale/es'; // without this line it didn't work

import _ from 'lodash';

class Reservas extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			adultos: 0,
			menores: 0,
			edades: [],
			checkIn: null,
			checkOut: null,
			habitaciones: 0,
			mensaje: '',
			msg: '',
		};
	}

	componentDidMount() {
		moment.locale('es');
	}

	notify2 = (place, message) => {
		//var color = Math.floor(Math.random() * 5 + 1);
		let color = 4;
		let type;
		switch (color) {
			case 1:
				type = 'primary';
				break;
			case 2:
				type = 'success';
				break;
			case 3:
				type = 'danger';
				break;
			case 4:
				type = 'warning';
				break;
			case 5:
				type = 'info';
				break;
			default:
				break;
		}
		let options = {};
		options = {
			place: place,
			message: (
				<div>
					<div>
						<b>Reserva enviada, Gracias!</b>
						<h6>{message}</h6>
					</div>
				</div>
			),
			type: type,
			icon: 'tim-icons icon-check-2',
			autoDismiss: 7,
		};
		this.refs.notificationAlert.notificationAlert(options);
	};

	notify = (place, message) => {
		//var color = Math.floor(Math.random() * 5 + 1);

		let color = 1;
		let type;
		switch (color) {
			case 1:
				type = 'primary';
				break;
			case 2:
				type = 'success';
				break;
			case 3:
				type = 'danger';
				break;
			case 4:
				type = 'warning';
				break;
			case 5:
				type = 'info';
				break;
			default:
				break;
		}
		let options = {};
		options = {
			place: place,
			message: (
				<div>
					<div>
						<b>Falta algo!</b>
						<h6>{message}</h6>
					</div>
				</div>
			),
			type: type,
			icon: 'tim-icons icon-alert-circle-exc',
			autoDismiss: 7,
		};
		this.refs.notificationAlert.notificationAlert(options);
	};

	goToUrl = url => {
		window.open(url, '_blank');
	};

	getImage = imagenes => {
		const imagenesXseparado = imagenes.split(',');
		return imagenesXseparado[0];
	};

	handlerComment = e => {
		this.setState({ ...this.state, mensaje: e.target.value });
	};

	enviarMensajeToEmpresa = () => {
		const { mensaje, checkIn, checkOut, adultos, menores, edades, habitaciones } = this.state;
		const { user, ids, sendMensaje } = this.props;
		const edadesString = _.map(edades, x => {
			return 'Edad Menor = ' + x.value;
		});

		if (checkIn || checkOut || adultos || menores || habitaciones) {
			const data = {
				tipo: 'reserva',
				paraId: ids,
				deId: user._id,
				imageUser: user.imagen,
				nameUser: user.nombre,
				email: user.email,
				titulo: 'Reserva del Usuario - ' + user.nombre,
				mensaje: '',
				checkIn: checkIn,
				checkOut: checkOut,
				adultos: adultos.value,
				menores: menores.value,
				habitaciones: habitaciones.value,
				edades: _.join(edadesString, ' - '),
			};
			this.setState({
				...this.state,
				mensaje: '',
				checkIn: '',
				checkOut: '',
				adultos: '',
				menores: '',
				habitaciones: '',
				edades: [],
			});
			sendMensaje(data);
		} else {
			if (!mensaje || _.isEmpty(mensaje)) {
				this.notify('br', 'Completa todos los campor.');
			}
		}
	};

	showAlertMessage = () => {
		const { msg } = this.props;
		if (msg === 'Mensaje enviado') {
			this.notify2(
				'br',
				'Tu Reserva fue enviado correctamente. Te llegara un mensaje y un mail con la confirmacion,  Gracias!'
			);
		} else return null;
	};

	edadesMenores = () => {
		const edades = [];
		for (let i = 0; i < parseInt(this.state.menores.value); i++) {
			const component = (
				<div key={i} style={{ padding: '10px', width: '125px', float: 'left' }}>
					<h6 style={{ float: 'left' }}>{`Edad Menor ${i + 1}`}</h6>
					<Select
						className="react-select warning"
						classNamePrefix="react-select"
						name={`edad${i}`}
						value={this.state.edades[i]}
						onChange={value => {
							let edades = this.state.edades;
							edades[i] = value;
							this.setState({ ...this.state, edades: edades });
						}}
						options={[
							{ value: '1', label: '1' },
							{ value: '2', label: '2' },
							{ value: '3', label: '3' },
							{ value: '4', label: '4' },
							{ value: '5', label: '5' },
							{ value: '6', label: '6' },
							{ value: '7', label: '7' },
							{ value: '8', label: '8' },
							{ value: '9', label: '9' },
							{ value: '10', label: '10' },
							{ value: '11', label: '11' },
							{ value: '12', label: '12' },
							{ value: '13', label: '13' },
							{ value: '14', label: '14' },
							{ value: '15', label: '15' },
							{ value: '16', label: '16' },
							{ value: '17', label: '17' },
						]}
						placeholder={`Años`}
					/>
				</div>
			);

			edades.push(component);
		}

		return edades;
	};

	render() {

		const { user, empresa } = this.props;

		const token = localStorage.getItem('token');
		const tokenEmpresa = localStorage.getItem('tokenEmpresa');

		return (
			<>
				<div className="rna-container">
					<NotificationAlert ref="notificationAlert" />
				</div>
				<Container fluid>
					{this.showAlertMessage()}
					<Row className="ml-auto mr-auto" md="12">
						<Col className="ml-auto mr-auto" md="12" style={{ marginTop: '0px' }}>
							{!_.isEmpty(user) && token && (
								<div style={{ width: '100%' }}>
									<div style={{ float: 'left' }}>
										<div style={{ padding: '10px', width: '125px', float: 'left' }}>
											<h6 style={{ float: 'left', color: '#ff8d72' }}>Check-in</h6>
											<ReactDatetime
												onChange={value =>
													this.setState({
														...this.state,
														checkIn: moment(new Date(value)).format('LLLL'),
													})
												}
												inputProps={{
													className: 'form-control success',
													style: { borderColor: '#ff8d72' },
													placeholder: 'Check-in',
												}}
												timeFormat={false}
											/>
											<label style={{ float: 'left' }}>Desde las 12:00</label>
										</div>
										<div style={{ padding: '10px', width: '125px', float: 'left' }}>
											<h6 style={{ float: 'left', color: '#ff8d72' }}>Check-out</h6>
											<ReactDatetime
												onChange={value =>
													this.setState({
														...this.state,
														checkOut: moment(new Date(value)).format('LLLL'),
													})
												}
												inputProps={{
													className: 'form-control success',
													style: { borderColor: '#ff8d72' },
													placeholder: 'Check-out',
												}}
												timeFormat={false}
												style={{ borderColor: '#00f2c3' }}
											/>
											<label style={{ float: 'left' }}>Hasta las 12:00</label>
										</div>
										<div style={{ padding: '10px', width: '125px', float: 'left' }}>
											<h6 style={{ float: 'left' }}>Adultos</h6>
											<Select
												className="react-select warning"
												classNamePrefix="react-select"
												name="adultos"
												value={this.state.adultos}
												onChange={value => this.setState({ ...this.state, adultos: value })}
												options={[
													{ value: '1', label: '1' },
													{ value: '2', label: '2' },
													{ value: '3', label: '3' },
													{ value: '4', label: '4' },
													{ value: '5', label: '5' },
													{ value: '6', label: '6' },
													{ value: '7', label: '7' },
													{ value: '8', label: '8' },
													{ value: '9', label: '9' },
													{ value: '10', label: '10' },
													{ value: '11', label: '11' },
													{ value: '12', label: '12' },
													{ value: '13', label: '13' },
													{ value: '14', label: '14' },
													{ value: '15', label: '15' },
													{ value: '16', label: '16' },
													{ value: '17', label: '17' },
													{ value: '18', label: '18' },
													{ value: '19', label: '19' },
													{ value: '20', label: '20' },
												]}
												placeholder="Cantidad"
											/>
										</div>
										<div style={{ padding: '10px', width: '125px', float: 'left' }}>
											<h6 style={{ float: 'left' }}>Menores</h6>
											<Select
												className="react-select warning"
												classNamePrefix="react-select"
												name="menores"
												value={this.state.menores}
												onChange={value => this.setState({ ...this.state, menores: value })}
												options={[
													{ value: '0', label: 'No' },
													{ value: '1', label: '1' },
													{ value: '2', label: '2' },
													{ value: '3', label: '3' },
													{ value: '4', label: '4' },
													{ value: '5', label: '5' },
													{ value: '6', label: '6' },
													{ value: '7', label: '7' },
													{ value: '8', label: '8' },
													{ value: '9', label: '9' },
													{ value: '10', label: '10' },
													{ value: '11', label: '11' },
													{ value: '12', label: '12' },
													{ value: '13', label: '13' },
													{ value: '14', label: '14' },
													{ value: '15', label: '15' },
													{ value: '16', label: '16' },
													{ value: '17', label: '17' },
													{ value: '18', label: '18' },
													{ value: '19', label: '19' },
													{ value: '20', label: '20' },
												]}
												placeholder="Cantidad"
											/>
										</div>
									</div>
									<div style={{ float: 'left', width: '100%' }}>
										{this.edadesMenores()}
										<div>
											<div style={{ padding: '10px', width: '125px', float: 'left' }}>
												<h6 style={{ float: 'left' }}>Habitaciones</h6>
												<Select
													className="react-select warning"
													classNamePrefix="react-select"
													name="habitaciones"
													value={this.state.habitaciones}
													onChange={value =>
														this.setState({ ...this.state, habitaciones: value })
													}
													options={[
														{ value: '1', label: '1' },
														{ value: '2', label: '2' },
														{ value: '3', label: '3' },
														{ value: '4', label: '4' },
														{ value: '5', label: '5' },
														{ value: '6', label: '6' },
														{ value: '7', label: '7' },
														{ value: '8', label: '8' },
														{ value: '9', label: '9' },
														{ value: '10', label: '10' },
														{ value: '11', label: '11' },
														{ value: '12', label: '12' },
														{ value: '13', label: '13' },
														{ value: '14', label: '14' },
														{ value: '15', label: '15' },
														{ value: '16', label: '16' },
														{ value: '17', label: '17' },
														{ value: '18', label: '18' },
														{ value: '19', label: '19' },
														{ value: '20', label: '20' },
													]}
													placeholder="Cantidad"
												/>
											</div>

											<Button
												className="btn-simple"
												color="warning"
												style={{ borderRadius: '5px', marginTop: '30px', float: 'left' }}
												onClick={e => {
													this.enviarMensajeToEmpresa();
												}}>
												<i className="tim-icons icon-check-2"
													style={{
														fontSize: '20px',
														float: 'left',
														marginRight: '5px',
														marginTop: '-3px',
													}}
												/>
												Enviar
											</Button>
										</div>
									</div>
								</div>
							)}
							{!_.isEmpty(empresa) && !_.isEmpty(this.props.selected) && empresa._id === this.props.selected._id && tokenEmpresa && (
								<p className="text-warning" style={{ float: 'left' }}>
									<i
										className={'tim-icons icon-bulb-63'}
										style={{ fontSize: '25px', marginRight: '15px' }}
									/>
									Hey, eres tu! <br />
									<a href="https://vivipiria.uy/mensajes" target="_self">
										CLICK AQUI TUS RESERVAS!
									</a>
								</p>
							)}

							{!_.isEmpty(empresa) && !_.isEmpty(this.props.selected)&& empresa._id !== this.props.selected._id && tokenEmpresa && (
								<p className="text-warning" style={{ float: 'left' }}>
									<i
										className={'tim-icons icon-bulb-63'}
										style={{ fontSize: '25px', marginRight: '15px' }}
									/>
									Debes de ser Usuario no Empresa
								</p>
							)}

							{_.isEmpty(user) && _.isEmpty(empresa) && (
								<p className="text-warning" style={{ float: 'left' }}>
									<i
										className={'tim-icons icon-bulb-63'}
										style={{ fontSize: '25px', marginRight: '15px' }}
									/>
									Registrate para poder Reservar <br />
									<a href="https://vivipiria.uy/registro-usuario" target="_self">
										{' '}
										CLICK AQUI PARA REGISTRARTE!
									</a>
								</p>
							)}
						</Col>
					</Row>
				</Container>
			</>
		);
	}
}

const mapStateToProps = state => {
	return {
		...state.reducers.homeReducer,
	};
};

const mapDispatchToProps = dispatch => {
	return {
		goTo: path => {
			dispatch(push(path));
		},
		sendMensaje: data => {
			dispatch(sendMensaje(data));
		},
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(Reservas);
