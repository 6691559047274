import React from 'react';
import { connect } from 'react-redux';
//import classNames from 'classnames';
// reactstrap components
import { Button, Input, Container, Row, Col, Badge, Card, CardBody, CardHeader, CardTitle } from 'reactstrap';
import { push } from 'react-router-redux';
import NotificationAlert from 'react-notification-alert';
import { sendMensaje } from '../../actions/';

import moment from 'moment';
import 'moment/locale/es'; // without this line it didn't work

import _ from 'lodash';
class MensajesPrivados extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			mensaje: '',
			msg: '',
			to: '',
		};
	}

	componentDidMount() {
		moment.locale('es');
	}

	notify2 = (place, message) => {
		//var color = Math.floor(Math.random() * 5 + 1);
		let color = 2;
		let type;
		switch (color) {
			case 1:
				type = 'primary';
				break;
			case 2:
				type = 'success';
				break;
			case 3:
				type = 'danger';
				break;
			case 4:
				type = 'warning';
				break;
			case 5:
				type = 'info';
				break;
			default:
				break;
		}
		let options = {};
		options = {
			place: place,
			message: (
				<div>
					<div>
						<b>Mensaje enviado, Gracias!</b>
						<h6>{message}</h6>
					</div>
				</div>
			),
			type: type,
			icon: 'tim-icons icon-check-2',
			autoDismiss: 3,
		};
		this.refs.notificationAlert.notificationAlert(options);
	};

	notify = (place, message) => {
		//var color = Math.floor(Math.random() * 5 + 1);

		let color = 4;
		let type;
		switch (color) {
			case 1:
				type = 'primary';
				break;
			case 2:
				type = 'success';
				break;
			case 3:
				type = 'danger';
				break;
			case 4:
				type = 'warning';
				break;
			case 5:
				type = 'info';
				break;
			default:
				break;
		}
		let options = {};
		options = {
			place: place,
			message: (
				<div>
					<div>
						<b>Falta algo!</b>
						<h6>{message}</h6>
					</div>
				</div>
			),
			type: type,
			icon: 'tim-icons icon-alert-circle-exc',
			autoDismiss: 7,
		};
		this.refs.notificationAlert.notificationAlert(options);
	};

	goToUrl = url => {
		window.open(url, '_blank');
	};

	getImage = imagenes => {
		const imagenesXseparado = imagenes.split(',');
		return imagenesXseparado[0];
	};

	handlerComment = e => {
		this.setState({ ...this.state, mensaje: e.target.value });
	};

	enviarMensajeToEmpresa = () => {
		//tengo que pasarle el id del usuario a to
		const { mensaje } = this.state;
		const { empresa, to, sendMensaje } = this.props;
		if (mensaje) {
			const data = {
				paraId: to,
				deId: empresa._id,
				imageUser: !empresa.logo ? this.getImage(empresa.image) : empresa.logo,
				nameUser: empresa.nombre,
				titulo: 'Mensaje Privado de ' + empresa.nombre,
				mensaje: mensaje,
			};
			this.setState({ ...this.state, mensaje: '' });
			sendMensaje(data);
		} else {
			if (!mensaje || _.isEmpty(mensaje)) {
				this.notify('br', 'Agrega un comentario.');
			}
		}
	};

	enviarMensaje = () => {
		const { mensaje } = this.state;
		const { user, ids, sendMensaje } = this.props;
		if (mensaje) {
			const data = {
				paraId: ids,
				deId: user._id,
				imageUser: user.imagen,
				nameUser: user.nombre,
				titulo: 'Mensaje Privado de ' + user.nombre,
				mensaje: mensaje,
			};
			this.setState({ ...this.state, mensaje: '' });
			sendMensaje(data);
		} else {
			if (!mensaje || _.isEmpty(mensaje)) {
				this.notify('br', 'Agrega un Mensaje .');
			}
		}
	};

	showAlertMessage = () => {
		const { msg } = this.props;
		if (msg === 'Mensaje enviado') {
			this.notify2('br', 'Tu Mensaje privado fue enviado correctamente. Gracias!!');
		} else return null;
	};

	render() {
		const { user } = this.props;
		const { empresa } = this.props;
		const token = localStorage.getItem('token');
		const tokenEmpresa = localStorage.getItem('tokenEmpresa');

		return (
			<>
				<div className="rna-container">
					<NotificationAlert ref="notificationAlert" />
				</div>
				<Container fluid>
					{this.showAlertMessage()}
					<Row md="12">
						<Col className="ml-auto mr-auto text-center" md="12" style={{ marginTop: '15px' }}>
							{!_.isEmpty(user) && token && (
								<div style={{ float: 'left', width: '100%' }}>
									<div>
										<Input
											cols="100"
											defaultValue=""
											value={this.state.mensaje}
											placeholder="Enviale un Mensaje Privado"
											rows="3"
											type="textarea"
											onChange={e => {
												this.handlerComment(e);
											}}
											style={{ borderColor: '#00f2c3', padding: '10px' }}
										/>

										<Button
											className="btn-simple"
											color="success"
											style={{ borderRadius: '5px', marginTop: '10px', float: 'right' }}
											onClick={e => {
												this.enviarMensaje();
											}}>
											<i
												className="tim-icons icon-check-2"
												style={{
													fontSize: '20px',
													float: 'left',
													marginRight: '5px',
													marginTop: '-3px',
												}}
											/>{' '}
											Enviar
										</Button>
									</div>
								</div>
							)}
							{!_.isEmpty(empresa) && !_.isEmpty(this.props.selected) && empresa._id !== this.props.selected._id && tokenEmpresa && (
								<div style={{ float: 'left', width: '100%' }}>
									
									<div style={{ float: 'right', padding: '5px' }}></div>
									<div>
										<Input
											cols="100"
											defaultValue=""
											value={this.state.mensaje}
											placeholder={`Mensaje Privado entre tu y ${this.props.selected.nombre}`}
											rows="4"
											type="textarea"
											onChange={e => {
												this.handlerComment(e);
											}}
											style={{ borderColor: '#00f2c3', padding: '10px' }}
										/>

										<Button
											className="btn-simple"
											color="success"
											style={{ borderRadius: '5px', marginTop: '10px', float: 'right' }}
											onClick={e => {
												this.enviarMensajeToEmpresa();
											}}>
											<i
												className="tim-icons icon-check-2"
												style={{
													fontSize: '20px',
													float: 'left',
													marginRight: '5px',
													marginTop: '-3px',
												}}
											/>{' '}
											Enviar
										</Button>
									</div>
								</div>
							)}

							{!_.isEmpty(empresa) && !_.isEmpty(this.props.selected) && empresa._id === this.props.selected._id && tokenEmpresa && (
								<p className="text-warning" style={{ float: 'left' }}>
									<i
										className={'tim-icons icon-bulb-63'}
										style={{ fontSize: '25px', marginRight: '15px' }}
									/>
									Hey, eres tu! <br />
									<a href="https://vivipiria.uy/mensajes/" target="_self">
										CLICK AQUI MENSAJES!
									</a>
								</p>
							)}

							{_.isEmpty(user) && _.isEmpty(empresa) && (
								<p className="text-warning" style={{ float: 'left' }}>
									<i
										className={'tim-icons icon-bulb-63'}
										style={{ fontSize: '25px', marginRight: '15px' }}
									/>
									Registrate para enviar un Mensajes <br />
									<a href="https://vivipiria.uy/registro-usuario" target="_self">
										{' '}
										CLICK AQUI PARA REGISTRARTE!
									</a>
								</p>
							)}
						</Col>
					</Row>
				</Container>
			</>
		);
	}
}

const mapStateToProps = state => {
	return {
		...state.reducers.homeReducer,
	};
};

const mapDispatchToProps = dispatch => {
	return {
		goTo: path => {
			dispatch(push(path));
		},
		sendMensaje: data => {
			dispatch(sendMensaje(data));
		},
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(MensajesPrivados);
