import React from 'react';
import { connect } from 'react-redux';

import { Card, CardBody, CardHeader, Row, Col, CardTitle } from 'reactstrap';
import { push } from 'react-router-redux';
import AuthNavbar from '../../components/Navbars/AuthNavbar';

class TerminosCondiciones extends React.Component {
	constructor(props) {
		super(props);
		this.state = {};
	}

	reemplazarAcentos = cadena => {
		const chars = {
			á: 'a',
			é: 'e',
			í: 'i',
			ó: 'o',
			ú: 'u',
			à: 'a',
			è: 'e',
			ì: 'i',
			ò: 'o',
			ù: 'u',
			ñ: 'n',
			Á: 'A',
			É: 'E',
			Í: 'I',
			Ó: 'O',
			Ú: 'U',
			À: 'A',
			È: 'E',
			Ì: 'I',
			Ò: 'O',
			Ù: 'U',
			Ñ: 'N',
		};
		const expr = /[áàéèíìóòúùñ]/gi;
		const res = cadena.replace(expr, e => {
			return chars[e];
		});
		return res;
	};

	render() {
		return (
			<>
				<AuthNavbar />
				<div className="content">
					<Row>
						<Col md="12" style={{ padding: '50px' }}>
							<Card>
								<CardHeader className="mb-5">
									<h5 className="card-category">vivipiria.uy</h5>
									<CardTitle tag="h3">ViviPiria · Términos y condiciones</CardTitle>
								</CardHeader>
								<CardBody>
									<h4>INTRODUCCIÓN:</h4>
									<p>
										En el sitio web o Apps, de ViviPiria los productos comercializados, los
										servicios publicitados por terceros, las páginas a las que se pueda acceder a
										través del mismo, su uso y/o la descarga de información contenida en él, se
										encuentran condicionado(a)s a la aceptación completa de los términos y
										condiciones que se expresan a continuación. Quien no aceptare los presentes
										términos y condiciones, deberá abstenerse de acceder a este sitio o a las Apps,
										ya sea directa o indirectamente, y de utilizar cualquier información o servicio
										provisto por el mismo. ViviPiria puede limitar el contenido y la adquisición de
										ciertos cupones a personas mayores de edad. La creación de una cuenta en el
										sitio web o Apps, es obligatoria para la adquisición de bienes y el acceso a las
										ofertas publicitadas por terceros. La información de la cuenta, es necesaria
										para el acceso al sistema, impresión de los pedidos, verificación compras
										anteriores, modificación de preferencias, atención de servicio al cliente y para
										asegurar el uso autorizado de los cupones adquiridos.
									</p>

									<p>
										<b>Glosario:</b>
									</p>
									<p>
										<span>
											<b>SOCIO:</b>
										</span>{' '}
										Es el tercero que publicita sus servicios a través de la página web o apps de la
										Empresa y entrega un mandato a la Empresa para el recaudo de los ingresos.
									</p>
									<p>
										<span>
											<b>USUARIO:</b>
										</span>
										Es la persona natural o jurídica que accede al sitio o apps voluntariamente para
										disfrutar de las ofertas promocionadas en él.
									</p>
									<p>
										<span>
											<b>VIVIPIRIA:</b>
										</span>{' '}
										Es una Compañía que puede actuar de dos maneras:
									</p>
									<p>
										- Como Publicitador y Recaudador de terceros: En estos casos actúa como un
										catálogo permitiendo que terceros publiciten en su página web y Apps y a su vez
										recauda los pagos que usuarios hacen a los terceros en virtud de un mandato dado
										por éstos a ViviPiria.
									</p>

									<p>
										<b>Privacidad y confidencialidad</b>
									</p>
									<p>
										El Usuario declara conocer y aceptar la política de confidencialidad y se obliga
										a guardar absoluta reserva sobre los datos aquí proporcionados. Toda la
										información personal proporcionada por el Usuario, incluidos los datos de
										despacho, en los casos en que es procedente, es de responsabilidad exclusiva de
										quien la aporta.La información ingresada por el Usuario, será utilizada por
										ViviPiria para registrarlo en los programas y servicios que prestan terceros,
										crear cuentas personales, procesar y darle seguimiento a los pedidos, contestar
										correos electrónicos y proporcionar información con respecto a su cuenta y
										respecto de los bienes y servicios contratados con ViviPiria y terceros.
										ViviPiria se reserva el derecho de usar esta información para enviar correos
										electrónicos con información relativa a su cuenta o de los bienes y servicios
										contratados, como también para enviar información sobre promociones, productos o
										servicios. El Usuario siempre podrá solicitar el cese de envío de los correos
										electrónicos. ViviPiria no venderá, arrendará o intercambiará con terceros la
										información personal proporcionada por el Usuario.
									</p>
									<p>
										<b>Propiedad Intelectual e Industrial:</b>
									</p>
									<p>
										El contenido, organización, gráficas, diseño, compilación y otros aspectos del
										Sitio se encuentran protegidos por leyes de Propiedad Intelectual. Su copia,
										redistribución, uso o publicación, total o parcial, está prohibida por la ley.
										La publicación o transmisión de información o documentos en el Sitio, no
										constituye una renuncia de cualquier derecho relacionado con tales documentos o
										información.ViviPiria hace expresa reserva del ejercicio de todas las acciones,
										tanto civiles como penales, destinadas al resguardo de sus legítimos derechos de
										propiedad intelectual e industrial.
									</p>
									<p>
										<b>Acceso al Sitio:</b>
									</p>
									<p>
										El acceso al Sitio o Apps y a sus ofertas está restringido a los Usuarios de
										ViviPiria, previa suscripción de los mismos al sitio web o Apps. Es obligación y
										responsabilidad del Usuario custodiar sus datos y claves de acceso, puesto que
										son para uso exclusivo del titular de las mismas.
									</p>
									<p>
										<b>Obligaciones sobre la veracidad de la información:</b>
									</p>
									<p>
										Obligaciones sobre la veracidad de la información: Toda la información que
										proporcione el Usuario a ViviPiria, deberá ser veraz y comprobable. Para estos
										efectos, el Usuario garantiza la autenticidad de todos los datos proporcionados
										y las actualizaciones de los mismos, siendo responsable de todos los daños que
										se deriven de cualquier inexactitud en los mismos.
									</p>
									<p>
										<b>Vínculos:</b>
									</p>
									<p>
										El Sitio y las Apps puede contener hipervínculos o Links a otros sitios web, que
										no sean controlados, editados, ni tengan relación alguna con ViviPiria, no
										siendo la Empresa en consecuencia responsable del contenido ni de la exactitud
										de la información contenida en ellos. La función de los links que se encuentran
										en el Sitio es meramente informativa, es proporcionada por terceros, y se limita
										sólo a dar a conocer al Usuario otras fuentes de información relacionadas a las
										materias propias del sitio. ViviPiria no tiene responsabilidad alguna respecto
										de las páginas web que cuenten con links al Sitio. El establecimiento de estos
										vínculos no implica en forma alguna la existencia de algún tipo de relación
										entre ViviPiria y el titular de la página web en la que se establezcan los
										mismos.
									</p>
									<p>
										<b>Prohibiciones:</b>
									</p>
									<p>
										Sin perjuicio de lo ya expresado, se prohíbe a los Usuarios usar el Sitio para
										cargar, hacer avisos, transmitir y/o distribuir cualquier ítem, incluyendo sin
										limitación alguna virus computacionales, caballos troyanos, gusanos, mecanismos
										de apagado automático o cualquier otro sistema, programa o archivo disruptivo;
										interferir con la seguridad de este sitio; infringir patentes comerciales,
										marcas registradas, secretos comerciales e industriales, derechos de publicidad
										o cualquier otros derechos propietarios de cualquier persona natural o jurídica,
										colectividades, etc.; impedir o interrumpir el uso del sitio por parte de
										terceros; usar programas automáticos, mecanismos o procesos manuales para
										monitorear, copiar, resumir, o extraer información de cualquier otra forma desde
										este sitio; usar las cuentas y claves de terceros Usuarios, o cualquier otra
										información sin el consentimiento previo y por escrito de su titular; crear
										cuentas o utilizar el sitio proporcionando datos falsos; transmitir desde este
										sitio SPAM, cadenas, correo basura o cualquier otro tipo de correo masivo no
										solicitado; cargar, distribuir o diseminar desde el sitio material o información
										de carácter amenazante, de acoso, difamatorio, obsceno, pornográfico,
										fraudulento, engañador, o que de cualquier otra forma pudiere ser atentatorio
										contra la Ley, las buenas costumbres, el orden público, que viole los derechos
										de cualquier otra parte, o que contenga solicitudes de recaudación de fondos,
										entre otras.
									</p>
									<p>
										<b>Fallas de Sistema:</b>
									</p>
									<p>
										El sistema puede eventualmente no estar disponible debido a dificultades
										técnicas, fallas de Internet, o por cualquier otra circunstancia ajena a
										ViviPiria; en tales casos se procurará restablecerlo con la mayor celeridad
										posible, sin que por ello pueda imputársele algún tipo de responsabilidad a la
										Empresa.
									</p>
									<p>
										<b>Responsabilidad:</b>
									</p>
									<p>
										ViviPiria no se hace responsable por la pérdida o daño que eventualmente puedan
										sufrir los archivos que le son enviados por los Usuarios para la prestación de
										sus servicios, ya sea que estos ocurran por problemas técnicos, hechos suyos o
										de sus dependientes. En virtud de lo anterior, es el Usuario quien tiene la
										responsabilidad de respaldar la información que envía a ViviPiria.
									</p>
									<p>
										<b>Productos:</b>
									</p>
									<p>
										Los productos promocionados a través del Sitio o Apps pueden ser vendidos por
										ViviPiria o por terceros que publicitan sus productos a través del sitio o Apps.
										La responsabilidad en el cumplimiento de las obligaciones de la compraventa,
										recaerán en ViviPiria exclusivamente en aquellos casos en que figura como
										vendedor de los productos; en el resto de los casos, dicha responsabilidad
										recaerá en el tercero que publicita sus productos a través del sitio web. La
										propiedad de los productos será transferida al usuario una vez que se verifique
										la entrega del mismo en la dirección de despacho señalada al momento de realizar
										la compra. Los cambios, devoluciones o el ejercicio de la garantía, se sujetarán
										a la normativa legal vigente en aquellos casos en que la venta se realice de
										manera directa por parte de ViviPiria, en caso contrario, los cambios,
										devoluciones o garantías se ejercerán con el proveedor de los bienes. Tratándose
										de productos vendidos con despacho, para cambios y/o devoluciones si
										corresponde, o el uso de la garantía, los compradores deberán hacer llegar los
										productos a las oficinas de ViviPiria, ubicadas en la ciudad de Maldonado, salvo
										que se indique algo distinto en la respectiva publicación. El despacho de los
										productos por parte de ViviPiria, procede solamente cuando así se especifica en
										la publicación de los mismos. El plazo de despacho se especifica en cada caso.
										Los plazos de días hábiles excluyen los días sábados, domingos y festivos.
										ViviPiria podrá entregar la información de despacho otorgada por el Usuario a
										terceros con el objeto de llevar a cabo la distribución de los productos.
									</p>
									<p>
										<b>Servicios:</b>
									</p>
									<p>
										Salvo que se establezca expresamente lo contrario, los servicios son prestados
										por el tercero que publicita a través de la página web o Apps de la Empresa. El
										cumplimiento de las obligaciones asociadas a los cupones de prestaciones de
										servicios, son siempre de cargo del tercero que publicita o Socio, quien estará
										debidamente identificado en la respectiva publicación. ViviPiria, no es
										responsable por el cumplimiento de tales obligaciones ni por el contenido de
										dicha publicidad, así como el Socio es el único responsable por los posibles
										daños o perjuicios que se cause al usuario dentro del ámbito de la prestación
										del servicio. Los descuentos incorporados en los cupones, no pueden ser
										reembolsados total o parcialmente ni pueden ser acumulados con otras promociones
										que tenga vigentes el tercero que publicita. Los cupones de servicios tendrán
										las siguientes condiciones específicas: Los cupones, su correcto uso y cuidado
										son responsabilidad del adquirente. Los cupones no son nominativos, son al
										portador. Los cupones de servicios, sólo podrán ser usados hasta la fecha
										especificada en los mismos, después de lo cual caducarán definitivamente, sin
										que pueda el Usuario reclamar derecho o indemnización alguna. En el caso de los
										cupones vencidos, caducados o expirados que no hayan sido utilizados por un
										hecho o culpa del Socio, no se devolverá el valor de ellos si la devolución no
										fué solicitada dentro del periodo de validez de los mismos. En el caso de los
										cupones vencidos, caducados o expirados que no hayan sido utilizados por un
										hecho o culpa del usuario, no se devolverá el valor de ellos si la devolución no
										fué solicitada dentro del periodo de validez de los mismos. No procederá la
										devolución del precio del cupón después de que se haya recibido el servicio por
										parte del tercero publicitante. En caso de problemas con el cupón y su
										generación el usuario deberá contactarse en primer lugar con el área de Servicio
										al Cliente de ViviPiria. Las devoluciones que procediesen según en los párrafos
										Servicios y Productos, podrán ser otorgadas en dinero o créditos ViviPiria . Una
										vez aceptada una de ellas no se podrá optar por la otra. Los créditos ViviPiria
										mencionados tendrán una vigencia de 60 días, contados desde la carga de los
										mismos en la cuenta de usuario.
									</p>
									<p>
										<b>Factura:</b>
									</p>
									<p>
										Las facturas emitidas por las ventas de productos serán entregadas con el bien
										despachado o podrán ser generadas electrónicamente, para esta última opción las
										facturas de los productos adquiridos podrán ser descargadas de la página web de
										ViviPiria desde el perfil de cada usuario y/o serán enviadas ala correo
										electrónico que para estos efectos señale el usuario. En caso de servicios,
										serán entregadas por el proveedor al momento de la prestación del servicio o
										enviadas por sistema electrónico.
									</p>
									<p>
										<b>Jurisdicción y Ley Aplicable:</b>
									</p>
									<p>
										Este acuerdo estará regido por las leyes de la República Oriental del Uruguay.
										Cualquier controversia derivada del presente acuerdo, su existencia, validez,
										interpretación, alcance o cumplimiento, será sometida a las leyes aplicables y a
										los Tribunales competentes para su conocimiento.
									</p>
									<p>
										Última actualización: 15 de Noviembre de 2019. Este documento describe los
										términos y condiciones generales (los "Términos y Condiciones Generales")
										aplicables al acceso y uso de los servicios ofrecidos por ViviPiria dentro de la
										plataforma www.vivipiria.uy (Apps y web).
									</p>
									<p>
										<b>1.</b> En la página web y Apps de Vivipiria , se ofrecen Cupones sobre
										prestaciones o sobre mercancías de otras empresas (SOCIOS). Los socios son
										emitentes de los Cupones y exclusivos deudores de las prestaciones o mercancías
										indicadas en los mismos. Los socios realizan esas prestaciones con arreglo a sus
										respectivas Condiciones Generales de Contratación. Vivipiria misma no adeuda la
										realización de las prestaciones indicadas en los Cupones ni la entrega de las
										mercancías indicadas en los mismos, sino que el Vivipiria le concede a Usted
										únicamente un derecho a que el SOCIO realice la prestación. Para la utilización
										del servicio de Vivipiria rigen exclusivamente las siguientes condiciones de
										uso. Los Cupones ofrecidos por Vivipiria no están dirigidos a una prestación
										concreta (Vivipiria de acontecimiento) ni a una mercancía concreta (Vivipiria de
										mercancía) ni a un valor de prestación o mercancía concretos (Vivipiria de
										valor). En caso de que el SOCIO hubiera fijado sus propios términos y
										condiciones para los actos y contratos que realice en este Sitio o App, ellos
										aparecerán en esta página señalados con un link o indicados como parte de la
										promoción de sus ofertas y promociones y prevalecerán sobre estas.
									</p>
									<p>
										<b>2.</b>
										La página web www.vivipiria.uy así como las Apps y el sistema de Vivipiria son
										gestionados por: Appsuy.com Rincon1037 Maldonado , Maldonado ,Uruguay +598
										42268932
									</p>
									<p>
										<b>3.</b>
										Ya sea mediante confirmación del correspondiente campo en el proceso de pedido,
										o bien si Usted hace uso efectivo del servicio, quedarán aceptadas las
										condiciones de uso de Vivipiria. En tal caso, Usted reconoce que nosotros -
										Vivipiria - consideremos el uso de nuestro servicio como reconocimiento de las
										condiciones de uso. Estas condiciones de uso podrán ser consultadas, impresas o
										grabadas localmente en cualquier momento bajo
										https://www.vivipiria.uy/terminos-y-conficiones
									</p>
									<p>
										<b>4.</b>
										Los Cupones sólo están disponibles para personas que tengan capacidad legal para
										contratar. No podrán adquirir los Cupones las personas que no tengan esa
										capacidad y los menores de edad. Los actos que éstos realicen en el Sitio o Apps
										serán responsabilidad de sus padres, tutores, encargados o curadores, y por
										tanto se considerarán realizados por éstos en ejercicio de la representación
										legal con la que cuentan.
									</p>
									<p>
										<b>5.</b>
										Para adquirir los Cupones ofrecidos en el Sitio y Apps, los Usuarios Registrados
										deberán facilitar determinados datos de carácter personal. Su información
										personal se procesa y almacena en servidores o medios magnéticos que mantienen
										altos estándares de seguridad y protección tanto física como tecnológica. Para
										mayor información sobre la privacidad de los Datos Personales y casos en los que
										será revelada la información personal, se pueden consultar nuestras Políticas de
										Privacidad en cualquier momento bajo el siguiente link
										https://www.vivipiria.uy/politicas-de-privacidad
									</p>
									<p>
										<b>5.1.</b>
										Todas las transacciones monetarias realizadas por los clientes en vivipiria.uy
										son manejados por Appsuy.com.
									</p>
									<p>
										<b>5.2.</b>
										Vivipiria se reserva el derecho de usar esta información para enviar correos
										electrónicos con información relativa a su cuenta o de los bienes y servicios
										contratados, como también para enviar información sobre promociones, productos o
										servicios. El Usuario siempre podrá solicitar el cese de envío de los correos
										electrónicos.
									</p>
									<p>
										<b>6.</b>
										Vivipiria podrá adaptar estas condiciones de uso de vez en cuando, por ejemplo,
										para poner en práctica exigencias jurídicas o tener en cuenta modificaciones de
										funcionamiento. Encontrará las condiciones de uso vigentes en cada momento bajo
										https://www.vivipiria.uy/terminos-y-conficiones. En caso de que no estuviera de
										acuerdo con las modificaciones de nuestras condiciones de uso, lamentablemente
										ya no podrá hacer uso de nuestro servicio.
									</p>
									<p>
										<b>7.</b>
										Así compra Usted un Vivipiria en www.vivipiria.uy o Apps.:
									</p>
									<p>
										<b>7.1.</b>
										Confirmar el botón "comprar" o “adquirir” en la página inicial.
									</p>
									<p>
										<b>7.2.</b>
										Elija el número deseado de Cupones.
									</p>
									<p>
										<b>7.3.</b>
										Su declaración de voluntad de compra queda emitida mediante la confirmación de
										las condiciones de uso y presión del botón "comprar" o “adquirir”. La aceptación
										de la oferta por parte de Vivipiria tiene lugar con la confirmación en forma de
										envío de un código del Vivipiria.
									</p>
									<p>
										<b>7.4.</b>
										Cuando hayamos registrado con éxito el cobro o adquisición del Cupón por Usted,
										le enviaremos el Cupón a su mail y lo tendrá disponible en la sección cupones de
										su cuenta en Vivipiria asi como tambien los otros cupones que haya solicitado .
									</p>
									<p>
										<b>7.5.</b>
										Imprima o muestre el Cupón y hágalo efectivo en el correspondiente
										establecimiento del SOCIO o, en su caso, ante dicho SOCIO.
									</p>
									<p>
										<b>8.</b>
										Información sobre el derecho de revocación
									</p>
									<p>
										<b>8.1.</b>
										Si Usted es consumidor, podrá revocar la declaración contractual de compra del
										cupón en el plazo de DIEZ DÍAS sin necesidad de indicar las causas. El plazo
										comenzará a contar a partir de la recepción del cupón en su casilla de correo.
									</p>
									<p>
										<b>8.2.</b>
										Una vez hecho efectivo el vale frente al SOCIO, ya no es posible la revocación.
									</p>
									<p>
										<b>8.3.</b>
										Consecuencias de la revocación: En el caso de una revocación eficaz, se han de
										restituir las prestaciones recibidas por ambas partes y, en su caso, entregar
										los frutos o utilidades percibidos (ej. venta-jas de uso). Las obligaciones de
										devolución de pagos han de cumplirse en el plazo de 30 días. El plazo comienza
										para Usted con la salida del envío de su declaración de revocación o de su vale,
										y para nosotros, con su recepción.
									</p>
									<p>
										<b>8.4.</b>
										La revocación prevista en el apartado 8.1 anterior no será aplicable para los
										Cupones que incluyan la provisión de transportes aéreos, marítimos y/o
										fluviales. En tales casos, la compra por parte del consumidor implica la
										renuncia irrevocable de éste de ejercer el derecho de revocación referido.
									</p>
									<p>
										<b>9.</b>
										La duración de la venta de un Cupón está limitada temporalmente, estableciéndose
										de forma individual para cada descuento por parte de Vivipiria. Transcurrido el
										período de duración ya no es posible la compra.
									</p>
									<p>
										<b>10.</b>
										La venta del Cupón tampoco se produce hasta que el Cupón no haya registrado con
										éxito el cobro si es necesario en caso contrario será inmediato. En caso de no
										tener éxito con el primer intento de cobro (por ej. porque no exista la cuenta
										indicada o no disponga de saldo suficiente, el número de la tarjeta de crédito
										sea erróneo o la misma esté al límite) tampoco se producirá la venta del Cupón.
									</p>
									<p>
										<b>11.</b>
										Todos los Cupones están dotados de dos claros números de código, que el SOCIO
										compara con una lista de que dispone, cuando se presentan los Cupones para
										hacerlos efectivos. El Cupón es transmisible. No se autoriza la reproducción,
										edición o manipulación de los Cupones. En caso de sospecha fundada de
										reproducción ilícita, Vivipiria se reserva el derecho de transmitir los datos
										archivados al SOCIO respectivo e iniciar trámites legales.
									</p>
									<p>
										<b>12.</b>
										En la medida en que no se indique lo contrario, podrá hacerse uso de cada Cupón
										una sola vez, haciéndolo efectivo frente al SOCIO acudiendo al restaurante o
										tienda correspondiente o participando en cualquier otro acontecimiento (o
										percibiendo cualquier otra mercancía). En caso de que no se consumiera
										totalmente el valor del Cupón o no se hiciera uso de toda la prestación, caduca
										la diferencia no utilizada. No es posible el reembolso.
									</p>
									<p>
										<b>13.</b>
										En la medida en que no se indique lo contrario, el Vivipiria no concede un
										derecho a hacer uso de prestaciones o a la adquisición de mercancías en un
										momento concreto. Por ello, recomendamos acordar individualmente con el SOCIO el
										momento de realización de la prestación.
									</p>
									<p>
										<b>14.</b>
										En caso de que en el Cupón figure un período de vigencia, el Cupón sólo podrá
										hacerse efectivo frente al SOCIO dentro de ese período de vigencia. Una vez
										transcurrido el período de vigencia, el Cupón pierde su validez y no se puede
										hacer efectivo.
									</p>
									<p>
										<b>15.</b>
										Vivipiria garantiza que el SOCIO hará efectivo el Cupón, es decir, que realizará
										las prestaciones con arreglo a las condiciones certificadas en el Cupón, siempre
										y cuando Usted presente el Cupón antes del comienzo de la realización de la
										prestación. Aparte de lo anterior, Vivipiria no está obligada a realizar
										reembolsos y/o indemnizaciones de daños y perjuicios. Vivipiria no asume
										garantía alguna por los productos o servicios del SOCIO adquiridos por el
										cliente. El SOCIO respectivo realiza la prestación certificada en el Groupon en
										nombre propio y por cuenta propia frente al cliente, por lo que Vivipiria no
										responde frente al cliente por incumplimiento de obligaciones del SOCIO a la
										hora de realizar la prestación. En caso de que surgieran problemas a la hora de
										hacer efectivo el Cupón o de realizar la prestación, Vivipiria intentará
										encontrar una solución.
									</p>
									<p>
										<b>16.</b>
										Vivipiria se reserva el derecho de modificar en cualquier momento el alcance y
										las funciones de su página web o Apps, de limitarlas o cancelarlas. Aunque nos
										esforzamos por ofrecer nuestro servicio sin molestias técnicas, en particular,
										los trabajos de mantenimiento, la continuación del desarrollo y/o otras
										molestias pueden limitar y/o parcialmente interrumpir las posibilidades de uso.
										Bajo determinadas circunstancias se pueden producir aquí pérdidas de datos.
										Vivipiria no asume por ello garantía alguna sobre la disponibilidad del servicio
										o la ausencia de molestias técnicas o pérdidas de datos.
									</p>
									<p>
										<b>17.</b>
										Vivipiria y los SOCIOS se reservan todos los derechos de Propiedad intelectual y
										demás derechos sobre el servicio así como sobre los contenidos publicados,
										informaciones, imágenes, videos y bancos de datos (a continuación denominada
										"propiedad protegida"). Por ello, está expresamente prohibida cualquier
										modificación, reproducción, publicación, transmisión a terceros y/o demás uso o
										explotación de la propiedad protegida sin el consentimiento previo por escrito
										de Vivipiria o del SOCIO respectivamente afectado.
									</p>
									<p>
										<b>18.</b>
										No está permitida ninguna acción o uso de dispositivo, software, u otro medio
										tendiente a interferir tanto en las actividades y operatoria de Vivipiria como
										en los ofertas, descripciones, cuentas o bases de datos de Vivipiria. Cualquier
										intromisión, tentativa o actividad violatoria o contraria a las leyes sobre
										derecho de propiedad intelectual y/o a las prohibiciones estipuladas en este
										contrato harán pasible a su responsable de las acciones legales pertinentes, y a
										las sanciones previstas por este acuerdo, así como lo hará responsable de
										indemnizar los daños ocasionados.
									</p>
									<p>
										<b>19.</b>
										En caso de que disposiciones concretas de estas Condiciones Generales de
										Contratación o del contrato concluido con la parte contratante fueran ineficaces
										en todo o en parte, ello no afectará a la eficacia del resto del contrato.
									</p>
									<p>
										<b>20.</b>
										Este acuerdo estará regido en todos sus puntos por las leyes vigentes en la
										República Oriental del Uruguay. Cualquier controversia derivada del presente
										acuerdo, su existencia, validez, interpretación, alcance o cumplimiento, será
										sometida a los tribunales judiciales de Maldonado.
									</p>
								</CardBody>
							</Card>
						</Col>
					</Row>
				</div>
			</>
		);
	}
}

const mapStateToProps = state => {
	//console.log(JSON.stringify(state));
	return {
		...state.reducers.homeReducer,
	};
};

const mapDispatchToProps = dispatch => {
	return {
		goTo: path => {
			dispatch(push(path));
		},
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(TerminosCondiciones);
