import React from 'react';
import { connect } from 'react-redux';
import { updateEmpresa } from '../../actions/';
import Footer from '../../components/Footer/Footer';
import NotificationAlert from 'react-notification-alert';
import AuthNavbar from '../../components/Navbars/AuthNavbar';
import Select from 'react-select';
import _ from 'lodash';
import classnames from 'classnames';
//import UploadImages from './UploadImages.jsx';
import Dropzone from 'react-dropzone';
import axios from 'axios';
import { push } from 'react-router-redux';

import {
	Button,
	Card,
	CardBody,
	CardFooter,
	Form,
	FormGroup,
	Input,
	InputGroupAddon,
	InputGroupText,
	InputGroup,
	Container,
	Row,
	Col,
	Label
} from 'reactstrap';

const servicios = {
	desayuno: false,
	desayunobufet: false,
	canchatenis: false,
	casino: false,
	serviciotraslado: false,
	trasladoaeropuerto: false,
	ascensor: false,
	masajes: false,
	spa: false,
	entretenimientoinfantil: false,
	entretenimientonocturno: false,
	bar:false,
	barbacoa: false,
	jardin: false,
	zonafumadores: false,
	frenteplaya: false,
	golf: false,
	piscinainterior: false,
	piscinaexterior: false,
	wifi: false,
	parking: false,
	cocina: false,
	minibar: false,
	utensiliosdecocina: false,
	restaurante: false,
	aireacondicionado: false,
	gimnasio: false,
	sauna: false,
	cajafuerte: false,
	toallas: false,
	ropadecama: false,
	mascotas: false,
	saladejuegos: false,
	tvpantallaplana: false,
	adaptadoparasillasruedas: false,
	salasdereuniones: false,
	serviciolimpiezadiario: false,
	serviciodelavanderia: false,
	recepciontodahoras: false,
	efectivo:false,
	tranferenciabancaria:false,
	targetascredito: {
		visa: false,
		mastercard: false,
		oca: false,
		diners: false,
		cabal: false,
		americanexpress: false,
		anda: false,
		lider: false,
		discover: false,
	},
	targetasdebito: {
		visa: false,
		mastercard: false,
		anda: false,
	},
};

class GuiaComercialPerfilById extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			msg: '',
			empresa: {
				nombre: '',
				email: '',
				telefono: '',
				direccion: '',
				image: '',
				descripcionCorta: '',
				descripcionLarga: '',
				tenerencuenta:'',
				latitude: '',
				longitude: '',
				horarios: '',
				whatsapp: '',
				web: '',
				facebook: '',
				instagram: '',
				twitter: '',
				youTube: '',
			},
			categoria: {
				value: '',
				label: '',
			},
			servicios: servicios,
			imagenes: '',
			selectedFiles: null,
			emailState: 'has-success'
		};
	}

	static getDerivedStateFromProps(nextProps, prevState) {
		if (nextProps.msg !== prevState.msg) {
			return { msg: nextProps.msg };
		}

		if (nextProps.empresa !== prevState.empresa) {
			return { selectedFiles: null, empresa: prevState.empresa };
		} else return null;
	}

	componentDidUpdate(prevProps, prevState) {
		if (prevProps.empresa !== this.props.empresa) {
			this.setState({
				selectedFiles: null,
				empresa: this.props.empresa,
				categoria: { value: this.props.empresa.categoria, label: this.getLabel(this.props.empresa.categoria) },
				servicios: this.props.empresa.servicios
			});
		}
		if (prevState.msg !== this.props.msg){
			if(this.props.msg === "El email ya esta registrado" ){
				this.notify2('br', "El email ya esta registrado");
				this.setState({...this.state, emailState: 'has-danger', msg:""});
			}else if(this.props.msg === "Datos actualizados correctamente"){
				this.notify('br', 'Datos actualizados correctamente');
				this.setState({...this.state, emailState: 'has-success', msg:""});
			}
		}
	}

	componentDidMount() {
		this.setState({
			selectedFiles: null,
			empresa: this.props.empresa,
			categoria: { value: this.props.empresa.categoria, label: this.getLabel(this.props.empresa.categoria) },
			servicios: this.props.empresa.servicios
		});
	}

	getLabel = str => {
		const str2 = str ? str.toLowerCase() : '';
		switch (str2) {
			case 'comercios,farmacia':
				return 'Farmacias';
			case 'comercios,supermercado':
				return 'Supermercados';
			case 'comercios,autoservice':
				return 'Autoservicios';
			case 'comercios,estacion':
				return 'Estaciones de servicio';
			case 'comercios,heladeria':
				return 'Heladerias';
			case 'comercios,panaderia':
				return 'Panaderias';
			case 'comercios,ropa':
				return 'Tiendas de ropa';
			case 'comercios,celula':
				return 'Celulares y accesorios';
			case 'comercios,electro':
				return 'Electrodomesticos';
			case 'comercios,mueble':
				return 'Muebles';
			case 'comercios,tela':
				return 'Telas';
			case 'comercios,barraca':
                return 'Barracas y Ferreterias';
            case 'comercios,otros':
                    return 'Otros';
			default:
				return 'Comercio';
		}
	};

	notify = (place, message) => {
		//var color = Math.floor(Math.random() * 5 + 1);

		let color = 2;
		let type;
		switch (color) {
			case 1:
				type = 'primary';
				break;
			case 2:
				type = 'success';
				break;
			case 3:
				type = 'danger';
				break;
			case 4:
				type = 'warning';
				break;
			case 5:
				type = 'info';
				break;
			default:
				break;
		}
		let options = {};
		options = {
			place: place,
			message: (
				<div>
					<div>
						<b>Últimos cambios guardados</b>
						<h6>{message}</h6>
					</div>
				</div>
			),
			type: type,
			icon: 'tim-icons icon-check-2',
			autoDismiss: 4,
		};
		this.refs.notificationAlert.notificationAlert(options);
	};

	notify2 = (place, message) => {
		//var color = Math.floor(Math.random() * 5 + 1);

		let color = 1;
		let type;
		switch (color) {
			case 1:
				type = 'primary';
				break;
			case 2:
				type = 'success';
				break;
			case 3:
				type = 'danger';
				break;
			case 4:
				type = 'warning';
				break;
			case 5:
				type = 'info';
				break;
			default:
				break;
		}
		let options = {};
		options = {
			place: place,
			message: (
				<div>
					<div>
						<b>Tenemos algunos Errores </b>
						<h6>{message}</h6>
					</div>
				</div>
			),
			type: type,
			icon: 'tim-icons icon-alert-circle-exc',
			autoDismiss: 4,
		};
		this.refs.notificationAlert.notificationAlert(options);
	};

	verifyEmail = value => {
		var emailRex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
		if (emailRex.test(value)) {
			return true;
		}
		return false;
	};

	// function that verifies if a string has a given length or not
	verifyLength = (value, length) => {
		if (value.length >= length) {
			return true;
		}
		return false;
	};

	verifyNumber = value => {
		var numberRex = new RegExp('^[0-9]+$');
		if (numberRex.test(value)) {
			return true;
		}
		return false;
	};

	isValidated = () => {
		if (this.state.emailState === 'has-success') {
			return true;
		} else {
			if (this.state.emailState !== 'has-success') {
				this.notify2('br', 'Email incorrecto');
				this.setState({ emailState: 'has-danger' });
			}
			return false;
		}
	};

	change = (event, stateName, type, stateNameEqualTo, maxValue) => {
		switch (type) {
			case 'email':
				if (this.verifyEmail(event.target.value)) {
					const newChange = { ...this.state.empresa, email: event.target.value  };
					this.setState({ ...this.state, empresa: newChange, [stateName + 'State']: 'has-success' });
				} else {
					const newChange = { ...this.state.empresa, email: event.target.value  };
					this.setState({ ...this.state, empresa: newChange, [stateName + 'State']: 'has-danger' });
				}
				break;
			case 'length':
				if (this.verifyLength(event.target.value, stateNameEqualTo)) {
					this.setState({ [stateName + 'State']: 'has-success' });
				} else {
					this.setState({ [stateName + 'State']: 'has-danger' });
				}
				break;
			case 'number':
				if (this.verifyNumber(event.target.value, stateNameEqualTo)) {
					this.setState({ [stateName + 'State']: 'has-success' });
				} else {
					this.setState({ [stateName + 'State']: 'has-danger' });
				}
				break;
			case 'equal':
				if (this.verifyPasswords(event.target.value, stateNameEqualTo)) {
					this.setState({ [stateName + 'State']: 'has-success' });
				} else {
					this.setState({ [stateName + 'State']: 'has-danger' });
				}
				break;
			case 'passwordV':
				if (this.verifyPasswordsData(event.target.value, stateNameEqualTo)) {
					this.setState({ [stateName + 'State']: 'has-success' });
				} else {
					this.setState({ [stateName + 'State']: 'has-danger', msg: 'Los passwords no son iguales' });
				}
				break;
			default:
				break;
		}
		//this.setState({ [stateName]: event.target.value });
	};

	handleInput = event => {
		let newChange = {};
		if (event.target.name === 'email') {
			newChange = { ...this.state.empresa, email: event.target.value };
			this.setState({ ...this.state, empresa: newChange });
		} else if (event.target.name === 'nombre') {
			newChange = { ...this.state.empresa, nombre: event.target.value };
			this.setState({ ...this.state, empresa: newChange });
		} else if (event.target.name === 'telefono') {
			newChange = { ...this.state.empresa, telefono: event.target.value };
			this.setState({ ...this.state, empresa: newChange });
		} else if (event.target.name === 'categoria') {
			this.setState({ ...this.state, categoria: newChange });
		} else if (event.target.name === 'direccion') {
			newChange = { ...this.state.empresa, direccion: event.target.value };
			this.setState({ ...this.state, empresa: newChange });
		} else if (event.target.name === 'descripcionCorta') {
			newChange = { ...this.state.empresa, descripcionCorta: event.target.value };
			this.setState({ ...this.state, empresa: newChange });
		} else if (event.target.name === 'descripcionLarga') {
			newChange = { ...this.state.empresa, descripcionLarga: event.target.value };
			this.setState({ ...this.state, empresa: newChange });
		} else if (event.target.name === 'tenerencuenta') {
			newChange = { ...this.state.empresa, tenerencuenta: event.target.value };
			this.setState({ ...this.state, empresa: newChange });
		}
		else if (event.target.name === 'latitude') {
			newChange = { ...this.state.empresa, latitude: event.target.value };
			this.setState({ ...this.state, empresa: newChange });
		} else if (event.target.name === 'longitude') {
			newChange = { ...this.state.empresa, longitude: event.target.value };
			this.setState({ ...this.state, empresa: newChange });
		} else if (event.target.name === 'horarios') {
			newChange = { ...this.state.empresa, horarios: event.target.value };
			this.setState({ ...this.state, empresa: newChange });
		} else if (event.target.name === 'whatsapp') {
			newChange = { ...this.state.empresa, whatsapp: event.target.value };
			this.setState({ ...this.state, empresa: newChange });
		} else if (event.target.name === 'web') {
			newChange = { ...this.state.empresa, web: event.target.value };
			this.setState({ ...this.state, empresa: newChange });
		} else if (event.target.name === 'facebook') {
			newChange = { ...this.state.empresa, facebook: event.target.value };
			this.setState({ ...this.state, empresa: newChange });
		} else if (event.target.name === 'instagram') {
			newChange = { ...this.state.empresa, instagram: event.target.value };
			this.setState({ ...this.state, empresa: newChange });
		} else if (event.target.name === 'twitter') {
			newChange = { ...this.state.empresa, twitter: event.target.value };
			this.setState({ ...this.state, empresa: newChange });
		} else if (event.target.name === 'youTube') {
			newChange = { ...this.state.empresa, youTube: event.target.value };
			this.setState({ ...this.state, empresa: newChange });
		}else {
			const valorTengo = _.get(this.state.servicios, event.target.name);
			const valorEditado = _.set(this.state.servicios ,event.target.name , !valorTengo );
			this.setState({ ...this.state, servicios: valorEditado });
		}
	};

	handleInputTarjetasCredito = event => {
		const valorTengo = _.get(this.state.servicios.targetascredito, event.target.name);
		const valorEditado = _.set(this.state.servicios.targetascredito ,event.target.name , !valorTengo );
		const valorServicios =_.set(this.state.servicios ,'targetascredito' , valorEditado );
		this.setState({ ...this.state, servicios: valorServicios });
	}

	handleInputTarjetasDebito = event => {
		const valorTengo = _.get(this.state.servicios.targetasdebito, event.target.name);
		const valorEditado = _.set(this.state.servicios.targetasdebito ,event.target.name , !valorTengo );
		const valorServicios =_.set(this.state.servicios ,'targetasdebito' , valorEditado );
		this.setState({ ...this.state, servicios: valorServicios });
	}


	//ES PARA IMAGEN
	onDrop = acceptedFiles => {
		let files = [];
		let st = [];
		if (!this.state.selectedFiles) {
			st = acceptedFiles;
		} else {
			files = this.state.selectedFiles;
			st = _.concat(files, acceptedFiles);
		}

		this.setState({
			selectedFiles: st,
		});

		this.handlerSend();
	};

	removeImageHandler = (e, x, whereIs) => {
		if (whereIs === 'images') {
			const images = this.state.image !== '' ? _.split(this.state.empresa.image, ',') : [];
			const imagenesFiltered = _.map(images, m => {
				if (m !== x) {
					return m;
				}
			});
			const filtered = _.compact(_.map(imagenesFiltered));
			this.removeFile(x); // this in case you prefer upload before
			const newEmpresa = { ...this.state.empresa, image: filtered };
			this.setState({ ...this.state, empresa: newEmpresa });
		} else {
			const { selectedFiles } = this.state;
			const selectedFiltered = _.filter(selectedFiles, s => s !== x);
			this.setState({ ...this.state, selectedFiles: selectedFiltered });
		}
	};
	removeFile = fileName => {
		if (fileName) {
			axios
				.post(
					'https://vivipiria.herokuapp.com/delete-file',
					{ fileName: fileName },
					{
						headers: {
							accept: 'application/json',
							'Accept-Language': 'en-US,en;q=0.8',
						},
					}
				)
				.then(response => {
					if (200 === response.status) {
						// If file size is larger than expected.
						if (response.data.error) {
							console.log(response.data.error);
						} else {
							// Success
							console.log('filedata removed', fileName);
							this.handlerSend();
						}
					}
				})
				.catch(error => {
					// If another error
					console.log(error);
				});
		} else {
			// if file not selected throw error
			console.log('Send a fileName to remove');
		}
	};

	singleFileChangedHandler = event => {
		this.setState({
			selectedFile: event.target.files[0],
		});
	};

	multipleFileChangedHandler = files => {
		this.setState({
			selectedFiles: files,
		});
	};

	showtheImages = () => {
		const { selectedFiles } = this.state;
		const images = this.state.image !== '' ? _.split(this.state.empresa.image, ',') : [];
		let oldImages = null;
		oldImages = _.map(images, x => {
			if (x !== '') {
				const img = (
					<div key={x} style={{ margin: '15px', width: '140px', float: 'left', textAlign: 'center' }}>
						{this.props.loading ? (
							''
						) : (
							<Button
								className="btn-icon btn-round"
								color="pinterest"
								onClick={e => {
									this.removeImageHandler(e, x, 'images');
								}}
							>
								<i style={{ fontSize: '20px' }} className="tim-icons icon-simple-remove" />
							</Button>
						)}
						<img
							alt=""
							width="140px"
							src={'https://d39etc8ensw3fs.cloudfront.net/fit-in/250x180/' + x}
						></img>
					</div>
				);
				return img;
			} else return null;
		});

		const returnImages = _.map(selectedFiles, x => {
			const img = (
				<div key={x} style={{ margin: '15px', width: '140px', float: 'left', textAlign: 'center' }}>
					<img alt="..." src={require('../../assets/img/loader.gif')} width={'70px'} />
					<h6>CARGANDO IMAGENES...</h6>
				</div>
			);
			return img;
		});

		return (
			<div>
				{oldImages}
				{returnImages}
			</div>
		);
	};

	handlecategoria = category => {
		this.setState({ ...this.state, categoria: category });
	};

	handlerSend = () => {
		const data = new FormData();
		const id = this.props.empresa._id;
		const { empresa, selectedFiles, categoria, servicios } = this.state;
		if (categoria.value === '') {
			categoria.value = empresa.categoria;
		}
		if (selectedFiles) {
			for (let i = 0; i < selectedFiles.length; i++) {
				data.append('galleryImage', selectedFiles[i], selectedFiles[i].name);
			}
		}
		data.append('categoria', categoria.value);
		data.append('nombre', empresa.nombre);
		data.append('email', empresa.email);
		data.append('password', this.props.empresa.password);
		data.append('telefono', empresa.telefono);
		data.append('servicios', JSON.stringify(servicios));
		data.append('direccion', empresa.direccion);
		data.append('image', empresa.image);
		data.append('descripcionCorta', empresa.descripcionCorta);
		data.append('descripcionLarga', empresa.descripcionLarga);
		data.append('latitude', empresa.latitude);
		data.append('longitude', empresa.longitude);
		data.append('horarios', empresa.horarios);
		data.append('whatsapp', empresa.whatsapp);
		data.append('web', empresa.web);
		data.append('facebook', empresa.facebook);
		data.append('instagram', empresa.instagram);
		data.append('twitter', empresa.twitter);
		data.append('youTube', empresa.youTube);
		data.append('tenerencuenta', empresa.tenerencuenta);
		this.props.updateEmpresa(data, id);
	};

	componentUploadImage = () => {
		const maxSize = 15000000;
		const component = (
			<div>
				<Dropzone onDrop={this.onDrop} accept="image/png, image/jpeg" minSize={0} maxSize={maxSize}>
					{({ getRootProps, getInputProps, isDragActive, isDragReject, rejectedFiles }) => {
						const isFileTooLarge = rejectedFiles.length > 0 && rejectedFiles[0].size > maxSize;
						return (
							<div {...getRootProps()}>
								<input {...getInputProps()} />
								<div
									style={{
										width: '100%',
										height: '200px',
										border: '5px dashed #5603ad',
										marginBottom: '30px',
										textAlign: 'center',
										color: '#8965e0',
									}}
								>
									{this.props.loading ? (
										<img
											alt="..."
											style={{ marginTop: '30px' }}
											src={require('../../assets/img/loader.gif')}
											width={'70px'}
										/>
									) : (
										<i
											style={{ fontSize: '60px', marginTop: '30px' }}
											className="tim-icons icon-image-02"
										/>
									)}
									<h6 style={{ marginTop: '20px' }}>
										{!isDragActive && 'Click aqui o arrastra y suelta tus imagenes'}
										{isDragActive && !isDragReject && 'Sueltalo como si te estuviera quemando!'}
										{isDragReject && 'Este tipo de formato no lo aceptamos! JPG o PNG '}
									</h6>
									{isFileTooLarge && (
										<div className="text-danger mt-2">Imagen demasiado pesada max 3Mb</div>
									)}
								</div>
							</div>
						);
					}}
				</Dropzone>
				<div>
					<h6>Imagenes publicas</h6>
				</div>
				<div>{this.state.selectedFiles || this.state.empresa.image !== '' ? this.showtheImages() : ''}</div>
			</div>
		);
		return component;
	};

	handlerEvent = (id) =>{
        this.props.goToGuiaById(id);
    }

	render() {
		return (
			<>
				<AuthNavbar />
				<div className="rna-container">
					<NotificationAlert ref="notificationAlert" />
				</div>
				<Container>
					<Row className="ml-auto mr-auto text-center">
						<Col md="12">
							<div style={{ paddingTop: '30px' }}>
								<img
									alt="..."
									src={'http://appsuy.com/images_vivi_piria/icono_vivipiria_130.png'}
									width="80px"
								/>
							</div>
							<div>
								<h3 className="info-title">Edita el perfil Comercios</h3>
							</div>
						</Col>
					</Row>
				</Container>
				<div className="content" style={{ marginTop: '30px' }}>
					<Container>
						<Row>
							<Col className="mr-auto" md="7">
								<Card className="card-register card-black" style={{ margin: '0px' }}>
									<CardBody>
										{this.componentUploadImage()}
										<Form className="form">
											<InputGroup>
												<InputGroupAddon addonType="prepend">
													<InputGroupText>
														<i className="tim-icons icon-spaceship" />
													</InputGroupText>
												</InputGroupAddon>
												<Input
													placeholder="Nombre del comercio"
													name="nombre"
													type="text"
													value={this.state.empresa.nombre || ''}
													onChange={this.handleInput}
												/>
											</InputGroup>
											<InputGroup>
												<InputGroupAddon addonType="prepend"></InputGroupAddon>
												<Col
													className="ml-auto mr-auto text-left"
													md="12"
													sm="12"
													style={{ padding: '0px' }}
												>
													<Select
														className="react-select success"
														classNamePrefix="react-select"
														name="categoria"
														value={this.state.categoria}
														onChange={value => this.handlecategoria(value)}
														options={[
															{ value: 'comercios,farmacia', label: 'Farmacias' },
															{ value: 'comercios,supermercado', label: 'Supermercados' },
															{ value: 'comercios,autoservice', label: 'Autoservicios' },
															{
																value: 'comercios,estacion',
																label: 'Estaciones de servicio',
															},
															{ value: 'comercios,heladeria', label: 'Heladerias' },
															{ value: 'comercios,panaderia', label: 'Panaderias' },
															{ value: 'comercios,ropa', label: 'Tiendas de ropa' },
															{
																value: 'comercios,celula',
																label: 'Celulares y accesorios',
															},
															{ value: 'comercios,electro', label: 'Electrodomesticos' },
															{ value: 'comercios,mueble', label: 'Muebles' },
															{ value: 'comercios,tela', label: 'Telas' },
															{
																value: 'comercios,barraca',
																label: 'Barracas y Ferreterias',
															},{
																value: 'comercios,otros',
																label: 'Otros',
															},
														]}
														placeholder="Tipo de comercio"
													/>
												</Col>
											</InputGroup>
											<InputGroup
												className={classnames(this.state.emailState, {
													'input-group-focus': this.state.emailFocus,
												})}
											>
												<InputGroupAddon addonType="prepend">
													<InputGroupText>
														<i className="tim-icons icon-email-85" />
													</InputGroupText>
												</InputGroupAddon>
												<Input
													name="email"
													placeholder="Email..."
													type="email"
													value={this.state.empresa.email || ''}
													onChange={e => this.change(e, 'email', 'email')}
													onFocus={e => this.setState({ emailFocus: true })}
													onBlur={e => this.setState({ emailFocus: false })}
												/>
											</InputGroup>
											<InputGroup>
												<InputGroupAddon addonType="prepend">
													<InputGroupText>
														<i className="tim-icons icon-mobile" />
													</InputGroupText>
												</InputGroupAddon>
												<Input
													placeholder="Telefono /cell de contacto"
													name="telefono"
													value={this.state.empresa.telefono || ''}
													type="text"
													onChange={this.handleInput}
												/>
											</InputGroup>
											<InputGroup>
												<InputGroupAddon addonType="prepend">
													<InputGroupText>
														<i className="tim-icons icon-square-pin" />
													</InputGroupText>
												</InputGroupAddon>
												<Input
													placeholder="Dirección"
													name="direccion"
													value={this.state.empresa.direccion || ''}
													type="text"
													onChange={this.handleInput}
												/>
											</InputGroup>
											<InputGroup>
												<InputGroupAddon addonType="prepend">
													<InputGroupText>
														<i className="tim-icons icon-notes" />
													</InputGroupText>
												</InputGroupAddon>
												<Input
													name="descripcionCorta"
													cols="50"
													value={this.state.empresa.descripcionCorta || ''}
													placeholder="Descripcion corta"
													rows="2"
													type="textarea"
													onChange={this.handleInput}
												/>
											</InputGroup>
											<InputGroup>
												<InputGroupAddon addonType="prepend">
													<InputGroupText>
														<i className="tim-icons icon-paper" />
													</InputGroupText>
												</InputGroupAddon>
												<Input
													name="descripcionLarga"
													cols="100"
													value={this.state.empresa.descripcionLarga || ''}
													placeholder="DescripcionLarga"
													rows="4"
													type="textarea"
													onChange={this.handleInput}
												/>
											</InputGroup>
											<InputGroup>
												<InputGroupAddon addonType="prepend">
													<InputGroupText>
														<i className="tim-icons icon-alert-circle-exc text-success" />
													</InputGroupText>
												</InputGroupAddon>
												<Input
													name="tenerencuenta"
													cols="100"
													value={this.state.empresa.tenerencuenta || ''}
													placeholder="Información a tener en cuenta?"
													rows="4"
													type="textarea"
													onChange={this.handleInput}
												/>
											</InputGroup>
											<InputGroup>
												<InputGroupAddon addonType="prepend">
													<InputGroupText>
														<i className="tim-icons  icon-compass-05" />
													</InputGroupText>
												</InputGroupAddon>
												<Input
													placeholder="latitude"
													name="latitude"
													value={this.state.empresa.latitude || ''}
													type="text"
													onChange={this.handleInput}
												/>
											</InputGroup>
											<InputGroup>
												<InputGroupAddon addonType="prepend">
													<InputGroupText>
														<i className="tim-icons  icon-compass-05" />
													</InputGroupText>
												</InputGroupAddon>
												<Input
													placeholder="longitude"
													name="longitude"
													value={this.state.empresa.longitude || ''}
													type="text"
													onChange={this.handleInput}
												/>
											</InputGroup>
											<InputGroup>
												<InputGroupAddon addonType="prepend">
													<InputGroupText>
														<i className="tim-icons icon-watch-time" />
													</InputGroupText>
												</InputGroupAddon>
												<Input
													placeholder="horarios"
													name="horarios"
													value={this.state.empresa.horarios || ''}
													type="text"
													onChange={this.handleInput}
												/>
											</InputGroup>
											<InputGroup>
												<InputGroupAddon addonType="prepend">
													<InputGroupText>
														<img
															alt=""
															src={
																'http://appsuy.com/images_vivi_piria/icono-whatsapp.png'
															}
															width="13px"
														/>
													</InputGroupText>
												</InputGroupAddon>
												<Input
													placeholder="whatsapp"
													name="whatsapp"
													value={this.state.empresa.whatsapp || ''}
													type="text"
													onChange={this.handleInput}
												/>
											</InputGroup>
											<InputGroup>
												<InputGroupAddon addonType="prepend">
													<InputGroupText>
														<i className="tim-icons icon-world" />
													</InputGroupText>
												</InputGroupAddon>
												<Input
													placeholder="web"
													name="web"
													value={this.state.empresa.web || ''}
													type="text"
													onChange={this.handleInput}
												/>
											</InputGroup>
											<InputGroup>
												<InputGroupAddon addonType="prepend">
													<InputGroupText>
														<i className="fab fa-facebook-square ml-auto" />
													</InputGroupText>
												</InputGroupAddon>
												<Input
													placeholder="facebook"
													name="facebook"
													value={this.state.empresa.facebook || ''}
													type="text"
													onChange={this.handleInput}
												/>
											</InputGroup>
											<InputGroup>
												<InputGroupAddon addonType="prepend">
													<InputGroupText>
														<i className="fab fa-instagram ml-auto mr-auto" />
													</InputGroupText>
												</InputGroupAddon>
												<Input
													placeholder="instagram"
													name="instagram"
													value={this.state.empresa.instagram || ''}
													type="text"
													onChange={this.handleInput}
												/>
											</InputGroup>
											<InputGroup>
												<InputGroupAddon addonType="prepend">
													<InputGroupText>
														<i className="fab fa-twitter ml-auto" />
													</InputGroupText>
												</InputGroupAddon>
												<Input
													placeholder="twitter"
													name="twitter"
													value={this.state.empresa.twitter || ''}
													type="text"
													onChange={this.handleInput}
												/>
											</InputGroup>
											<InputGroup>
												<InputGroupAddon addonType="prepend">
													<InputGroupText>
														<i className="fab fa-youtube ml-auto" />
													</InputGroupText>
												</InputGroupAddon>
												<Input
													placeholder="youTube"
													name="youTube"
													value={this.state.empresa.youTube || ''}
													type="text"
													onChange={this.handleInput}
												/>
											</InputGroup>
											<FormGroup check >
												<h6>Metodos de pago</h6>
												<h6 style={{marginTop:'10px'}}>Efectivo</h6>
												<Label check style={{margin:'10px'}}>
													<Input type="checkbox" name="efectivo" checked= {this.state.empresa  && this.state.servicios ? this.state.servicios.efectivo : false}  onChange={this.handleInput}/>
													<span className="form-check-sign" />
													Efectivo
												</Label>
												<h6 style={{marginTop:'10px'}}>Tarjetas Crédito</h6>
												<Label check style={{margin:'10px'}}>
													<Input type="checkbox" name="visa" checked= {this.state.empresa  && this.state.servicios ? this.state.servicios.targetascredito.visa : false} onChange={this.handleInputTarjetasCredito}/>
													<span className="form-check-sign" />
													Visa
												</Label>
												<Label check style={{margin:'10px'}}>
													<Input type="checkbox" name="mastercard" checked= {this.state.empresa  && this.state.servicios ? this.state.servicios.targetascredito.mastercard : false} onChange={this.handleInputTarjetasCredito}/>
													<span className="form-check-sign" />
													MasterCard
												</Label>
												<Label check style={{margin:'10px'}}>
													<Input type="checkbox" name="oca" checked= {this.state.empresa  && this.state.servicios ? this.state.servicios.targetascredito.oca : false} onChange={this.handleInputTarjetasCredito}/>
													<span className="form-check-sign" />
													Oca
												</Label>
												
												<Label check style={{margin:'10px'}}>
													<Input type="checkbox" name="diners" checked= {this.state.empresa  && this.state.servicios ? this.state.servicios.targetascredito.diners : false} onChange={this.handleInputTarjetasCredito}/>
													<span className="form-check-sign" />
													Diners Club
												</Label>
												<Label check style={{margin:'10px'}}>
													<Input type="checkbox" name="americanexpress" checked= {this.state.empresa  && this.state.servicios ? this.state.servicios.targetascredito.americanexpress : false} onChange={this.handleInputTarjetasCredito}/>
													<span className="form-check-sign" />
													American express
												</Label>
												<Label check style={{margin:'10px'}}>
													<Input type="checkbox" name="anda" checked= {this.state.empresa  && this.state.servicios ? this.state.servicios.targetascredito.anda : false} onChange={this.handleInputTarjetasCredito}/>
													<span className="form-check-sign" />
													Anda
												</Label>
												<Label check style={{margin:'10px'}}>
													<Input type="checkbox" name="lider" checked= {this.state.empresa  && this.state.servicios ? this.state.servicios.targetascredito.lider : false} onChange={this.handleInputTarjetasCredito}/>
													<span className="form-check-sign" />
													Lider
												</Label>
												<Label check style={{margin:'10px'}}>
													<Input type="checkbox" name="discover" checked= {this.state.empresa  && this.state.servicios ? this.state.servicios.targetascredito.discover : false} onChange={this.handleInputTarjetasCredito}/>
													<span className="form-check-sign" />
													Discover
												</Label>
												<h6 style={{marginTop:'10px'}} >Tarjetas Débito</h6>
												<Label check style={{margin:'10px'}}>
													<Input type="checkbox" name="visa" checked= {this.state.empresa  && this.state.servicios ? this.state.servicios.targetasdebito.visa : false} onChange={this.handleInputTarjetasDebito}/>
													<span className="form-check-sign" />
													Visa
												</Label>
												<Label check style={{margin:'10px'}}>
													<Input type="checkbox" name="mastercard" checked= {this.state.empresa  && this.state.servicios ? this.state.servicios.targetasdebito.mastercard : false} onChange={this.handleInputTarjetasDebito}/>
													<span className="form-check-sign" />
													MasterCard
												</Label>
												<Label check style={{margin:'10px'}}>
													<Input type="checkbox" name="anda" checked= {this.state.empresa  && this.state.servicios ? this.state.servicios.targetasdebito.anda : false} onChange={this.handleInputTarjetasDebito}/>
													<span className="form-check-sign" />
													Anda
												</Label>
												<h6 style={{marginTop:'20px'}}>  </h6>
											</FormGroup>
										</Form>
									</CardBody>
									<CardFooter>
										<Button
											block
											className="btn-simple mb-3"
											color="success"
											style={{ borderRadius: '5px' }}
											onClick={e => {
												this.handlerSend();
											}}
											size="lg"
										>
											Actualizar Perfil
										</Button>
									</CardFooter>
								</Card>
							</Col>
							<Col className="ml-auto" md="5">
							<div className="info-area info-horizontal" style={{ marginTop: '30px' }}>
                                <Button
									className="btn-simple"
									color="warning"
									style={{ borderRadius: '5px', margin: '10px',width:'100%'  }}
									onClick={e => {
										this.handlerEvent(this.state.empresa._id);
									}}
								>
									Ver Perfil Publico
								</Button>
								<Button
									className="btn-simple"
									color="primary"
									style={{ borderRadius: '5px', margin: '10px',width:'100%'  }}
									onClick={e => {
										//this.handlerEvent(this.state.empresa._id);
										console.log('mostrar formulario para cargar productos destacados');
									}}
								>
									Agregar Producto Destacado
								</Button>
								<Button
									className="btn-simple"
									color="info"
									style={{ borderRadius: '5px', margin: '10px',width:'100%'  }}
									onClick={e => {
										//this.handlerEvent(this.state.empresa._id);
										console.log('mostrar listado productos destacados');
									}}
								>
									Ver Productos Destacados
								</Button>
								</div>
								<div className="info-area info-horizontal mt-5">
									<div className="icon icon-warning" style={{ marginTop: '5px' }}>
										<i className="icon-warning tim-icons icon-shape-star" />
									</div>
									<div className="description">
										<h3 className="info-title"> Intenta tener tu perfil completo </h3>
										<p className="description">
											Es muy importante que tu perfil esté actualizado y lo más completo posible,
											así nuestros usuarios pueden contactarte y también conocer a tu empresa.
										</p>
									</div>
								</div>
								<div className="info-area info-horizontal" style={{ marginTop: '30px' }}>
									<div className="icon icon-primary" style={{ marginTop: '5px' }}>
										<i className="tim-icons icon-tag" />
									</div>
									<div className="description">
										<h3 className="info-title">Crea Cupones y atrae clientes</h3>
										<p className="description">
											Crea cupones de descuentos y beneficios para nuestros usuarios,estaras en
											todos nuestros newslaters aparte de eso te ayudará a atraer clientes a tu
											negocio y crear una base de datos de clientes que obtuvieron el cupón y lo
											canjearon, así poder hacerles futuras ofertas y lo más importante crear un
											vínculo con tu cliente.
										</p>
									</div>
								</div>
								<div className="info-area info-horizontal" style={{ marginTop: '30px' }}>
									<div className="icon icon-info" style={{ marginTop: '0px' }}>
										<i className="tim-icons icon-bulb-63" />
									</div>
									<div className="description">
										<h3 className="info-title">Soporte 24/7</h3>
										<p className="description">
											Nuestro soporte esta 24/7 , si necesitas ayuda, si tienes una duda o quieres
											hablar con nosotros para sugerirnos algo, no dudes en enviarnos un mail a
											contacto@vivipiria.uy o a nuestro <br />
											<img
												alt=""
												src={'http://appsuy.com/images_vivi_piria/icono-whatsapp.png'}
												width="30px"
											/>
											<a
												href={'https://api.whatsapp.com/send?phone=59892826776'}
												target={'_blank'}
											>
												{' Contacto via WhatsApp '}
											</a>
										</p>
									</div>
								</div>
							</Col>
						</Row>
					</Container>
				</div>
				<Footer />
			</>
		);
	}
}

const mapStateToProps = state => {
	return {
		...state.reducers.homeReducer,
		empresa: state.reducers.homeReducer.empresa,
	};
};

const mapDispatchToProps = dispatch => {
	return {
		goToGuiaById: x => {
            dispatch(push('/guia-comercial/' + x));
		},
		updateEmpresa: (data, id) => dispatch(updateEmpresa(data, id)),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(GuiaComercialPerfilById);
