import { createStore, combineReducers, applyMiddleware } from "redux";
import { routerReducer, routerMiddleware } from "react-router-redux";
import { createLogger } from "redux-logger";
import createSagaMiddleware from "redux-saga";
import reducers from "./reducers.js";
import { generator } from './sagas/generators';
import { createBrowserHistory } from 'history';


import persistAuthLocally from "./persist-auth-locally";

const loggerMiddleware = createLogger();
const sagaMiddleware = createSagaMiddleware();
export const history = createBrowserHistory();
const historyRouterMiddleware = routerMiddleware(history);

let retrievedState;
try {
  retrievedState = localStorage.getItem("authStore");
  if (retrievedState === null){
    retrievedState = {};
  }
  retrievedState = JSON.parse(retrievedState);
} catch (err){
  retrievedState = {};
}

export const store = createStore(
  combineReducers({
  reducers,
  router: routerReducer
}),
applyMiddleware(sagaMiddleware, loggerMiddleware, historyRouterMiddleware, persistAuthLocally));

sagaMiddleware.run(generator);