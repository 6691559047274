import React from 'react';
import { connect } from 'react-redux';
import { updateUser } from '../../actions/';
import Footer from '../../components/Footer/Footer';
import AuthNavbar from '../../components/Navbars/AuthNavbar';
import _ from 'lodash';
//import UploadImages from './UploadImages.jsx';
import Dropzone from 'react-dropzone';
import axios from 'axios';
// reactstrap components
import {
	Button,
	Card,
	CardBody,
	CardFooter,
	Form,
	Input,
	InputGroupAddon,
	InputGroupText,
	InputGroup,
	Container,
	Row,
	Col,
} from 'reactstrap';

class PerfilUser extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			usuario: {
				nombre: '',
				email: '',
				telefono: '',
				imagen: '',
				whatsapp: '',
				facebook: '',
				instagram: '',
				twitter: '',
			},
			imagenes: '',
			selectedFiles: null,
		};
	}

	static getDerivedStateFromProps(nextProps, prevState) {
		if (nextProps.usuario !== prevState.usuario) {
			return { selectedFiles: null, usuario: prevState.usuario };
		} else return null;
	}

	componentDidUpdate(prevProps, prevState) {
		if (prevProps.usuario !== this.props.usuario) {
			this.setState({
				selectedFiles: null,
				usuario: this.props.usuario,
			});
		}
	}

	componentDidMount() {
		this.setState({
			selectedFiles: null,
			usuario: this.props.usuario,
		});
	}

	handleInput = event => {
		let newChange = {};

		if (event.target.name === 'email') {
			newChange = { ...this.state.usuario, email: event.target.value };
			this.setState({ ...this.state, usuario: newChange });
		} else if (event.target.name === 'nombre') {
			newChange = { ...this.state.usuario, nombre: event.target.value };
			this.setState({ ...this.state, usuario: newChange });
		} else if (event.target.name === 'telefono') {
			newChange = { ...this.state.usuario, telefono: event.target.value };
			this.setState({ ...this.state, usuario: newChange });
		} else if (event.target.name === 'whatsapp') {
			newChange = { ...this.state.usuario, whatsapp: event.target.value };
			this.setState({ ...this.state, usuario: newChange });
		} else if (event.target.name === 'facebook') {
			newChange = { ...this.state.usuario, facebook: event.target.value };
			this.setState({ ...this.state, usuario: newChange });
		} else if (event.target.name === 'instagram') {
			newChange = { ...this.state.usuario, instagram: event.target.value };
			this.setState({ ...this.state, usuario: newChange });
		} else if (event.target.name === 'twitter') {
			newChange = { ...this.state.usuario, twitter: event.target.value };
			this.setState({ ...this.state, usuario: newChange });
		}
	};

	//ES PARA IMAGEN
	onDrop = acceptedFiles => {
		let files = [];
		let st = [];
		if (!this.state.selectedFiles) {
			st = acceptedFiles;
		} else {
			files = this.state.selectedFiles;
			st = _.concat(files, acceptedFiles);
		}

		this.setState({
			selectedFiles: st,
		});

		this.handlerSend();
	};

	removeImageHandler = (e, x, whereIs) => {
		if (whereIs === 'images') {
			const images = this.state.usuario.imagen !== '' ? _.split(this.state.usuario.imagen, ',') : [];
			const imagenesFiltered = _.map(images, m => {
				if (m !== x) {
					return m;
				}
			});
			const filtered = _.compact(_.map(imagenesFiltered));
			this.removeFile(x); // this in case you prefer upload before
			const newEmpresa = { ...this.state.usuario, imagen: filtered };
			this.setState({ ...this.state, usuario: newEmpresa });
		} else {
			console.log("entro aqui");
			const { selectedFiles } = this.state;
			const selectedFiltered = _.filter(selectedFiles, s => s !== x);
			this.setState({ ...this.state, selectedFiles: selectedFiltered });
		}
	};

	removeFile = fileName => {
		if (fileName) {
			axios
				.post(
					'https://vivipiria.herokuapp.com/delete-file',
					{ fileName: fileName },
					{
						headers: {
							accept: 'application/json',
							'Accept-Language': 'en-US,en;q=0.8',
						},
					}
				)
				.then(response => {
					if (200 === response.status) {
						// If file size is larger than expected.
						if (response.data.error) {
							console.log(response.data.error);
						} else {
							// Success
							console.log('filedata removed', fileName);
							this.handlerSend();
						}
					}
				})
				.catch(error => {
					// If another error
					console.log(error);
				});
		} else {
			// if file not selected throw error
			console.log('Send a fileName to remove');
		}
	};

	singleFileChangedHandler = event => {
		this.setState({
			selectedFile: event.target.files[0],
		});
	};

	multipleFileChangedHandler = files => {
		this.setState({
			selectedFiles: files,
		});
	};

	showtheImages = () => {

        const { selectedFiles } = this.state;

        const images = this.state.usuario.imagen !== '' ? _.split(this.state.usuario.imagen, ',') : []
		let oldImages = null;
		oldImages = _.map(images, x => {
			if (x !== '') {
				const img = (
					<div key={x} style={{ margin: '15px', width: '140px', float: 'left', textAlign: 'center' }}>
						{this.props.loading ? (
							''
						) : (
							<Button
								className="btn-icon btn-round"
								color="pinterest"
								onClick={e => {
									this.removeImageHandler(e, x, 'images');
								}}
							>
								<i style={{ fontSize: '20px' }} className="tim-icons icon-simple-remove" />
							</Button>
						)}
						<img
							alt=""
							width="140px"
							src={'https://d39etc8ensw3fs.cloudfront.net/fit-in/250x180/' + x}
						></img>
					</div>
				);
				return img;
			} else return null;
		});

        
		const returnImages = _.map(selectedFiles, x => {
			const img = (
				<div key={x} style={{ margin: '15px', width: '140px', float: 'left', textAlign: 'center' }}>
					<img alt="..." src={require('../../assets/img/loader.gif')} width={'70px'} />
					<h6>CARGANDO IMAGENES...</h6>
				</div>
			);
			return img;
		});

		return (
			<div>
				{oldImages}
				{returnImages}
			</div>
		);
	};


	handlerSend = () => {
		const data = new FormData();
		const id = this.props.usuario._id;
		const { usuario, selectedFiles } = this.state;
		if (selectedFiles) {
			for (let i = 0; i < selectedFiles.length; i++) {
				data.append('galleryImage', selectedFiles[i], selectedFiles[i].name);
			}
		}

		data.append('nombre', usuario.nombre);
		data.append('email', usuario.email);
		data.append('password', this.props.empresa.password);
		data.append('telefono', usuario.telefono);
		data.append('whatsapp', usuario.whatsapp);
		data.append('image', usuario.imagen);
		data.append('facebook', usuario.facebook);
		data.append('instagram', usuario.instagram);
		data.append('twitter', usuario.twitter);
		this.props.updateUser(data, id);
	};

	componentUploadImage = () => {
		const maxSize = 3000000;
		const component = (
			<div>
				<Dropzone onDrop={this.onDrop} accept="image/png, image/jpeg" minSize={0} maxSize={maxSize}>
					{({ getRootProps, getInputProps, isDragActive, isDragReject, rejectedFiles }) => {
						const isFileTooLarge = rejectedFiles.length > 0 && rejectedFiles[0].size > maxSize;
						return (
							<div {...getRootProps()}>
								<input {...getInputProps()} />
								<div
									style={{
										width: '100%',
										height: '200px',
										border: '5px dashed #5603ad',
										marginBottom: '30px',
										textAlign: 'center',
										color: '#8965e0',
									}}
								>
									{this.props.loading ? (
										<img
											alt="..."
											style={{ marginTop: '30px' }}
											src={require('../../assets/img/loader.gif')}
											width={'70px'}
										/>
									) : (
										<i
											style={{ fontSize: '60px', marginTop: '30px' }}
											className="tim-icons icon-image-02"
										/>
									)}
									<h6 style={{ marginTop: '20px' }}>
										{!isDragActive && 'Click aqui o arrastra y suelta tus imagenes'}
										{isDragActive && !isDragReject && 'Sueltalo como si te estuviera quemando!'}
										{isDragReject && 'Este tipo de formato no lo aceptamos! JPG o PNG '}
									</h6>
									{isFileTooLarge && (
										<div className="text-danger mt-2">Imagen demasiado pesada max 3Mb</div>
									)}
								</div>
							</div>
						);
					}}
				</Dropzone>
				<div>
					<h6>Imagenes Perfil</h6>
				</div>
				<div>{this.state.usuario ? this.showtheImages() : ''}</div>
			</div>
		);
		return component;
	};

	render() {
		return (
			<>
				<AuthNavbar />
				<Container>
					<Row className="ml-auto mr-auto text-center">
						<Col md="12">
							<div style={{ paddingTop: '30px' }}>
								<img
									alt="..."
									src={'http://appsuy.com/images_vivi_piria/icono_vivipiria_130.png'}
									width="80px"
								/>
							</div>
							<div>
								<h3 className="info-title">Edita el perfil</h3>
							</div>
						</Col>
					</Row>
				</Container>
				<div className="content" style={{ marginTop: '30px' }}>
					<Container>
						<Row>
							<Col className="mr-auto" md="7">
								<Card className="card-register card-black" style={{ margin: '0px' }}>
									<CardBody>
										{this.componentUploadImage()}
										<Form className="form">
											<InputGroup>
												<InputGroupAddon addonType="prepend">
													<InputGroupText>
														<i className="tim-icons icon-spaceship text-success" />
													</InputGroupText>
												</InputGroupAddon>
												<Input
													placeholder="Nombre completo"
													name="nombre"
													type="text"
													value={this.state.usuario.nombre || ''}
													onChange={this.handleInput}
												/>
											</InputGroup>
											<InputGroup>
												<InputGroupAddon addonType="prepend">
													<InputGroupText>
														<i className="tim-icons icon-email-85 text-success" />
													</InputGroupText>
												</InputGroupAddon>
												<Input
													placeholder="Email de contacto"
													name="email"
													value={this.state.usuario.email || ''}
													type="text"
													onChange={this.handleInput}
												/>
											</InputGroup>
											<InputGroup>
												<InputGroupAddon addonType="prepend">
													<InputGroupText>
														<i className="tim-icons icon-mobile text-success" />
													</InputGroupText>
												</InputGroupAddon>
												<Input
													placeholder="Telefono /cell de contacto"
													name="telefono"
													value={this.state.usuario.telefono || ''}
													type="text"
													onChange={this.handleInput}
												/>
											</InputGroup>
											<InputGroup>
												<InputGroupAddon addonType="prepend">
													<InputGroupText>
														<img
															alt=""
															src={
																'http://appsuy.com/images_vivi_piria/icono-whatsapp.png'
															}
															width="13px"
														/>
													</InputGroupText>
												</InputGroupAddon>
												<Input
													placeholder="whatsapp"
													name="whatsapp"
													value={this.state.usuario.whatsapp || ''}
													type="text"
													onChange={this.handleInput}
												/>
											</InputGroup>
											<InputGroup>
												<InputGroupAddon addonType="prepend">
													<InputGroupText>
														<i className="fab fa-facebook-square ml-auto text-success" />
													</InputGroupText>
												</InputGroupAddon>
												<Input
													placeholder="facebook"
													name="facebook"
													value={this.state.usuario.facebook || ''}
													type="text"
													onChange={this.handleInput}
												/>
											</InputGroup>
											<InputGroup>
												<InputGroupAddon addonType="prepend">
													<InputGroupText>
														<i className="fab fa-instagram ml-auto mr-auto text-success" />
													</InputGroupText>
												</InputGroupAddon>
												<Input
													placeholder="instagram"
													name="instagram"
													value={this.state.usuario.instagram || ''}
													type="text"
													onChange={this.handleInput}
												/>
											</InputGroup>
											<InputGroup>
												<InputGroupAddon addonType="prepend">
													<InputGroupText>
														<i className="fab fa-twitter ml-auto text-success" />
													</InputGroupText>
												</InputGroupAddon>
												<Input
													placeholder="twitter"
													name="twitter"
													value={this.state.usuario.twitter || ''}
													type="text"
													onChange={this.handleInput}
												/>
											</InputGroup>
										</Form>
									</CardBody>
									<CardFooter>
										<Button
											block
											className="btn-simple mb-3"
											color="success"
											style={{ borderRadius: '5px' }}
											onClick={e => {
												this.handlerSend();
											}}
											size="lg"
										>
											Actualizar Perfil
										</Button>
									</CardFooter>
								</Card>
							</Col>
							<Col className="ml-auto" md="5">
								<div className="info-area info-horizontal mt-5">
									<div className="icon icon-warning" style={{ marginTop: '5px' }}>
										<i className="icon-warning tim-icons icon-shape-star" />
									</div>
									<div className="description">
										<h3 className="info-title"> Intenta tener tu perfil completo </h3>
										<p className="description">
											Es muy importante que tu perfil esté actualizado y lo más completo posible,
											así podemos contactarte en caso de ganar algún regalo de nuestros socios y
											también para conocerte.
										</p>
									</div>
								</div>
								<div className="info-area info-horizontal" style={{ marginTop: '30px' }}>
									<div className="icon icon-primary" style={{ marginTop: '5px' }}>
										<i className="tim-icons icon-tag" />
									</div>
									<div className="description">
										<h3 className="info-title">Obtiene Cupones, Regalos y beneficios</h3>
										<p className="description">
											Si tu perfil está completo podrás adquirir cupones de descuentos y
											beneficios exclusivos y participar de sorteos con muchos regalos .
										</p>
									</div>
								</div>
								<div className="info-area info-horizontal" style={{ marginTop: '30px' }}>
									<div className="icon icon-info" style={{ marginTop: '0px' }}>
										<i className="tim-icons icon-bulb-63" />
									</div>
									<div className="description">
										<h3 className="info-title">Soporte 24/7</h3>
										<p className="description">
											Nuestro soporte esta 24/7 , si necesitas ayuda, si tienes una duda o quieres
											hablar con nosotros para sugerirnos algo, no dudes en enviarnos un mail a
											contacto@vivipiria.uy o a nuestro <br />
											<img
												alt=""
												src={'http://appsuy.com/images_vivi_piria/icono-whatsapp.png'}
												width="30px"
											/>
											<a
												href={'https://api.whatsapp.com/send?phone=59892826776'}
												target={'_blank'}
											>
												{' Contacto via WhatsApp '}
											</a>
										</p>
									</div>
								</div>
							</Col>
						</Row>
					</Container>
				</div>
				<Footer />
			</>
		);
	}
}

const mapStateToProps = state => {
	return {
		...state.reducers.homeReducer,
		usuario: state.reducers.homeReducer.user,
	};
};

const mapDispatchToProps = dispatch => {
	return {
		updateUser: (data, id) => dispatch(updateUser(data, id)),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(PerfilUser);
