import React from 'react';
import { connect } from 'react-redux';
//import classNames from 'classnames';
// reactstrap components
import { Button, Input, Container, Row, Col, Badge, Card, CardBody } from 'reactstrap';
import { push } from 'react-router-redux';
import StarRatings from 'react-star-ratings';
import NotificationAlert from 'react-notification-alert';
import { getComments, sendComment } from '../../actions/';

import moment from 'moment';
import 'moment/locale/es'; // without this line it didn't work

import _ from 'lodash';
class Comments extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			rating: 0,
			comentariosAll: null,
			comment: '',
			msg: '',
		};
	}

	static getDerivedStateFromProps(nextProps, prevState) {
		if (nextProps.comentariosAll !== prevState.comentariosAll) {
			const commentsAll = _.reverse(nextProps.comentariosAll);
			return { comentariosAll: commentsAll };
		} else if (nextProps.msg !== prevState.msg) {
			return { msg: nextProps.msg, comment: '', rating: 0 };
		} else return null;
	}

	componentDidUpdate(prevProps, prevState) {
		if (prevProps.comentariosAll !== this.props.comentariosAll) {
			const commentsAll = _.reverse(this.props.comentariosAll);
			this.setState({ comentariosAll: commentsAll });
		}
	}

	componentDidMount() {
		moment.locale('es');
		const { ids } = this.props;
		if (this.state.comentariosAll != null) {
		} else {
			this.props.getAllsCommnets(ids);
		}
	}

	changeRating = (newRating, name) => {
		this.setState({ ...this.state, rating: newRating });
	};

	notify2 = (place, message) => {
		//var color = Math.floor(Math.random() * 5 + 1);
		let color = 2;
		let type;
		switch (color) {
			case 1:
				type = 'primary';
				break;
			case 2:
				type = 'success';
				break;
			case 3:
				type = 'danger';
				break;
			case 4:
				type = 'warning';
				break;
			case 5:
				type = 'info';
				break;
			default:
				break;
		}
		let options = {};
		options = {
			place: place,
			message: (
				<div>
					<div>
						<b>Comentario enviado, Gracias!</b>
						<h6>{message}</h6>
					</div>
				</div>
			),
			type: type,
			icon: 'tim-icons icon-check-2',
			autoDismiss: 3,
		};
		this.refs.notificationAlert.notificationAlert(options);
	};

	notify = (place, message) => {
		//var color = Math.floor(Math.random() * 5 + 1);

		let color = 4;
		let type;
		switch (color) {
			case 1:
				type = 'primary';
				break;
			case 2:
				type = 'success';
				break;
			case 3:
				type = 'danger';
				break;
			case 4:
				type = 'warning';
				break;
			case 5:
				type = 'info';
				break;
			default:
				break;
		}
		let options = {};
		options = {
			place: place,
			message: (
				<div>
					<div>
						<b>Falta algo!</b>
						<h6>{message}</h6>
					</div>
				</div>
			),
			type: type,
			icon: 'tim-icons icon-alert-circle-exc',
			autoDismiss: 7,
		};
		this.refs.notificationAlert.notificationAlert(options);
	};

	goToUrl = url => {
		window.open(url, '_blank');
	};

	calificacion = califi => {
		switch (califi) {
			case '1':
				return (
					<h5>
						<i
							className={'tim-icons icon-shape-star text-primary'}
							style={{ fontSize: '20px', marginRight: '13px' }}
						/>
						No lo recomiendo
					</h5>
				);
			case '2':
				return (
					<h5>
						<i className={'tim-icons icon-shape-star text-primary'} style={{ fontSize: '20px' }} />
						<i
							className={'tim-icons icon-shape-star text-primary'}
							style={{ fontSize: '20px', marginRight: '13px' }}
						/>
						No me gusto
					</h5>
				);
			case '3':
				return (
					<h5>
						<i className={'tim-icons icon-shape-star text-warning'} style={{ fontSize: '20px' }} />
						<i className={'tim-icons icon-shape-star text-warning'} style={{ fontSize: '20px' }} />
						<i
							className={'tim-icons icon-shape-star text-warning'}
							style={{ fontSize: '20px', marginRight: '13px' }}
						/>
						Aceptable
					</h5>
				);
			case '4':
				return (
					<h5>
						<i className={'tim-icons icon-shape-star text-success'} style={{ fontSize: '20px' }} />
						<i className={'tim-icons icon-shape-star text-success'} style={{ fontSize: '20px' }} />
						<i className={'tim-icons icon-shape-star text-success'} style={{ fontSize: '20px' }} />
						<i
							className={'tim-icons icon-shape-star text-success'}
							style={{ fontSize: '20px', marginRight: '13px' }}
						/>
						Muy recomendable!
					</h5>
				);
			case '5':
				return (
					<h5>
						<i className={'tim-icons icon-shape-star text-success'} style={{ fontSize: '20px' }} />
						<i className={'tim-icons icon-shape-star text-success'} style={{ fontSize: '20px' }} />
						<i className={'tim-icons icon-shape-star text-success'} style={{ fontSize: '20px' }} />
						<i className={'tim-icons icon-shape-star text-success'} style={{ fontSize: '20px' }} />
						<i
							className={'tim-icons icon-shape-star text-success'}
							style={{ fontSize: '20px', marginRight: '13px' }}
						/>
						Exelente!
					</h5>
				);

			default:
				return null;
		}
	};

	showListCommnets = () => {
		const { comentariosAll } = this.state;
		const listComments = _.map(comentariosAll, x => {
			if (x.status) {
				let date = moment(x.createdAt);
				return (
					<li className="timeline-inverted">
						<div className="timeline-badge danger">
							<div
								className="photo"
								style={{ width: '50px', height: '50px', marginBottom: '20px', float: 'left' }}
							>
								<img
									style={{ borderRadius: '50%' }}
									alt="..."
									src={
										!x.imageUser
											? 'https://d39etc8ensw3fs.cloudfront.net/fit-in/200x200/defaultPerfil.png'
											: 'https://d39etc8ensw3fs.cloudfront.net/fit-in/200x200/' + x.imageUser
									}
								/>
							</div>
						</div>
						<div className="timeline-panel">
							<div className="timeline-heading">
								<Badge color="danger">{x.nameUser}</Badge>
								<h5>{date.subtract().calendar()}</h5>
							</div>
							
								<i className="ti-time" />
								{this.calificacion(x.calificacion)}
							
							<div className="timeline-body">
								<p>{x.comentario}</p>
							</div>
							
						</div>
					</li>
				);
			}
		});
		return listComments;
	};

	getImage = (imagenes) =>{
		const imagenesXseparado = imagenes.split(',');
		return imagenesXseparado[0];
	}

	handlerComment = e => {
		this.setState({ ...this.state, comment: e.target.value });
	};

	enviarComentarioEmpresa = () => {
		const { comment } = this.state;
		const { empresa, ids, sendComment } = this.props;
		if (comment) {
			const data = {
				usuarioID: empresa._id,
				email:empresa.verificado ? empresa.email : 'contacto@vivipiria.uy' ,
				refID: ids,
				imageUser: !empresa.logo ? this.getImage(empresa.image) : empresa.logo,
				nameUser: empresa.nombre,
				titulo: '',
				comentario: comment,
			};
			this.setState({ ...this.state, comment: '', rating: 0 });
			sendComment(data);
		} else {
			if (!comment || _.isEmpty(comment)) {
				this.notify('br', 'Agrega un comentario.');
			}
		}
	};

	enviarComentario = () => {
		const { rating, comment } = this.state;
		const { user, ids, sendComment, selected } = this.props;
		if (comment && rating) {
			const data = {
				usuarioID: user._id,
				email:selected.verificado ? selected.email : 'contacto@vivipiria.uy' ,
				refID: ids,
				imageUser: user.imagen,
				nameUser: user.nombre,
				titulo: '',
				comentario: comment,
				calificacion: rating,
			};
			this.setState({ ...this.state, comment: '', rating: 0 });
			sendComment(data);
		} else {
			if (!rating) {
				this.notify('br', 'Elige con cuanto lo calificarias de 1 a 5 estrellas.');
			}
			if (!comment || _.isEmpty(comment)) {
				this.notify('br', 'Agrega un comentario y cuentanos que tal te fue.');
			}
		}
	};

	showAlertMessage = () => {
		const { msg } = this.state;
		if (msg === 'Comentario agregado') {
			this.notify2(
				'br',
				'Tu comentario y calificacion fue enviado correctamente. Un moderador de nuestro equipo lo publicara, gracias!!'
			);
		} else return null;
	};

	render() {
		const { user } = this.props;
		const { empresa } = this.props;
		const token = localStorage.getItem('token');
		const tokenEmpresa = localStorage.getItem('tokenEmpresa');

		return (
			<>
				<div className="rna-container">
					<NotificationAlert ref="notificationAlert" />
				</div>
				<Container fluid>
					{this.showAlertMessage()}
					<Row className="ml-auto mr-auto text-center" md="12">
						<Col className="ml-auto mr-auto text-center" md="12" style={{ marginTop: '15px' }}>
							{!_.isEmpty(user) && token && (
								<div style={{ float: 'left', width: '100%' }}>
									<div
										className="photo"
										style={{ width: '50px', height: '50px', marginBottom: '20px', float: 'left' }}
									>
										<img
											style={{ borderRadius: '50%' }}
											alt="..."
											src={
												!user.imagen
													? 'https://d39etc8ensw3fs.cloudfront.net/fit-in/200x200/defaultPerfil.png'
													: 'https://d39etc8ensw3fs.cloudfront.net/fit-in/200x200/' +
													  user.imagen
											}
										/>
									</div>

									<div style={{ float: 'left', textAlign: 'left', marginLeft: '10px' }}>
										<h6>{user.nombre}</h6>
									</div>
									<div style={{ float: 'right', padding: '5px' }}>
										<h6>Con cuando lo calificarias?</h6>
										<StarRatings
											rating={this.state.rating}
											starRatedColor="yellow"
											starEmptyColor="rgb(203, 211, 227)"
											starHoverColor="rgb(230, 67, 47)"
											starDimension="20px"
											starSpacing="5px"
											changeRating={this.changeRating}
											numberOfStars={5}
											name="rating"
										/>
									</div>
									<Input
										cols="100"
										defaultValue=""
										value={this.state.comment}
										placeholder="Comentanos tu experiencia!"
										rows="4"
										type="textarea"
										onChange={e => {
											this.handlerComment(e);
										}}
										style={{ borderColor: '#00f2c3', padding: '10px' }}
									/>

									<Button
										className="btn-simple"
										color="success"
										style={{ borderRadius: '5px', marginTop: '10px', float: 'right' }}
										onClick={e => {
											this.enviarComentario();
										}}
									>
										<i
											className="tim-icons icon-check-2"
											style={{
												fontSize: '20px',
												float: 'left',
												marginRight: '5px',
												marginTop: '-3px',
											}}
										/>{' '}
										Enviar comentario
									</Button>
								</div>
							)}
							{!_.isEmpty(empresa) && tokenEmpresa && (
								<div style={{ float: 'left', width: '100%' }}>
									<div
										className="photo"
										style={{ width: '50px', height: '50px', marginBottom: '20px', float: 'left' }}
									>
										<img
											style={{ borderRadius: '50%' }}
											alt="..."
											src={
												!empresa.logo
													? 'https://d39etc8ensw3fs.cloudfront.net/fit-in/200x200/'+this.getImage(empresa.image)
													: 'https://d39etc8ensw3fs.cloudfront.net/fit-in/200x200/' +
													empresa.logo
											}
										/>
									</div>

									<div style={{ float: 'left', textAlign: 'left', marginLeft: '10px' }}>
										<h6>{empresa.nombre}</h6>
									</div>
									<div style={{ float: 'right', padding: '5px' }}>
									</div>
									<Input
										cols="100"
										defaultValue=""
										value={this.state.comment}
										placeholder="Comentario"
										rows="4"
										type="textarea"
										onChange={e => {
											this.handlerComment(e);
										}}
										style={{ borderColor: '#00f2c3', padding: '10px' }}
									/>

									<Button
										className="btn-simple"
										color="success"
										style={{ borderRadius: '5px', marginTop: '10px', float: 'right' }}
										onClick={e => {
											this.enviarComentarioEmpresa();
										}}
									>
										<i
											className="tim-icons icon-check-2"
											style={{
												fontSize: '20px',
												float: 'left',
												marginRight: '5px',
												marginTop: '-3px',
											}}
										/>{' '}
										Enviar comentario
									</Button>
								</div>
							)}
						</Col>
					</Row>
				</Container>
				<Container fluid>
					<Row className="ml-auto mr-auto text-left" md="12">
						<Col className="ml-auto mr-auto text-left" md="12" style={{ marginTop: '15px' }}>
						<h4><i className={'tim-icons icon-chat-33 text-primary'} style={{ fontSize: '20px' }} /> Comentarios de otros usuarios </h4>
						</Col>
					</Row>
					<Row className="ml-auto mr-auto text-left" md="12">
						<Col className="ml-auto mr-auto text-left" md="12" style={{ marginTop: '15px' }}>
							<Card className="card-timeline card-plain">
								<CardBody>
									<ul className="timeline timeline-simple">{this.showListCommnets()}</ul>
								</CardBody>
							</Card>
						</Col>
					</Row>
				</Container>
			</>
		);
	}
}

const mapStateToProps = state => {
	return {
		...state.reducers.homeReducer,
	};
};

const mapDispatchToProps = dispatch => {
	return {
		goTo: path => {
			dispatch(push(path));
		},
		getAllsCommnets: id => {
			dispatch(getComments(id));
		},
		sendComment: data => {
			dispatch(sendComment(data));
		},
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(Comments);
