import axios from 'axios';
import client from "../apiCliente.js";
//import _ from 'lodash';

//const URL_BASE = "http://localhost:3000/";
const URL_BASE = 'https://vivipiria.herokuapp.com/';

export const getNoticias = () => {
	return axios.get(`${URL_BASE}noticias-paseos-eventos`);
};

export const getNoticia = id => {
	return axios.get(`${URL_BASE}noticia/${id}`);
};

export const getEmpresa = id => {
	return axios.get(`${URL_BASE}empresa/${id}`);
};


export const updateEmpresa = (data, id) =>{

	return axios.post('https://vivipiria.herokuapp.com/editar-empresa/'+id, data, {
					headers: {
						accept: 'application/json',
						'Accept-Language': 'en-US,en;q=0.8',
						'Content-Type': `multipart/form-data; boundary=${data._boundary}`,
					},
				});
}

export const updateUser = (data, id) =>{
		return axios.post('https://vivipiria.herokuapp.com/editar-usuario/'+id, data, {
						headers: {
							accept: 'application/json',
							'Accept-Language': 'en-US,en;q=0.8',
							'Content-Type': `multipart/form-data; boundary=${data._boundary}`,
						},
					});
	}

export const getCommentse = id => {
	return client().get(`${URL_BASE}comentarios/${id}`);
};

export const sendComment  = data => {
	console.log(data);
	return client().post(`${URL_BASE}addComentarios/`, data.data);
};


export const getMensajes = id => {
	return client().get(`${URL_BASE}mensajes/${id}`);
};

export const getMensajesEnviados = id => {
	return client().get(`${URL_BASE}mensajes-enviados/${id}`);
};

//emails
export const sendMailRegistroUsuario  = data => {
	return client().post(`${URL_BASE}send-email-new-user`, data);
};

export const sendMailNewUserToAdmin  = data => {
	return client().post(`${URL_BASE}send-email-new-user-admin`, data);
};


export const sendMailRegistroEmpresa  = data => {
	return client().post(`${URL_BASE}send-email-new-empresa`, data);
};

export const sendMailNewEmpresaToAdmin  = data => {
	return client().post(`${URL_BASE}send-email-new-empresa-admin`, data);
};


export const sendMailNewComment  = data => {
	return client().post(`${URL_BASE}send-email-new-comment`, data);
};

export const sendMailNewCommentToAdmin  = data => {
	return client().post(`${URL_BASE}send-email-new-comment-admin`, data);
};
//listo

export const sendMailContactoToAdmin = data => {
	return client().post(`${URL_BASE}send-email-contacto-admin`, data);
};

export const sendMailVerificarEmpresaToAdmin = data => {
	return client().post(`${URL_BASE}send-email-verification-empresa`, data);
};

export const sendMailNewReserve = data => {
	return client().post(`${URL_BASE}send-email-new-reserve`, data);
};

export const sendMailNotificatioNewMessage = data => {
	return client().post(`${URL_BASE}send-email-new-message`, data);
};

export const resetPasswordUser = data => {
	return client().post(`${URL_BASE}send-email-reset-password-user`, data);
};

export const resetPasswordEmpresa = data => {
	return client().post(`${URL_BASE}send-email-reset-password-empresa`, data);
};


//-----

export const sendMensaje  = data => {
	console.log(data);
	return client().post(`${URL_BASE}sendmensaje/`, data.data);
};

export const changeStatusMensaje  = id => {
	return client().get(`${URL_BASE}mensaje/${id}`);
};

export const changeStatusEmpresa  = id => {
	return client().get(`${URL_BASE}empresastatus/${id}`);
};

export const changeVerificateEmpresa  = id => {
	return client().get(`${URL_BASE}empresas-verificado/${id}`);
};

export const changePremiumEmpresa  = id => {
	return client().get(`${URL_BASE}empresas-premium/${id}`);
};

export const borrarEmpresa = id => {
	return client().get(`${URL_BASE}deleteEmpresa/${id}`);
};

export const changeStatusNoticia = id => {
	return client().get(`${URL_BASE}noticiasstatus/${id}`);
};

export const borrarNoticia = id => {
	return client().get(`${URL_BASE}deleteNoticia/${id}`);
};

export const userData = () => {
	return client().get(`${URL_BASE}userData`);
};

export const empresaData = () => {
	return client().get(`${URL_BASE}empresaData`);
};


export const registrarEmpresa = data => {
	return client().post(`${URL_BASE}registrarEmpresa`, data.payload);
};

export const registrarUsuario = data => {
	return client().post(`${URL_BASE}registrarUsuario`, data.payload);
};

export const loginEmpresa = data => {
	return client().post(`${URL_BASE}loginEmpresa`, data.payload);
};

export const getCotizaciones = () => {
	return axios.get('https://cotizaciones-brou.herokuapp.com/api/currency/latest');
};

export const getCupones = () => {
	return axios.get(`${URL_BASE}lista-cupones`);
};

export const login = data => {
	return client().post(`${URL_BASE}login`, data.payload);
};

export const getEmpresas = () => {
	return axios.get(`${URL_BASE}lista-empresas`);
};
