import React from 'react';
import { connect } from 'react-redux';
import { registrarEmpresa } from '../../actions/';
import Footer from '../../components/Footer/Footer';
import AuthNavbar from '../../components/Navbars/AuthNavbar';
import Select from 'react-select';
import classnames from 'classnames';
import NotificationAlert from 'react-notification-alert';

// reactstrap components
import {
	Button,
	Card,
	CardBody,
	CardFooter,
	Label,
	FormGroup,
	Form,
	Input,
	InputGroupAddon,
	InputGroupText,
	InputGroup,
	Container,
	Row,
	Col,
} from 'reactstrap';

class Register extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			msg: '',
			nameCompany: '',
			email: '',
			password: '',
			passwordConfirm: '',
			phone: '',
			terminosConfirm: false,
			categoria: '',
		};
	}

	componentDidMount() {
		document.body.classList.toggle('register-page');
	}
	componentWillUnmount() {
		document.body.classList.toggle('register-page');
	}

	static getDerivedStateFromProps(nextProps, prevState) {
		if (nextProps.msg !== prevState.msg) {
			return { msg: nextProps.msg };
		} else return null;
	}

	componentDidUpdate(prevProps, prevState) {
		if (prevProps.msg !== this.props.msg) {
			this.setState({
				emailState: 'has-danger',
				msg: 'Email ya existe!',
			});
			this.notify('br', this.state.msg);
		}
	}

	notify = (place, message) => {
		//var color = Math.floor(Math.random() * 5 + 1);

		let color = 1;
		let type;
		switch (color) {
			case 1:
				type = 'primary';
				break;
			case 2:
				type = 'success';
				break;
			case 3:
				type = 'danger';
				break;
			case 4:
				type = 'warning';
				break;
			case 5:
				type = 'info';
				break;
			default:
				break;
		}
		let options = {};
		options = {
			place: place,
			message: (
				<div>
					<div>
						<b>Tenemos algunos Errores </b>
						<h6>{message}</h6>
					</div>
				</div>
			),
			type: type,
			icon: 'tim-icons icon-alert-circle-exc',
			autoDismiss: 10,
		};
		this.refs.notificationAlert.notificationAlert(options);
	};

	handleInput = event => {
		if (event.target.name === 'categoria') {
			this.setState({ ...this.state, categoria: event.target.value });
		}
	};

	verifyEmail = value => {
		var emailRex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
		if (emailRex.test(value)) {
			return true;
		}
		return false;
	};

	// function that verifies if a string has a given length or not
	verifyLength = (value, length) => {
		if (value.length >= length) {
			return true;
		}
		return false;
	};

	verifyNumber = value => {
		var numberRex = new RegExp('^[0-9]+$');
		if (numberRex.test(value)) {
			return true;
		}
		return false;
	};

	verifyPasswords = value => {
		const { password } = this.state;
		if (password === value) {
			return true;
		}
		return false;
	};

	verifyPasswordsData = value => {
		const content = value.length;

		if (content >= 8) {
			return true;
		}
		return false;
	};

	isValidated = () => {
		if (
			this.state.nameCompanyState === 'has-success' &&
			this.state.emailState === 'has-success' &&
			this.state.phoneState === 'has-success' &&
			this.state.passwordState === 'has-success' &&
			this.state.passwordConfirmState === 'has-success' &&
			this.state.terminosConfirm &&
			this.state.categoria !== ''
		) {
			return true;
		} else {
			if (this.state.nameCompanyState !== 'has-success') {
				this.notify('br', 'Completa el nombre');
				this.setState({ nameCompanyState: 'has-danger' });
			}
			if (this.state.emailState !== 'has-success') {
				this.notify('br', 'Email incorrecto');
				this.setState({ emailState: 'has-danger' });
			}
			if (this.state.phoneState !== 'has-success') {
				this.notify('br', 'Danos un telefono o celular');
				this.setState({ phoneState: 'has-danger' });
			}
			if (this.state.passwordState !== 'has-success') {
				this.notify('br', 'Passwords incorrecto tiene que tener 8 caracteres minimo');
				this.setState({ passwordState: 'has-danger', passwordConfirmState: 'has-danger' });
			}
			if (this.state.categoria === '') {
				this.notify('br', 'Tienes que escoger una categoria para tu empresa');
			}
			if (!this.state.terminosConfirm) {
				this.notify('br', 'Tienes que estar de acuerdo con los Terminos y condiciones ');
			}
			return false;
		}
	};

	change = (event, stateName, type, stateNameEqualTo, maxValue) => {
		switch (type) {
			case 'email':
				if (this.verifyEmail(event.target.value)) {
					this.setState({ [stateName + 'State']: 'has-success' });
				} else {
					this.setState({ [stateName + 'State']: 'has-danger' });
				}
				break;
			case 'length':
				if (this.verifyLength(event.target.value, stateNameEqualTo)) {
					this.setState({ [stateName + 'State']: 'has-success' });
				} else {
					this.setState({ [stateName + 'State']: 'has-danger' });
				}
				break;
			case 'number':
				if (this.verifyNumber(event.target.value, stateNameEqualTo)) {
					this.setState({ [stateName + 'State']: 'has-success' });
				} else {
					this.setState({ [stateName + 'State']: 'has-danger' });
				}
				break;
			case 'equal':
				if (this.verifyPasswords(event.target.value, stateNameEqualTo)) {
					this.setState({ [stateName + 'State']: 'has-success' });
				} else {
					this.setState({ [stateName + 'State']: 'has-danger' });
				}
				break;
			case 'passwordV':
				if (this.verifyPasswordsData(event.target.value, stateNameEqualTo)) {
					this.setState({ [stateName + 'State']: 'has-success' });
				} else {
					this.setState({ [stateName + 'State']: 'has-danger', msg: 'Los passwords no son iguales' });
				}
				break;
			default:
				break;
		}
		this.setState({ [stateName]: event.target.value });
	};

	handlecategoria = category => {
		this.setState({ ...this.state, categoria: category });
	};

	handlerSend = () => {
		if (this.isValidated()) {
			const data = {
				categoria: this.state.categoria.value,
				nombre: this.state.nameCompany,
				email: this.state.email,
				password: this.state.password,
				telefono: this.state.phone,
			};
			this.props.registrarEmpresa(data);
		}
	};

	render() {
		return (
			<>
				<AuthNavbar />
				<div className="rna-container">
					<NotificationAlert ref="notificationAlert" />
				</div>
				<div className="content" style={{ marginTop: '120px' }}>
					<Container>
						<Row>
							<Col className="mr-auto" md="7">
								<Card className="card-register card-black" style={{ margin: '0px' }}>
									<div className="ml-auto mr-auto" style={{ paddingTop: '30px' }}>
										<img
											alt="..."
											src={'http://appsuy.com/images_vivi_piria/icono_vivipiria_130.png'}
											width="80px"
										/>
									</div>
									<div className="ml-auto mr-auto">
										<h3 className="info-title">Registra tu empresa o negocio</h3>
									</div>
									<CardBody>
										<Form className="form">
											<InputGroup
												className={classnames(this.state.nameCompanyState, {
													'input-group-focus': this.state.nameCompanyFocus,
												})}
											>
												<InputGroupAddon addonType="prepend">
													<InputGroupText>
														<i className="tim-icons icon-single-02" />
													</InputGroupText>
												</InputGroupAddon>
												<Input
													name="nameCompany"
													placeholder="Nombre Empresa o comercio..."
													type="text"
													onChange={e => this.change(e, 'nameCompany', 'length', 1)}
													onFocus={e => this.setState({ nameCompanyFocus: true })}
													onBlur={e => this.setState({ nameCompanyFocus: false })}
												/>
											</InputGroup>
											<InputGroup>
												<InputGroupAddon addonType="prepend"></InputGroupAddon>
												<Col
													className="ml-auto mr-auto text-left"
													md="12"
													sm="12"
													style={{ padding: '0px' }}
												>
													<Select
														className="react-select success"
														classNamePrefix="react-select"
														name="categoria"
														value={this.state.categoria}
														onChange={value => this.handlecategoria(value)}
														options={[
															{ value: 'Hotel, hoteles resort', label: 'Hoteles' },
															{ value: 'Hotel, hostel', label: 'Hostels' },
															{ value: 'Hotel, hostal', label: 'Hostales' },
															{ value: 'Hotel, camping', label: 'Campings' },
															{ value: 'Hotel, apartament', label: 'Apartamentos' },
															{ value: 'Hotel, comple', label: 'Complejo Cabañas' },
															{ value: 'Hotel, apart', label: 'Apart Hotel' },
															{ value: 'Hotel, posada', label: 'Posadas' },
															{ value: 'Hotel, bungalow', label: 'Bungalow' },
															{
																value: 'comercios,inmobiliaria',
																label: 'Inmonbiliarias',
															},
															{
																value: 'restaurante',
																label: 'Restaurante comida Internacional',
															},
															{ value: 'restaurante,maris', label: 'Mariscos' },
															{
																value: 'restaurante,vegetariano',
																label: 'Comida Vegetariana',
															},
															{
																value: 'restaurante,comida rapida',
																label: 'Comida rápida',
															},
															{ value: 'restaurante,chiveterias', label: 'Chiveterias' },
															{ value: 'restaurante,pizza', label: 'Pizzerias' },
															{ value: 'restaurante,parrilla', label: 'Parrillas' },
															{ value: 'restaurante,buffet', label: 'Buffet' },
															{
																value: 'restaurante,comida casera',
																label: 'Comida casera',
															},
															{ value: 'restaurante,pub', label: "Pub's" },
															{ value: 'restaurante,rotise', label: 'Rotiserías' },
															{ value: 'restaurante,para llevar', label: 'Para llevar' },
															{ value: 'restaurante,cafe', label: 'Caféteria' },
															{ value: 'restaurante,tragos', label: 'Tragos' },
															{ value: 'restaurante,cervecerias', label: 'Cervecerias' },
															{ value: 'comercios,farmacia', label: 'Farmacias' },
															{ value: 'comercios,supermercado', label: 'Supermercados' },
															{ value: 'comercios,autoservice', label: 'Autoservicios' },
															{
																value: 'comercios,estacion',
																label: 'Estaciones de servicio',
															},
															{ value: 'comercios,heladeria', label: 'Heladerias' },
															{ value: 'comercios,panaderia', label: 'Panaderias' },
															{ value: 'comercios,ropa', label: 'Tiendas de ropa' },
															{
																value: 'comercios,celula',
																label: 'Celulares y accesorios',
															},
															{ value: 'comercios,electro', label: 'Electrodomesticos' },
															{ value: 'comercios,mueble', label: 'Muebles' },
															{ value: 'comercios,tela', label: 'Telas' },
															{
																value: 'comercios,barraca',
																label: 'Barracas y Ferreterias',
															},
															{
																value: 'comercios,otros',
																label: 'Otros',
															},
														]}
														placeholder="Categoria"
													/>
												</Col>
											</InputGroup>
											<InputGroup
												className={classnames(this.state.emailState, {
													'input-group-focus': this.state.emailFocus,
												})}
											>
												<InputGroupAddon addonType="prepend">
													<InputGroupText>
														<i className="tim-icons icon-email-85" />
													</InputGroupText>
												</InputGroupAddon>
												<Input
													name="email"
													placeholder="Email..."
													type="email"
													onChange={e => this.change(e, 'email', 'email')}
													onFocus={e => this.setState({ emailFocus: true })}
													onBlur={e => this.setState({ emailFocus: false })}
												/>
											</InputGroup>
											<InputGroup
												className={classnames(this.state.phoneState, {
													'input-group-focus': this.state.phoneFocus,
												})}
											>
												<InputGroupAddon addonType="prepend">
													<InputGroupText>
														<i className="tim-icons icon-mobile" />
													</InputGroupText>
												</InputGroupAddon>
												<Input
													name="phone"
													placeholder="Telefono / celular..."
													type="number"
													onChange={e => this.change(e, 'phone', 'number')}
													onFocus={e => this.setState({ phoneFocus: true })}
													onBlur={e => this.setState({ phoneFocus: false })}
												/>
											</InputGroup>

											<InputGroup
												className={classnames(this.state.passwordState, {
													'input-group-focus': this.state.passwordFocus,
												})}
											>
												<InputGroupAddon addonType="prepend">
													<InputGroupText>
														<i className="tim-icons icon-lock-circle" />
													</InputGroupText>
												</InputGroupAddon>
												<Input
													name="password"
													placeholder="Contraseña..."
													type="password"
													onChange={e => this.change(e, 'password', 'passwordV')}
													onFocus={e => this.setState({ passwordFocus: true })}
													onBlur={e => this.setState({ passwordFocus: false })}
												/>
											</InputGroup>
											<InputGroup
												className={classnames(this.state.passwordConfirmState, {
													'input-group-focus': this.state.passwordConfirmFocus,
												})}
											>
												<InputGroupAddon addonType="prepend">
													<InputGroupText>
														<i className="tim-icons icon-lock-circle " />
													</InputGroupText>
												</InputGroupAddon>
												<Input
													name="passwordConfirm"
													placeholder="Repita Contraseña..."
													type="password"
													onChange={e => this.change(e, 'passwordConfirm', 'equal')}
													onFocus={e => this.setState({ passwordConfirmFocus: true })}
													onBlur={e => this.setState({ passwordConfirmFocus: false })}
												/>
											</InputGroup>
											<FormGroup check className="text-left">
												<Label check>
													<Input
														type="checkbox"
														onChange={e => {
															this.setState({
																...this.state,
																terminosConfirm: !this.state.terminosConfirm,
															});
														}}
													/>
													<span className="form-check-sign" />
													Estoy de acuerdo{' '}
													<a href="/terminos-y-condiciones" target="_blank">
														Términos y condiciónes
													</a>
													.
												</Label>
											</FormGroup>
										</Form>
									</CardBody>
									<CardFooter>
										<Button
											block
											className="btn-simple mb-3"
											color="success"
											style={{ borderRadius: '5px' }}
											onClick={e => {
												this.handlerSend();
											}}
											size="lg"
										>
											Registrar mi Empresa
										</Button>
									</CardFooter>
								</Card>
							</Col>
							<Col className="ml-auto" md="5">
								<div className="info-area info-horizontal mt-5">
									<div className="icon icon-warning" style={{ marginTop: '5px' }}>
										<i className="icon-warning tim-icons icon-shape-star" />
									</div>
									<div className="description">
										<h3 className="info-title"> Regístrate ¡es gratis!</h3>
										<p className="description">
											Si tienes un negocio en Piriápolis, tienes que formar parte de nuestra
											plataforma web para llegar más y mejor a tus clientes.
										</p>
									</div>
								</div>
								<div className="info-area info-horizontal" style={{ marginTop: '30px' }}>
									<div className="icon icon-primary" style={{ marginTop: '5px' }}>
										<i className="tim-icons icon-tag" />
									</div>
									<div className="description">
										<h3 className="info-title">Crea Cupones y Beneficios</h3>
										<p className="description">
											Podrás crear Cupones y beneficios para atraer clientes a tu negocio.
										</p>
									</div>
								</div>
								<div className="info-area info-horizontal" style={{ marginTop: '30px' }}>
									<div className="icon icon-info" style={{ marginTop: '0px' }}>
										<i className="tim-icons icon-bulb-63" />
									</div>
									<div className="description">
										<h3 className="info-title">Información en tiempo real</h3>
										<p className="description">
											Tendrás acceso a la información de calidad de cómo se están comportando los
											posibles clientes en Piriápolis, y poder ajustar tus estrategia comerciales.
										</p>
									</div>
								</div>
							</Col>
						</Row>
					</Container>
				</div>
				<Footer />
			</>
		);
	}
}

const mapStateToProps = state => {
	return {
		...state.reducers.homeReducer,
	};
};

const mapDispatchToProps = dispatch => {
	return {
		registrarEmpresa: data => dispatch(registrarEmpresa(data)),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(Register);
