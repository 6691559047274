import { actions } from '../Constants';

const initialState = {
	loading: false,
	msg: '',
	token: '',
	user: {},
	empresa: {},
	empresas: {},
	noticias: {},
	cupones: {},
	mensajes: {},
	mensajesEnviados: {},
	cotizaciones: {},
	image: '',
	comentariosAll: null,
};

const reducer = (state = initialState, action) => {
	switch (action.type) {
		case actions.GET_COTIZACIONES:
			return {
				...state,
				loading: true,
			};
		case actions.GET_COTIZACIONES_SUCCESS:
			return {
				...state,
				loading: false,
				cotizaciones: action.payload.data,
			};
		case actions.GET_COTIZACIONES_ERROR:
			return {
				...state,
				loading: false,
				msg: action.payload,
			};
		case actions.GET_EMPRESAS:
			return {
				...state,
				loading: true,
				msg: '',
			};
		case actions.GET_EMPRESAS_SUCCESS:
			return {
				...state,
				loading: false,
				empresas: action.payload.data,
			};
		case actions.GET_EMPRESAS_ERROR:
			return {
				...state,
				loading: false,
				msg: action.payload,
			};
		case actions.GET_NOTICIAS:
			return {
				...state,
				loading: true,
				msg: '',
			};
		case actions.GET_NOTICIAS_SUCCESS:
			return {
				...state,
				loading: false,
				noticias: action.payload.data,
			};
		case actions.GET_NOTICIAS_ERROR:
			return {
				...state,
				loading: false,
				msg: action.payload,
			};
		case actions.GET_CUPONES:
			return {
				...state,
				loading: true,
				msg: '',
			};
		case actions.GET_CUPONES_SUCCESS:
			return {
				...state,
				loading: false,
				cupones: action.payload.data,
			};
		case actions.GET_CUPONES_ERROR:
			return {
				...state,
				loading: false,
				msg: action.payload,
			};
		case actions.REGISTRAR_EMPRESA:
			return {
				...state,
				loading: true,
				data: action.payload,
			};
		case actions.REGISTRAR_EMPRESA_SUCCESS:
			return {
				...state,
				loading: false,
				token: action.payload.data.token,
			};
		case actions.REGISTRAR_EMPRESA_ERROR:
			return {
				...state,
				loading: false,
				msg: action.payload,
			};
		case actions.REGISTRAR_USUARIO:
			return {
				...state,
				loading: true,
				data: action.payload,
			};
		case actions.REGISTRAR_USUARIO_SUCCESS:
			return {
				...state,
				loading: false,
				token: action.payload.data.token,
			};
		case actions.REGISTRAR_USUARIO_ERROR:
			return {
				...state,
				loading: false,
				msg: action.payload,
			};
		case actions.LOGIN:
			return {
				...state,
				loading: true,
				data: action.payload,
			};
		case actions.LOGIN_SUCCESS:
			return {
				...state,
				loading: false,
				token: action.payload.data.token,
			};
		case actions.LOGIN_ERROR:
			return {
				...state,
				loading: false,
				msg: action.payload,
			};
		case actions.USER_DATA:
			return {
				...state,
				loading: true,
			};
		case actions.USER_DATA_SUCCESS:
			return {
				...state,
				loading: false,
				user: action.payload.data.user,
			};
		case actions.USER_DATA_ERROR:
			return {
				...state,
				loading: false,
				msg: action.payload,
			};
		case actions.UPDATE_EMPRESAS:
			return {
				...state,
				loading: true,
				data: action.data,
				id: action.id,
				msg: '',
			};
		case actions.UPDATE_EMPRESAS_SUCCESS:
			return {
				...state,
				loading: false,
				msg: action.data.message,
				token: action.data.token,
			};
		case actions.UPDATE_EMPRESAS_ERROR:
			return {
				...state,
				loading: false,
				msg: action.data,
			};
		case actions.UPDATE_USER:
			return {
				...state,
				loading: true,
				data: action.data,
				id: action.id,
			};
		case actions.UPDATE_USER_SUCCESS:
			return {
				...state,
				loading: false,
				token: action.data.token,
			};
		case actions.UPDATE_USER_ERROR:
			return {
				...state,
				loading: false,
				msg: action.payload,
			};

		case actions.EMPRESA_DATA:
			return {
				...state,
				loading: true,
			};
		case actions.EMPRESA_DATA_SUCCESS:
			return {
				...state,
				loading: false,
				empresa: action.payload.data.empresa,
			};
		case actions.EMPRESA_DATA_ERROR:
			return {
				...state,
				loading: false,
				msg: action.payload,
			};
		case actions.LOGIN_EMPRESA:
			return {
				...state,
				loading: true,
				data: action.payload,
			};
		case actions.LOGIN_EMPRESA_SUCCESS:
			return {
				...state,
				loading: false,
				token: action.payload.data.token,
			};
		case actions.LOGIN_EMPRESA_ERROR:
			return {
				...state,
				loading: false,
				msg: action.payload,
			};
		case actions.SEND_MENSAJE:
			return {
				...state,
				loading: false,
				data: action.data,
				msg: '',
			};
		case actions.SEND_MENSAJE_SUCCESS:
			return {
				...state,
				loading: false,
				msg: action.payload.data.msg,
			};
		case actions.SEND_MENSAJE_ERROR:
			return {
				...state,
				loading: false,
				msg: action.payload.data.msg,
			};
		case actions.GET_MENSAJES:
			return {
				...state,
				loading: true,
				msg: '',
			};
		case actions.GET_MENSAJES_SUCCESS:
			return {
				...state,
				loading: false,
				mensajes: action.payload.mensajes,
			};
		case actions.GET_MENSAJES_ERROR:
			return {
				...state,
				loading: false,
				msg: action.x,
			};
		case actions.GET_MENSAJES_ENVIADOS:
			return {
				...state,
				loading: true,
				msg: '',
			};
		case actions.GET_MENSAJES_ENVIADOS_SUCCESS:
			return {
				...state,
				loading: false,
				mensajesEnviados: action.payload.mensajesEnviados,
			};
		case actions.GET_MENSAJES_ENVIADOS_ERROR:
			return {
				...state,
				loading: false,
				msg: action.x,
			};
		case actions.CHANGE_STATUS_MENSAJE:
			return {
				...state,
				loading: true,
				msg: '',
			};
		case actions.CHANGE_STATUS_MENSAJE_SUCCESS:
			return {
				...state,
				loading: false,
				msg: action.payload.msg,
			};
		case actions.CHANGE_STATUS_MENSAJE_ERROR:
			return {
				...state,
				loading: false,
				msg: action.x,
			};

		case actions.SEND_COMMENTS:
			return {
				...state,
				loading: false,
				data: action.data,
				msg: '',
			};
		case actions.SEND_COMMENTS_SUCCESS:
			return {
				...state,
				loading: false,
				msg: action.payload.data.msg,
			};
		case actions.SEND_COMMENTS_ERROR:
			return {
				...state,
				loading: false,
				msg: action.payload.data.msg,
			};

		case actions.GET_COMMENTS:
			return {
				...state,
				loading: true,
				msg: '',
			};
		case actions.GET_COMMENTS_SUCCESS:
			return {
				...state,
				loading: false,
				comentariosAll: action.payload.comentarios,
			};
		case actions.GET_COMMENTS_ERROR:
			return {
				...state,
				loading: false,
				msg: action.x,
			};
		case actions.BORRAR_EMPRESA:
			return {
				...state,
				loading: true,
				id: action.x,
			};
		case actions.BORRAR_EMPRESA_SUCCESS:
			return {
				...state,
				loading: false,
				msg: action.message,
			};
		case actions.BORRAR_EMPRESA_ERROR:
			return {
				...state,
				loading: false,
				msg: action.x,
			};
		case actions.CHANGE_EMPRESA_STATUS:
			return {
				...state,
				loading: true,
				id: action.x,
			};
		case actions.CHANGE_EMPRESA_STATUS_SUCCESS:
			return {
				...state,
				loading: false,
				msg: action.message,
			};
		case actions.CHANGE_EMPRESA_STATUS_ERROR:
			return {
				...state,
				loading: false,
				msg: action.x,
			};
		case actions.CHANGE_EMPRESA_PREMIUM:
			return {
				...state,
				loading: true,
				id: action.x,
			};
		case actions.CHANGE_EMPRESA_PREMIUM_SUCCESS:
			return {
				...state,
				loading: false,
				id: null,
				msg: action.message,
			};
		case actions.CHANGE_EMPRESA_PREMIUM_ERROR:
			return {
				...state,
				loading: false,
				id: null,
				msg: action.x,
			};
		case actions.CHANGE_EMPRESA_VERIFICATE:
			return {
				...state,
				loading: true,
				id: action.x,
			};
		case actions.CHANGE_EMPRESA_VERIFICATE_SUCCESS:
			return {
				...state,
				loading: false,
				id: null,
				msg: action.message,
			};
		case actions.CHANGE_EMPRESA_VERIFICATE_ERROR:
			return {
				...state,
				loading: false,
				id: null,
				msg: action.x,
			};
		case actions.VERIFICAR_EMPRESA:
			return {
				...state,
				data: action.data,
			};
		case actions.VERIFICAR_EMPRESA_SUCCESS:
			return {
				...state,
				msg: action.data.data.data,
			};
		case actions.VERIFICAR_EMPRESA_ERROR:
			return {
				...state,
				msg: action.data.data.data,
			};
		case actions.ALOJAMIENTO_BY_ID:
			return {
				...state,
				loading: true,
				selected: {},
				empresa: {},
				msg: '',
			};
		case actions.ALOJAMIENTO_BY_ID_SUCCESS:
			return {
				...state,
				loading: false,
				selected: action.selected,
			};
		case actions.ALOJAMIENTO_BY_ID_ERROR:
			return {
				...state,
				loading: false,
				msg: action.x,
			};
		case actions.GASTRONOMIA_BY_ID:
			return {
				...state,
				loading: true,
				msg: '',
			};
		case actions.GASTRONOMIA_BY_ID_SUCCESS:
			return {
				...state,
				loading: false,
				selected: action.selected,
			};
		case actions.GASTRONOMIA_BY_ID_ERROR:
			return {
				...state,
				loading: false,
				msg: action.x,
			};
		case actions.GUIA_BY_ID:
			return {
				...state,
				loading: true,
				msg: '',
			};
		case actions.GUIA_BY_ID_SUCCESS:
			return {
				...state,
				loading: false,
				selected: action.selected,
			};
		case actions.GUIA_BY_ID_ERROR:
			return {
				...state,
				loading: false,
				msg: action.x,
			};
		case actions.INMOBILIARIA_BY_ID:
			return {
				...state,
				loading: true,
				msg: '',
			};
		case actions.INMOBILIARIA_BY_ID_SUCCESS:
			return {
				...state,
				loading: false,
				selected: action.selected,
			};
		case actions.INMOBILIARIA_BY_ID_ERROR:
			return {
				...state,
				loading: false,
				msg: action.x,
			};
		case actions.PASEO_BY_ID:
			return {
				...state,
				loading: true,
				comentariosAll: null,
				msg: '',
			};
		case actions.BORRAR_NOTICIA:
			return {
				...state,
				loading: true,
				id: action.x,
			};
		case actions.BORRAR_NOTICIA_SUCCESS:
			return {
				...state,
				loading: false,
				msg: action.message,
			};
		case actions.BORRAR_NOTICIA_ERROR:
			return {
				...state,
				loading: false,
				msg: action.x,
			};
		case actions.CHANGE_NOTICIA_STATUS:
			return {
				...state,
				loading: true,
				id: action.x,
			};
		case actions.CHANGE_NOTICIA_STATUS_SUCCESS:
			return {
				...state,
				loading: false,
				msg: action.message,
			};
		case actions.CHANGE_NOTICIA_STATUS_ERROR:
			return {
				...state,
				loading: false,
				msg: action.x,
			};
		case actions.PASEO_BY_ID_SUCCESS:
			return {
				...state,
				loading: false,
				selected: action.selected,
			};
		case actions.PASEO_BY_ID_ERROR:
			return {
				...state,
				loading: false,
				msg: action.x,
			};
		case actions.PLAYAS_BY_ID:
			return {
				...state,
				loading: true,
				comentariosAll: null,
				msg: '',
			};
		case actions.PLAYAS_BY_ID_SUCCESS:
			return {
				...state,
				loading: false,
				selected: action.selected,
			};
		case actions.PLAYAS_BY_ID_ERROR:
			return {
				...state,
				loading: false,
				msg: action.x,
			};
		case actions.EVENTO_BY_ID:
			return {
				...state,
				loading: true,
				msg: '',
			};

		case actions.EVENTO_BY_ID_SUCCESS:
			return {
				...state,
				loading: false,
				selected: action.selected,
			};
		case actions.EVENTO_BY_ID_ERROR:
			return {
				...state,
				loading: false,
				msg: action.x,
			};
		default:
			return state;
	}
};

export default reducer;
