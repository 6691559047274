import React from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
//import Iframe from 'react-iframe';
import Select from 'react-select';
import { push } from 'react-router-redux';
import { Map, GoogleApiWrapper, Marker, InfoWindow } from 'google-maps-react';
import YouTube from 'react-youtube';
import ReactBSAlert from 'react-bootstrap-sweetalert';
import Footer from '../../components/Footer/Footer';
import AuthNavbar from '../../components/Navbars/AuthNavbar';
import SliderImage from '../../views/components/SliderShowImage';
import SliderImagenes from '../../views/components/SliderImagenes.jsx';
import { Input } from 'reactstrap';

import { getEmpresas, getCupones, getNoticias, getCotizaciones } from '../../actions/';

import { Button, Card, CardHeader, CardBody, Container, Row, Col, Table } from 'reactstrap';


const mapStyles = {
	width: '100%',
	height: '1000px',
	position: 'absolute',
	left: '-15px',
};

class HomeView extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			stores: [],
			showingInfoWindow: false,
			activeMarker: {},
			selectedPlace: {},
			buscar: '',
			categoria: { value: '', label: 'Todas' },
			orderBy: 'desc',
			orderByValue: false,
			markers: this.props.empresas,
			currentLocation: { lat: '', lng: '' },
		};
	}

	reemplazarAcentos = cadena => {
		const chars = {
			á: 'a',
			é: 'e',
			í: 'i',
			ó: 'o',
			ú: 'u',
			à: 'a',
			è: 'e',
			ì: 'i',
			ò: 'o',
			ù: 'u',
			ñ: 'n',
			Á: 'A',
			É: 'E',
			Í: 'I',
			Ó: 'O',
			Ú: 'U',
			À: 'A',
			È: 'E',
			Ì: 'I',
			Ò: 'O',
			Ù: 'U',
			Ñ: 'N',
		};
		const expr = /[áàéèíìóòúùñ]/gi;
		const res = cadena.replace(expr, e => {
			return chars[e];
		});
		return res;
	};


	componentDidMount() {
		
		this.props.getEmpresas();

		if (navigator && navigator.geolocation) {
			navigator.geolocation.getCurrentPosition(pos => {
				const coords = pos.coords;
				this.setState({
					currentLocation: {
						lat: coords.latitude,
						lng: coords.longitude,
					},
				});
			});
		}
	}

	onMarkerClick = (props, marker, e) =>
		this.setState({
			selectedPlace: props,
			activeMarker: marker,
			showingInfoWindow: true,
		});

	onClose = props => {
		if (this.state.showingInfoWindow) {
			this.setState({
				showingInfoWindow: false,
				activeMarker: null,
			});
		}
	};

	handlerOnChange(e) {
		this.setState({ ...this.state, buscar: e.target.value, showingInfoWindow: false, activeMarker: null });
	}
	handlerCategoria(value) {
		this.setState({ ...this.state, categoria: value, showingInfoWindow: false, activeMarker: null });
	}

	reemplazarAcentos = cadena => {
		const chars = {
			á: 'a',
			é: 'e',
			í: 'i',
			ó: 'o',
			ú: 'u',
			à: 'a',
			è: 'e',
			ì: 'i',
			ò: 'o',
			ù: 'u',
			ñ: 'n',
			Á: 'A',
			É: 'E',
			Í: 'I',
			Ó: 'O',
			Ú: 'U',
			À: 'A',
			È: 'E',
			Ì: 'I',
			Ò: 'O',
			Ù: 'U',
			Ñ: 'N',
		};
		const expr = /[áàéèíìóòúùñ]/gi;
		const res = cadena.replace(expr, e => {
			return chars[e];
		});
		return res;
	};

	mapa() {
		if (!this.props.google) {
			
			return <div>Cargando...</div>;
		}
		const { empresas } = this.props;
		if (!empresas) return null;
		const filterByStatus = _.filter(empresas, x =>{
			const empresa = x.status ? x : null;
			return empresa;
		}
		);
		const category = this.state.categoria.value === 'todas' ? '' : this.state.categoria.value;
		const filterByCategory = _.filter(filterByStatus, x =>
			this.reemplazarAcentos(x.categoria.toLowerCase()).includes(category)
		);
		const filterByTextNombre = _.filter(filterByCategory, x =>
			this.reemplazarAcentos(x.nombre.toLowerCase()).includes(
				this.reemplazarAcentos(this.state.buscar.toLowerCase())
			)
		);

		return (
			<Map
				google={this.props.google}
				zoom={12}
				style={mapStyles}
				initialCenter={{ lat: -34.866111111111, lng: -55.274722222222 }}
			>
				{this.displayMarkers(filterByTextNombre)}
				<InfoWindow
					marker={this.state.activeMarker}
					visible={this.state.showingInfoWindow}
					onClose={this.onClose}
				>
					{this.state.selectedPlace.data ? (
						<div className="ml-auto mr-auto text-center">
							<h6 className={'title text-muted'}>{this.state.selectedPlace.data.nombre}</h6>
							<SliderImagenes imagenes={this.state.selectedPlace.data.image} map={true} padding={"50px"}/>
							<h6 className="text-warning" style={{ marginTop: '10px' }}>
								<i className={'tim-icons icon-shape-star'} style={{ fontSize: '15px' }} />
								{this.state.selectedPlace.data.calificacion} calificación
							</h6>
							<h6 className="text-primary">
								<i className={'tim-icons icon-heart-2'} style={{ fontSize: '15px' }} />
								{this.state.selectedPlace.data.cantidadvotos}
							</h6>

							<h6 className="text-info">
								<i className={'tim-icons icon-mobile'} style={{ fontSize: '15px' }} />
								{this.state.selectedPlace.data.telefono}
							</h6>

							<p style={{ width: '200px', color: '#000000', marginTop: '10px' }}>
								{this.state.selectedPlace.data.descripcionCorta}
							</p>
							<a
								href={
									this.state.selectedPlace.data
										? 'https://vivipiria.uy/guia-comercial/' +
										  this.state.selectedPlace.data._id
										: ''
								}
								target={'_self'}
							>
								VER MAS INFORMACIÓN
							</a>
						</div>
					) : (
						''
					)}
				</InfoWindow>
			</Map>
		);
	}

	displayMarkers = filterByTextNombre => {

		if(!filterByTextNombre) return null;

		return filterByTextNombre.map((store, index) => {
			return (
				<Marker
					key={index}
					id={index}
					position={{
						lat: store.latitude,
						lng: store.longitude,
					}}
					icon={{
						url: this.getMarker(store.categoria),
						anchor: new this.props.google.maps.Point(25, 25),
						scaledSize: new this.props.google.maps.Size(42, 42),
					}}
					data={store}
					onClick={this.onMarkerClick}
				/>
			);
		});
	};

	getMarker = category => {
		if (category.toLowerCase().includes('hotel')) {
			return 'http://appsuy.com/images_vivi_piria/hoteles_marker.png';
		} else if (category.toLowerCase().includes('restauran')) {
			return 'http://appsuy.com/images_vivi_piria/gastronomia_marker.png';
		} else if (category.toLowerCase().includes('supermerca')) {
			return 'http://appsuy.com/images_vivi_piria/supermercados.png';
		} else if (category.toLowerCase().includes('inmobiliaria')) {
			return 'http://appsuy.com/images_vivi_piria/Inmobiliarias.png';
		} else if (category.toLowerCase().includes('estacion')) {
			return 'http://appsuy.com/images_vivi_piria/estaciones.png';
		} else if (category.toLowerCase().includes('barraca')) {
			return 'http://appsuy.com/images_vivi_piria/barracas.png';
		} else if (category.toLowerCase().includes('farmacia')) {
			return 'http://appsuy.com/images_vivi_piria/farmacias.png';
		} else if (category.toLowerCase().includes('helad')) {
			return 'http://appsuy.com/images_vivi_piria/helados.png';
		} else {
			return 'http://appsuy.com/images_vivi_piria/Compras_marker.png';
		}
	};


	render() {
		return (
			<>
			<AuthNavbar />
				 <SliderImage />
				 <div style={{marginTop:"-5px"}}>
				 
				<Container fluid >
				<Row className="ml-auto mr-auto text-center" md="12">
						<Col className="ml-auto mr-auto text-center" md="3">
						<h4 className="title text-left" style={{marginTop:30}}>
								<img
									alt="Inmobiliarias piriapolis , inmobiliarias de piriapolis "
									src="http://appsuy.com/images_vivi_piria/mapa-icon.png"
									width="50px"
								/>
								Mapa de Piriápolis
							</h4>

							<div style={{ marginLeft: '60px', verticalAlign:"middle"}}>
								<h6 className="title text-left text-warning">
									{this.state.buscar === '' ? '' : 'Resultados busqueda : ' + this.state.buscar}{' '}
								</h6>
							</div>
						</Col>
						<Col className="ml-auto mr-auto text-left" md="3" sm="3" style={{ marginTop: '20px' }}></Col>
						<Col className="ml-auto mr-auto text-left" md="3" sm="3" style={{ marginTop: '20px' }}>
							<h6>Categorias</h6>
							<Select
								className="react-select success"
								classNamePrefix="react-select"
								name="singleSelect"
								value={this.state.categoria}
								onChange={value => this.handlerCategoria(value)}
								options={[
									{ value: '', label: 'Todas' },
									{ value: 'hoteles resort', label: 'Hoteles' },
									{ value: 'hostel', label: 'Hostels' },
									{ value: 'hostal', label: 'Hostales' },
									{ value: 'camping', label: 'Campings' },
									{ value: 'apartament', label: 'Apartamentos' },
									{ value: 'comple', label: 'Complejo Cabañas' },
									{ value: 'apart', label: 'Apart Hotel' },
									{ value: 'posada', label: 'Posadas' },
									{ value: 'bungalow', label: 'Bungalow' },
									{ value: 'inmobiliaria', label: 'Inmonbiliarias' },
									{ value: 'maris', label: 'Mariscos' },
									{ value: 'comida rap', label: 'Comida rápida' },
									{ value: 'chiveter', label: 'Chiveterias' },
									{ value: 'pizz', label: 'Pizzerias' },
									{ value: 'parrill', label: 'Parrillas' },
									{ value: 'buffet', label: 'Buffet' },
									{ value: 'comida casera', label: 'Comida casera' },
									{ value: 'pub', label: "Pub's" },
									{ value: 'rotise', label: 'Rotiserías' },
									{ value: 'para llevar', label: 'Para llevar' },
									{ value: 'cafe', label: 'Caféteria' },
									{ value: 'trag', label: 'Tragos' },
									{ value: 'cerve', label: 'Cervecerias' },
									{ value: 'farmacia', label: 'Farmacias' },
									{ value: 'supermercado', label: 'Supermercados' },
									{ value: 'autoservice', label: 'Autoservicios' },
									{ value: 'estacion', label: 'Estaciones de servicio' },
									{ value: 'heladeria', label: 'Heladerias' },
									{ value: 'panaderia', label: 'Panaderias' },
									{ value: 'ropa', label: 'Tiendas de ropa' },
									{ value: 'celula', label: 'Celulares y accesorios' },
									{ value: 'electro', label: 'Electrodomesticos' },
									{ value: 'mueble', label: 'Muebles' },
									{ value: 'tela', label: 'Telas' },
									{ value: 'barraca', label: 'Barracas y Ferreterias' },
								]}
								placeholder="Categorias"
							/>
						</Col>
						<Col className="ml-auto mr-auto text-left" md="3" sm="3" style={{ marginTop: '15px' }}>
							<h6>Buscar </h6>
							<Input
								className="success"
								placeholder="BUSCAR..."
								onChange={e => {
									this.handlerOnChange(e);
								}}
								style={{ borderColor: '#0AF4E9' }}
							/>
						</Col>
					</Row>
					<Row className="ml-auto mr-auto text-center" md="12">
						<Col className="ml-auto mr-auto text-left" md="6" sm="6"></Col>
						<Col className="ml-auto mr-auto text-right" md="6" sm="6"></Col>
					</Row>
					<Row className="ml-auto mr-auto text-center" style={{ marginTop: '15px', height: '900px' }}>
						{this?.mapa()}
					</Row>

					
				</Container>
				</div>
				<Footer/>
			</>
		);
	}
}

const mapStateToProps = state => {
	//console.log(JSON.stringify(state));
	return {
		...state.reducers.homeReducer,
	};
};

const mapDispatchToProps = dispatch => {
	return {
		
		getEmpresas: () => {
			dispatch(getEmpresas());
		},
		getCupones: () => {
			dispatch(getCupones());
		},
		goTo: path => {
			dispatch(push(path));
		},
	};
};



const ContainerHome = connect(
	mapStateToProps,
	mapDispatchToProps
)(HomeView);

const WrappedContainer = GoogleApiWrapper({
	apiKey: 'AIzaSyCuRvqGwte0YrAD0azdqKRO1FbvNlvHBTs',
})(ContainerHome);

export default WrappedContainer;