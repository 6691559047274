import React from 'react';
import classnames from 'classnames';
import { NavLink } from 'react-router-dom';
import { connect } from 'react-redux';
// reactstrap components
import { Collapse, Navbar, NavItem, Nav, Container, Button, Row, Col } from 'reactstrap';
import { push } from 'react-router-redux';
import Botonera from '../../components/Botonera.jsx';
import  * as actions from "../../actions/";
import AdminNavBar from "./AdminNavbar";
import _ from 'lodash';
 
class AuthNavbar extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			collapseOpen: false,
			color: 'navbar-transparent',
			width: null,
			height: null,
		};
		this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
	}

	componentDidMount() {
		this.updateWindowDimensions();
		window.addEventListener('resize', this.updateColor);
		window.addEventListener('resize2', this.updateWindowDimensions);
		const tokenEmpresa = localStorage.getItem('tokenEmpresa');
		const token = localStorage.getItem('token');
		const {user, empresa} = this.props;

		if(_.isEmpty(user) && token){
			this.props.getUserData();
		}else{
		if(_.isEmpty(empresa)&& tokenEmpresa){
			this.props.getEmpresaData();
		}
		}

	}
	componentWillUnmount() {
		window.removeEventListener('resize2', this.updateWindowDimensions);
	}

	updateWindowDimensions() {
		this.setState({ width: window.innerWidth, height: window.innerHeight });
	}

	toggleCollapse = () => {
		this.setState({
			collapseOpen: !this.state.collapseOpen,
			color: !this.state.collapseOpen ? 'black' : 'navbar-transparent',
		});
	};
	goToUrl = url => {
		window.open(url, '_blank');
	};

	handlerClick(e) {
		const { goTo } = this.props;
		switch (e) {
			case 'cupones':
				goTo('/cupones');
				break;
			case 'events':
				goTo('/eventos');
				break;
			case 'pa':
				goTo('/paseos');
				break;
			case 'playas':
				goTo('/playas');
				break;
			case 'alojamiento':
				goTo('/alojamientos');
				break;
			case 'gastronomia':
				goTo('/gastronomia');
				break;
			case 'guia':
				goTo('/guia-comercial');
				break;
			case 'mapa':
				goTo('/mapa');
				break;
			case 'inmobiliarias':
				goTo('/inmobiliarias');
				break;
			case 'home':
				goTo('/');
				break;
			default:
				return;
		}
	}

	navbarcomun  = ()=>{
		const image =
		this.state.color === 'bg-white'
			? 'http://vivipiria.uy/images/logoViviPiria_d.png'
			: 'http://vivipiria.uy/images/logoViviPiria.png';
	return(<Navbar className={classnames('navbar-absolute fixed-top', this.state.color)} expand="lg">
		<Container fluid style={{}}>
			<div className="navbar-wrapper">
				<img alt="logo Vivipiria!" src={image} width="160px" />
			</div>
			<button
				aria-controls="navigation-index"
				aria-expanded={false}
				aria-label="Toggle navigation"
				className="navbar-toggler"
				data-toggle="collapse"
				type="button"
				onClick={this.toggleCollapse}
			>
				<span className="navbar-toggler-bar navbar-kebab" />
				<span className="navbar-toggler-bar navbar-kebab" />
				<span className="navbar-toggler-bar navbar-kebab" />
			</button>
			<Collapse isOpen={this.state.collapseOpen} style={{backgroundColor:"rgb(0 0 0 / 80%"}} navbar>
				<Nav navbar className="ml-auto">
					<NavItem className="navbar-wrapper"></NavItem>
					<NavItem className="navbar-wrapper"></NavItem>

					<NavItem></NavItem>
					<br />

					<NavItem>
						<NavLink to="/login" className="nav-link">
							<i className="tim-icons icon-single-02" /> Login Usuario
						</NavLink>
					</NavItem>
					<br />
					<NavItem>
						<NavLink to="/registro-usuario" className="nav-link">
							<i className="tim-icons icon-single-02" /> Registrarme
						</NavLink>
					</NavItem>
					<br />
					<NavItem>
						<NavLink to="/login-empresa" className="nav-link">
							<i className="tim-icons icon-spaceship" /> Login Empresa
						</NavLink>
					</NavItem>
					<br />
					<NavItem>
						<NavLink to="/registrar-empresa" className="nav-link">
							<i className="tim-icons icon-laptop" /> Registra tu Empresa
						</NavLink>
					</NavItem>
					<br />
					<NavItem className="navbar-wrapper">
						<Button
							className="btn-icon btn-simple"
							color="info"
							onClick={e => {
								this.goToUrl('https://www.facebook.com/vivipiriapolis/');
							}}
						>
							<i className="fab fa-facebook-square mr-auto ml-auto mt-auto mb-auto" />
						</Button>
						
						<Button
							className="btn-icon btn-simple"
							color="primary"
							onClick={e => {
								this.goToUrl('https://www.instagram.com/vivipiria/');
							}}
						>
							<i className="fab fa-instagram mr-auto ml-auto mt-auto mb-auto" />
						</Button>
						<Button
							className="btn-icon btn-simple"
							style={{ color: 'red', borderColor: 'red' }}
							onClick={e => {
								this.goToUrl('#');
							}}
						>
							<i className="fab fa-youtube mr-auto ml-auto mt-auto mb-auto" />
						</Button>
					</NavItem>
				</Nav>
			</Collapse>
		</Container>
	</Navbar>);
	}

	showBar= () => {
		const {user , empresa} = this.props;
		const bar = _.isEmpty(user) ?( _.isEmpty(empresa) ? this.navbarcomun() : <AdminNavBar empresa = {this.props.empresa}/>):<AdminNavBar user = {this.props.user}/>;
	return bar;

	}

	render() {
		return (
			<>
			{this.showBar()}
				<Container fluid style={{ marginTop: '70px' }}>
					<Row>
						<Col className="ml-auto mr-auto text-center" md="12"></Col>
					</Row>
				</Container>
				{this.state.width < 800 ?
				<Botonera/>
				:
				<Container fluid>
				<Row>
						<Col className="ml-auto mr-auto text-center" md="12">
						<Button
									className="btn-simple"
									style={{ border: 'none' }}
									onClick={e => {
										this.handlerClick('home');
									}}
								>
									<img
										alt=""
										src="http://appsuy.com/images_vivi_piria/news-icon.png"
										width="50px"
									></img>
									<h6>Inicio</h6>
								</Button>
							
								<Button
									className="btn-simple"
									style={{ border: 'none' }}
									onClick={e => {
										this.handlerClick('cupones');
									}}
								>
									<img
										alt=""
										src="http://appsuy.com/images_vivi_piria/cupones_icon.png"
										width="50px"
									></img>
									<h6>Cupones</h6>
								</Button>
							
								<Button
									className="btn-simple"
									style={{ border: 'none' }}
									onClick={e => {
										this.handlerClick('events');
									}}
								>
									<img
										alt=""
										src="http://appsuy.com/images_vivi_piria/eventos_icon.png"
										width="50px"
									></img>
									<h6>Eventos</h6>
								</Button>
						
								<Button
									className="btn-simple"
									style={{ border: 'none' }}
									onClick={e => {
										this.handlerClick('pa');
									}}
								>
									<img
										alt=""
										src="http://appsuy.com/images_vivi_piria/paseos_posta.png"
										width="50px"
									></img>
									<h6>Paseos</h6>
								</Button>
							
								<Button
									className="btn-simple"
									style={{ border: 'none' }}
									onClick={e => {
										this.handlerClick('playas');
									}}
								>
									<img
										alt=""
										src="http://appsuy.com/images_vivi_piria/paseos_icon.png"
										width="50px"
									></img>
									<h6>Playas</h6>
								</Button>
							
								<Button
									className="btn-simple"
									style={{ border: 'none' }}
									onClick={e => {
										this.handlerClick('alojamiento');
									}}
								>
									<img
										alt=""
										src="http://appsuy.com/images_vivi_piria/alojamiento-icon.png"
										width="50px"
									></img>
									<h6>Alojamiento</h6>
								</Button>
						
								<Button
									className="btn-simple"
									style={{ border: 'none' }}
									onClick={e => {
										this.handlerClick('gastronomia');
									}}
								>
									<img
										alt=""
										src="http://appsuy.com/images_vivi_piria/gastronomia_icon.png"
										width="50px"
									></img>
									<h6>Gastronomia</h6>
								</Button>
							
								<Button
									className="btn-simple"
									style={{ border: 'none' }}
									onClick={e => {
										this.handlerClick('guia');
									}}
								>
									<img
										alt=""
										src="http://appsuy.com/images_vivi_piria/guia-icon.png"
										width="50px"
									></img>
									<h6>Comercios</h6>
								</Button>
						
								<Button
									className="btn-simple"
									style={{ border: 'none' }}
									onClick={e => {
										this.handlerClick('inmobiliarias');
									}}
								>
									<img
										alt=""
										src="http://appsuy.com/images_vivi_piria/inmobiliaria-icon.png"
										width="50px"
									></img>
									<h6>Inmobiliarias</h6>
								</Button>
								<Button
									className="btn-simple"
									style={{ border: 'none' }}
									onClick={e => {
										this.handlerClick('mapa');
									}}
								>
									<img
										alt=""
										src="http://appsuy.com/images_vivi_piria/mapa-icon.png"
										width="50px"
									></img>
									<h6>Mapa</h6>
								</Button>
						</Col>
					</Row>
								
								</Container>
				}
								
			</>
		);
	}
}

const mapStateToProps = state => {
	//console.log(JSON.stringify(state));
	return {
		...state.reducers.homeReducer,
	};
};

const mapDispatchToProps = dispatch => {
	return {
		goTo: path => {
			dispatch(push(path));
		},
		getUserData: ()=>{
			dispatch(actions.userData());
		},
		getEmpresaData:()=>{
			dispatch(actions.empresaData());
		}
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(AuthNavbar);
