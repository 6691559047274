import { actions } from '../Constants';
import { takeLatest } from 'redux-saga/effects';

import {
	login,
	getEmpresas,
	getCupones,
	getNoticias,
	getCotizaciones,
	alojamientoById,
	gastronomiaById,
	guiaById,
	inmobiliariaById,
	paseoById,
	eventoById,
	regitrarEmpresa,
	regitrarUsuario,
	loginEmpresa,
	playaById,
	imageNames,
	userData,
	empresaData,
	getComments,
	sendComment,
	updateEmpresa,
	updateUser,
	getMensajes,
	sendMensaje,
	changeStatusMensaje,
	getMensajesEnviados,
	changeStatusEmpresa,
	borrarEmpresa,
	changeStatusNoticia,
	borrarNoticia,
	empresaPremium,
	empresaVerificada,
	verificarEmpresa
} from './index';

export function* generator() {
	yield takeLatest(actions.LOGIN, login);
	yield takeLatest(actions.GET_COMMENTS ,getComments);
	yield takeLatest(actions.LOGIN_EMPRESA, loginEmpresa);
	yield takeLatest(actions.UPDATE_EMPRESAS, updateEmpresa);
	yield takeLatest(actions.UPDATE_USER, updateUser);
	yield takeLatest(actions.GET_COTIZACIONES, getCotizaciones);
	yield takeLatest(actions.GET_CUPONES, getCupones);
	yield takeLatest(actions.GET_NOTICIAS, getNoticias);
	yield takeLatest(actions.GET_EMPRESAS, getEmpresas);
	yield takeLatest(actions.ALOJAMIENTO_BY_ID, alojamientoById);
	yield takeLatest(actions.GASTRONOMIA_BY_ID, gastronomiaById);
	yield takeLatest(actions.GUIA_BY_ID, guiaById);
	yield takeLatest(actions.INMOBILIARIA_BY_ID, inmobiliariaById);
	yield takeLatest(actions.PASEO_BY_ID, paseoById);
	yield takeLatest(actions.EVENTO_BY_ID, eventoById);
	yield takeLatest(actions.REGISTRAR_EMPRESA, regitrarEmpresa);
	yield takeLatest(actions.REGISTRAR_USUARIO, regitrarUsuario);
	yield takeLatest(actions.PLAYAS_BY_ID, playaById);
	yield takeLatest(actions.IMAGE_NAME, imageNames);
	yield takeLatest(actions.USER_DATA, userData);
	yield takeLatest(actions.EMPRESA_DATA,empresaData);
	yield takeLatest(actions.SEND_COMMENTS,sendComment);
	yield takeLatest(actions.GET_MENSAJES,getMensajes);
	yield takeLatest(actions.GET_MENSAJES_ENVIADOS,getMensajesEnviados);
	yield takeLatest(actions.SEND_MENSAJE,sendMensaje);
	yield takeLatest(actions.CHANGE_STATUS_MENSAJE,changeStatusMensaje);
	yield takeLatest(actions.CHANGE_EMPRESA_STATUS, changeStatusEmpresa);
	yield takeLatest(actions.BORRAR_EMPRESA, borrarEmpresa);
	yield takeLatest(actions.CHANGE_NOTICIA_STATUS, changeStatusNoticia);
	yield takeLatest(actions.BORRAR_NOTICIA, borrarNoticia);
	yield takeLatest(actions.CHANGE_EMPRESA_PREMIUM, empresaPremium);
	yield takeLatest(actions.CHANGE_EMPRESA_VERIFICATE, empresaVerificada);
	yield takeLatest(actions.VERIFICAR_EMPRESA, verificarEmpresa);
}
