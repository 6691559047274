import { put } from 'redux-saga/effects';
//import { goBack } from 'react-router-redux';
import * as actions from '../actions/';
import * as client from '../client/';
import { errorMessage } from '../errors.js';
import { push } from 'react-router-redux';
//import { goBack, push } from "react-router-redux";

export function* login(payload) {
	try {
		const result = yield client.login(payload);
		yield put(actions.loginSuccess(result));
		localStorage.setItem('token', result.data.token);
		yield put(push('/'));
	} catch (error) {
		yield put(actions.loginError(errorMessage(error)));
	}
}

export function* userData() {
	try {
		const result = yield client.userData();
		yield put(actions.userDataSuccess(result));
	} catch (error) {
		yield put(actions.userDataError(errorMessage(error)));
	}
}

export function* empresaData () {
	try {
		const empresaToken = localStorage.getItem('tokenEmpresa');
		if(!empresaToken) return ;
		const result = yield client.empresaData();
		yield put(actions.empresaDataSuccess(result));
	} catch (error) {
		yield put(actions.empresaDataError(errorMessage(error)));
	}
}

export function* loginEmpresa(payload) {
	try {
		const result = yield client.loginEmpresa(payload);
		yield put(actions.loginEmpresaSuccess(result));
		localStorage.setItem('tokenEmpresa', result.data.token);
		yield put(push('/'));
	} catch (error) {
		yield put(actions.loginEmpresaError(errorMessage(error)));
	}
}

export function* regitrarUsuario(payload) {
	try {
		const result = yield client.registrarUsuario(payload);
		yield put(actions.registrarUsuarioSuccess(result));
		localStorage.setItem('token', result.data.token);
		yield put(push('/'));
		yield client.sendMailRegistroUsuario({email:payload.payload});
		yield client.sendMailNewUserToAdmin({email:payload.payload});
	} catch (error) {
		yield put(actions.registrarUsuarioError(errorMessage(error)));
	}
}

export function* regitrarEmpresa(payload) {
	try {
		const result = yield client.registrarEmpresa(payload);
		yield put(actions.registrarEmpresaSuccess(result));
		localStorage.setItem('tokenEmpresa', result.data.token);
		yield put(push('/'));
		yield client.sendMailRegistroEmpresa({data:payload.payload});
		yield client.sendMailNewEmpresaToAdmin({data:payload.payload});
	} catch (error) {
		yield put(actions.registrarEmpresaError(errorMessage(error)));
	}
}

export function* getCotizaciones() {
	try {
		const result = yield client.getCotizaciones();
		yield put(actions.cotizacionesSuccess(result));
	} catch (error) {
		yield put(actions.cotizacionesError(errorMessage(error)));
	}
}

export function* updateEmpresa(data) {
	try {
		const result = yield client.updateEmpresa(data.data, data.id);
		yield put(actions.updateEmpresaSuccess(result.data));
		localStorage.setItem('tokenEmpresa', result.data.token);
		yield put(actions.empresaData());
	} catch (error) {
		yield put(actions.updateEmpresaError(errorMessage(error)));
	}
}

export function* updateUser(data) {
	try {
		const result = yield client.updateUser(data.data, data.id);
		yield put(actions.updateUserSuccess(result.data));
		localStorage.setItem('token', result.data.token);
		yield put(actions.userData());
	} catch (error) {
		yield put(actions.updateUserError(errorMessage(error)));
	}
}

export function* changeStatusMensaje(data) {
	try {

		const result = yield client.changeStatusMensaje(data);
		yield put(actions.changeStatusMensajeSuccess(result));
	} catch (error) {
		yield put(actions.changeStatusMensajeError(errorMessage(error)));
	}
}

export function* changeStatusNoticia(id) {
	try {
		console.log(id.x);
		const result = yield client.changeStatusNoticia(id.x);
		yield put(actions.changeStatusNoticiaSuccess(result));
	} catch (error) {
		yield put(actions.changeStatusNoticiaError(errorMessage(error)));
	}
}

export function* borrarNoticia(id) {
	try {
		console.log(id.x);
		const result = yield client.borrarNoticia(id.x);
		yield put(actions.borrarNoticiaSuccess(result));
	} catch (error) {
		yield put(actions.borrarNoticiaError(errorMessage(error)));
	}
}

	export function* empresaPremium(id) {
		try {
			console.log(id.x);
			const result = yield client.changePremiumEmpresa(id.x);
			yield put(actions.changePremiumEmpresaSuccess(result));
		} catch (error) {
			yield put(actions.changePremiumEmpresaError(errorMessage(error)));
		}
	}
	export function* empresaVerificada(id) {
		try {
			console.log(id.x);
			const result = yield client.changeVerificateEmpresa(id.x);
			yield put(actions.changeVerificateEmpresaSuccess(result));
		} catch (error) {
			yield put(actions.changeVerificateEmpresaError(errorMessage(error)));
		}
	}
export function* changeStatusEmpresa(id) {
	try {
		console.log(id.x);
		const result = yield client.changeStatusEmpresa(id.x);
		yield put(actions.changeStatusEmpresaSuccess(result));
	} catch (error) {
		yield put(actions.changeStatusEmpresaError(errorMessage(error)));
	}
}


export function* borrarEmpresa(id) {
	try {
		console.log(id.x);
		const result = yield client.borrarEmpresa(id.x);
		yield put(actions.borrarEmpresaSuccess(result));
	} catch (error) {
		yield put(actions.borrarEmpresaError(errorMessage(error)));
	}
}



export function* sendMensaje(data) {
	try {
		const result = yield client.sendMensaje(data);
		yield put(actions.sendMensajeSuccess(result));
	} catch (error) {
		yield put(actions.sendMensajeError(errorMessage(error)));
	}
}

export function* getMensajesEnviados(x) {
	try {
		const result = yield client.getMensajesEnviados(x.x);
		yield put(actions.getMensajesEnviadosSuccess(result.data));
	} catch (error) {
		yield put(actions.getMensajesEnviadosError(errorMessage(error)));
	}
}

export function* getMensajes(x) {
	try {
		const result = yield client.getMensajes(x.x);
		yield put(actions.getMensajesSuccess(result.data));
	} catch (error) {
		yield put(actions.getMensajesError(errorMessage(error)));
	}
}

export function* sendComment(data) {
	try {
		const result = yield client.sendComment(data);
		yield put(actions.sendCommentSuccess(result));
		yield client.sendMailNewComment({data:data.data});
		yield client.sendMailNewCommentToAdmin({data:data.data});
	} catch (error) {
		yield put(actions.sendCommentError(errorMessage(error)));
	}
}

export function* verificarEmpresa(data){
	try {
		const result = yield client.sendMailVerificarEmpresaToAdmin(data);
		yield put(actions.verificarEmpresaSuccess(result));
	} catch (error) {
		yield put(actions.verificarEmpresaError(errorMessage(error)));
	}
}

export function* getComments(x) {
	try {
		const result = yield client.getCommentse(x.x);
		yield put(actions.getCommentsSuccess(result.data));
	} catch (error) {
		yield put(actions.getCommentsError(errorMessage(error)));
	}
}

export function* alojamientoById(x) {
	try {
		const result = yield client.getEmpresa(x.x);
		yield put(actions.alojamientoByIdSuccess(result.data));
	} catch (error) {
		yield put(actions.alojamientoByIdError(errorMessage(error)));
	}
}

export function* gastronomiaById(x) {
	try {
		const result = yield client.getEmpresa(x.x);
		yield put(actions.gastronomiaByIdSuccess(result.data));
	} catch (error) {
		yield put(actions.gastronomiaByIdError(errorMessage(error)));
	}
}

export function* guiaById(x) {
	try {
		const result = yield client.getEmpresa(x.x);
		yield put(actions.guiaByIdSuccess(result.data));
	} catch (error) {
		yield put(actions.guiaByIdError(errorMessage(error)));
	}
}

export function* inmobiliariaById(x) {
	try {
		const result = yield client.getEmpresa(x.x);
		yield put(actions.inmobiliariaByIdSuccess(result.data));
	} catch (error) {
		yield put(actions.inmobiliariaByIdError(errorMessage(error)));
	}
}

export function* imageNames(x) {
	try {
		yield put(actions.imageNamesSuccess(x.x.image));
	} catch (error) {
		yield put(actions.imageNamesError(errorMessage(error)));
	}
}

export function* paseoById(x) {
	try {
		const result = yield client.getNoticia(x.x);
		yield put(actions.paseoByIdSuccess(result.data));
	} catch (error) {
		yield put(actions.paseoByIdError(errorMessage(error)));
	}
}

export function* playaById(x) {
	try {
		const result = yield client.getNoticia(x.x);
		yield put(actions.playaByIdSuccess(result.data));
	} catch (error) {
		yield put(actions.playaByIdError(errorMessage(error)));
	}
}

export function* eventoById(x) {
	try {
		const result = yield client.getNoticia(x.x);
		yield put(actions.eventoByIdSuccess(result.data));
	} catch (error) {
		yield put(actions.eventoByIdError(errorMessage(error)));
	}
}

export function* getEmpresas() {
	try {
		const result = yield client.getEmpresas();
		yield put(actions.empresasSuccess(result));
	} catch (error) {
		yield put(actions.empresasError(errorMessage(error)));
	}
}

export function* getCupones() {
	try {
		const result = yield client.getCupones();
		yield put(actions.cuponesSuccess(result));
	} catch (error) {
		yield put(actions.cuponesError(errorMessage(error)));
	}
}

export function* getNoticias() {
	try {
		const result = yield client.getNoticias();
		yield put(actions.noticiasSuccess(result));
	} catch (error) {
		yield put(actions.noticiasError(errorMessage(error)));
	}
}
