import React from 'react';
import ItemsCarousel from 'react-items-carousel';
import _ from 'lodash';

import { Card, CardImg } from 'reactstrap';

class SliderImagenes extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			children: [],
			activeItemIndex: 0,
			loading: true,
		};
	}

	UNSAFE_componentWillMount() {
		this.setState({
			children: [],
			activeItemIndex: 0,
		});

		setTimeout(() => {
			this.setState({
				children: this.createChildren(1),
				loading: false,
			});
		}, 1000);
	}

	imagenes() {
		const { imagenes, big , event } = this.props;
		const imagenesXseparado = imagenes.split(',');
		const urlImage = event
			? big ? 'https://d39etc8ensw3fs.cloudfront.net/fit-in/650x500/' : 'https://d39etc8ensw3fs.cloudfront.net/fit-in/250x180/'
			: big ? 'https://d39etc8ensw3fs.cloudfront.net/fit-in/650x500/' : 'https://d39etc8ensw3fs.cloudfront.net/fit-in/250x180/';
		const listimagenes = _.map(imagenesXseparado, x => {
			return (
				<div key={x + 'slider'} className="text-center">
					<Card>
						<CardImg alt={urlImage + x} src={urlImage + x}></CardImg>
					</Card>
				</div>
			);
		});

		return listimagenes;
	}

	createChildren = n => {
		return this.imagenes();
	};

	changeActiveItem = activeItemIndex => this.setState({ activeItemIndex });

	render() {
		const { activeItemIndex, children, loading } = this.state;
		const { map, imagenes } = this.props;
		const imagenesXseparado = imagenes.split(',');
		const urlImage = this.props.event
			? 'https://d39etc8ensw3fs.cloudfront.net/fit-in/200x100/'
			: 'https://d39etc8ensw3fs.cloudfront.net/fit-in/200x100/';

		if (map) {
			return <img alt={urlImage + imagenesXseparado[0]} src={urlImage + imagenesXseparado[0]} width={200} />;
		} else {
			return loading ? (
				<div style={{ padding: this.props.padding }}>
					<img alt="..." src={require('../../assets/img/loader.gif')} width={'70px'} />
					<h6>CARGANDO IMAGENES...</h6>
				</div>
			) : (
				<ItemsCarousel
					// Carousel configurations
					numberOfCards={1}
					gutter={5}
					showSlither={true}
					firstAndLastGutter={true}
					freeScrolling={true}
					// Active item configurations
					requestToChangeActive={this.changeActiveItem}
					activeItemIndex={activeItemIndex}
					activePosition={'center'}
					chevronWidth={0}
					rightChevron={
						<h6> <i className={'tim-icons icon-minimal-right'} style={{ fontSize: '30px' }} /></h6>
					}
					leftChevron={
						<h6> <i className={'tim-icons icon-minimal-left'} style={{ fontSize: '30px' }} /></h6>
					}
					outsideChevron={false}
				>
					{children}
				</ItemsCarousel>
			);
		}
	}
}

export default SliderImagenes;
