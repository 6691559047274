import React from 'react';
import { connect } from 'react-redux';
import { goBack, push } from 'react-router-redux';
// nodejs library that concatenates classes
import classNames from 'classnames';
import _ from 'lodash';

// reactstrap components
import { Collapse, Button, Navbar, Nav, Container } from 'reactstrap';

class BotoneraAdmin extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			color: 'navbar-transparent',
		};
	}
	componentDidMount() {
		window.addEventListener('resize', this.updateColor);
	}
	componentWillUnmount() {
		window.removeEventListener('resize', this.updateColor);
	}
	// function that adds color white/transparent to the navbar on resize (this is for the collapse)
	updateColor = () => {
		if (window.innerWidth < 993 && this.state.collapseOpen) {
			this.setState({
				color: 'bg-white',
			});
		} else {
			this.setState({
				color: 'navbar-transparent',
			});
		}
	};
	// this function opens and closes the collapse on small devices
	toggleCollapse = () => {
		if (this.state.collapseOpen) {
			this.setState({
				color: 'navbar-transparent',
			});
		} else {
			this.setState({
				color: !this.state.collapseOpen ? 'navbar-black' : 'navbar-transparent',
			});
		}
		this.setState({
			collapseOpen: !this.state.collapseOpen,
		});
	};
	// this function is to open the Search modal
	toggleModalSearch = () => {
		this.setState({
			modalSearch: !this.state.modalSearch,
		});
	};

	goToUrl = url => {
		window.open(url, '_self');
	};

	getImage = imagenes => {
		const imagenesXseparado = imagenes.split(',');
		return imagenesXseparado[0];
	};

	handlerGoTo = go => {
		if (go === 'listaAlojamiento' && this.props.user.isAdmin) {
			this.props.goToListaAlojamientos();
		}
		if (go === 'nuevaEmpresa' && this.props.user.isAdmin) {
			this.props.goToNuevaEmpresa();
		}
		if (go === 'listaGastronomia' && this.props.user.isAdmin) {
			this.props.goToListaGastronomia();
		}
		if (go === 'listaComercios' && this.props.user.isAdmin) {
			this.props.goToListaComercios();
		}
		if (go === 'listaInmobiliarias' && this.props.user.isAdmin) {
			this.props.goToListaInmobiliaria();
		}
		if (go === 'listaEventos' && this.props.user.isAdmin) {
			this.props.goToListaEventos();
		}

		
		if (this.props.user.nombre) {
			if (go === 'listaUsuarios') {
			}
			if (go === 'listaEventos') {
			}
			if (go === 'listaPaseos') {
			}
			if (go === 'listaPlayas') {
			}
			if (go === 'listaCupones') {
			}
			if (go === 'listaComentarios') {
			}
			if (go === 'listaMensajes') {
			}
		}
	};

	userNavBar = () => {
		const { user } = this.props;
		return (
			<Container fluid>
				{user.isAdmin && (
						<div style={{ margin: '0px'}}>
							<Button
								className="btn-simple"
								color="success"
								style={{ borderRadius: '5px', margin: '10px', width: '200px' }}
								onClick={e => {
									this.handlerGoTo('nuevaEmpresa');
								}}
							>
								Nueva Empresa
							</Button>
							<Button
								className="btn-simple"
								color="success"
								style={{ borderRadius: '5px', margin: '10px', width: '200px' }}
								onClick={e => {
									this.handlerGoTo('listaAlojamiento');
								}}
							>
								Alojamientos
							</Button>
                            <Button
								className="btn-simple"
								color="success"
								style={{ borderRadius: '5px', margin: '10px', width: '200px' }}
								onClick={e => {
									this.handlerGoTo('listaGastronomia');
								}}
							>
								Gastronomia
							</Button>
                            <Button
								className="btn-simple"
								color="success"
								style={{ borderRadius: '5px', margin: '10px', width: '200px' }}
								onClick={e => {
									this.handlerGoTo('listaComercios');
								}}
							>
								Comercios General
							</Button>
                            <Button
								className="btn-simple"
								color="success"
								style={{ borderRadius: '5px', margin: '10px', width: '200px' }}
								onClick={e => {
									this.handlerGoTo('listaInmobiliarias');
								}}
							>
								Inmobiliarias
							</Button>
                            <Button
								className="btn-simple"
								color="success"
								style={{ borderRadius: '5px', margin: '10px', width: '200px' }}
								onClick={e => {
									this.handlerGoTo('listaEventos');
								}}
							>
								Eventos
							</Button>
                            <Button
								className="btn-simple"
								color="warning"
								style={{ borderRadius: '5px', margin: '10px', width: '200px' }}
								onClick={e => {
									this.handlerGoTo('#');
								}}
							>
								Paseos
							</Button>
                            <Button
								className="btn-simple"
								color="warning"
								style={{ borderRadius: '5px', margin: '10px', width: '200px' }}
								onClick={e => {
									this.handlerGoTo('#');
								}}
							>
								Playas
							</Button>
                            <Button
								className="btn-simple"
								color="warning"
								style={{ borderRadius: '5px', margin: '10px', width: '200px' }}
								onClick={e => {
									this.handlerGoTo('#');
								}}
							>
								Cupones
							</Button>
                            <Button
								className="btn-simple"
								color="warning"
								style={{ borderRadius: '5px', margin: '10px', width: '200px' }}
								onClick={e => {
									this.handlerGoTo('#');
								}}
							>
								Usuarios
							</Button>
						</div>
				)}
			</Container>
		);
	};

	getNavBar = () => {
		const { user } = this.props;
		let navBar;
		const token = localStorage.getItem('token');

		if (!_.isEmpty(user) && token) {
			navBar = this.userNavBar();
		}

		return navBar;
	};

	render() {
		return <>{this.getNavBar()}</>;
	}
}

const mapStateToProps = state => {
	//console.log(JSON.stringify(state));
	return {
		...state.reducers.homeReducer,
	};
};

const mapDispatchToProps = dispatch => {
	return {
		goToNuevaEmpresa: () => {
			dispatch(push('/registrar-empresa/'));
		},
		goToListaAlojamientos: () => {
			dispatch(push('/lista-alojamientos/'));
		},
		goToListaGastronomia: () => {
			dispatch(push('/lista-gastronomia/'));
		},
		goToListaComercios: () => {
			dispatch(push('/lista-comercios/'));
		},
		goToListaInmobiliaria: () => {
			dispatch(push('/lista-inmobiliarias/'));
		},
		goToListaEventos: () =>{
			dispatch(push('/lista-eventos/'));
		},
		goTo: () => {
			dispatch(goBack());
		},
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(BotoneraAdmin);
