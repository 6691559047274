import React from 'react';
import { connect } from 'react-redux';
import { registrarUsuario } from '../../actions/';
import Footer from '../../components/Footer/Footer';
import AuthNavbar from '../../components/Navbars/AuthNavbar';
import classnames from 'classnames';
import NotificationAlert from 'react-notification-alert';

//import ImageUpload from "components/CustomUpload/ImageUpload.jsx";

// reactstrap components
import {
	Button,
	Card,
	CardBody,
	CardFooter,
	Label,
	FormGroup,
	Form,
	Input,
	InputGroupAddon,
	InputGroupText,
	InputGroup,
	Container,
	Row,
	Col,
} from 'reactstrap';

class RegisterUser extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			msg: '',
			firstname: '',
			email: '',
			password: '',
			passwordConfirm: '',
			phone: '',
			terminosConfirm: false,
		};
	}

	componentDidMount() {
		document.body.classList.toggle('register-page');
	}
	componentWillUnmount() {
		document.body.classList.toggle('register-page');
	}

	static getDerivedStateFromProps(nextProps, prevState) {
		if (nextProps.msg !== prevState.msg) {
			return { msg: nextProps.msg };
		} else return null;
	}

	componentDidUpdate(prevProps, prevState) {
		if (prevProps.msg !== this.props.msg) {
			this.setState({
				emailState: 'has-danger',
				msg: 'Email ya existe!',
			});
			this.notify('br', this.state.msg);
		}
	}

	notify = (place, message) => {
		//var color = Math.floor(Math.random() * 5 + 1);

		let color = 1;
		let type;
		switch (color) {
			case 1:
				type = 'primary';
				break;
			case 2:
				type = 'success';
				break;
			case 3:
				type = 'danger';
				break;
			case 4:
				type = 'warning';
				break;
			case 5:
				type = 'info';
				break;
			default:
				break;
		}
		let options = {};
		options = {
			place: place,
			message: (
				<div>
					<div>
						<b>Tenemos algunos Errores </b>
						<h6>{message}</h6>
					</div>
				</div>
			),
			type: type,
			icon: 'tim-icons icon-alert-circle-exc',
			autoDismiss: 10,
		};
		this.refs.notificationAlert.notificationAlert(options);
	};

	handleInput = event => {
		if (event.target.name === 'email') {
			this.setState({ ...this.state, email: event.target.value });
		} else if (event.target.name === 'firstname') {
			this.setState({ ...this.state, firstname: event.target.value });
		} else if (event.target.name === 'password') {
			this.setState({ ...this.state, password: event.target.value });
		} else if (event.target.name === 'passwordConfirm') {
			this.setState({ ...this.state, passwordConfirm: event.target.value });
		} else if (event.target.name === 'phone') {
			this.setState({ ...this.state, phone: event.target.value });
		}
	};

	// function that returns true if value is email, false otherwise
	verifyEmail = value => {
		var emailRex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
		if (emailRex.test(value)) {
			return true;
		}
		return false;
	};

	// function that verifies if a string has a given length or not
	verifyLength = (value, length) => {
		if (value.length >= length) {
			return true;
		}
		return false;
	};

	verifyNumber = value => {
		var numberRex = new RegExp('^[0-9]+$');
		if (numberRex.test(value)) {
			return true;
		}
		return false;
	};

	verifyPasswords = value => {
		const { password } = this.state;
		if (password === value) {
			return true;
		}
		return false;
	};

	verifyPasswordsData = value => {
		const content = value.length;

		if (content >= 8) {
			return true;
		}
		return false;
	};

	isValidated = () => {
		if (
			this.state.firstnameState === 'has-success' &&
			this.state.emailState === 'has-success' &&
			this.state.phoneState === 'has-success' &&
			this.state.passwordState === 'has-success' &&
			this.state.passwordConfirmState === 'has-success' &&
			this.state.terminosConfirm
		) {
			return true;
		} else {
			if (this.state.firstnameState !== 'has-success') {
				this.notify('br', 'Completa el nombre');
				this.setState({ firstnameState: 'has-danger'});
			}
			if (this.state.emailState !== 'has-success') {
				this.notify('br', 'Email incorrecto');
				this.setState({ emailState: 'has-danger'});
			}
			if (this.state.phoneState !== 'has-success') {
				this.notify('br', 'Danos un telefono o celular');
				this.setState({ phoneState: 'has-danger'});
			}
			if (this.state.passwordState !== 'has-success') {
				this.notify('br', 'Passwords incorrecto tiene que tener 8 caracteres minimo');
				this.setState({
					passwordState: 'has-danger',
					passwordConfirmState: 'has-danger'
				});
			}
			if (!this.state.terminosConfirm) {
				this.notify('br', 'Tienes que estar de acuerdo con los Terminos y condiciones ');
			}
			return false;
		}
	};

	change = (event, stateName, type, stateNameEqualTo, maxValue) => {
		switch (type) {
			case 'email':
				if (this.verifyEmail(event.target.value)) {
					this.setState({ [stateName + 'State']: 'has-success' });
				} else {
					this.setState({ [stateName + 'State']: 'has-danger' });
				}
				break;
			case 'length':
				if (this.verifyLength(event.target.value, stateNameEqualTo)) {
					this.setState({ [stateName + 'State']: 'has-success' });
				} else {
					this.setState({ [stateName + 'State']: 'has-danger' });
				}
				break;
			case 'number':
				if (this.verifyNumber(event.target.value, stateNameEqualTo)) {
					this.setState({ [stateName + 'State']: 'has-success' });
				} else {
					this.setState({ [stateName + 'State']: 'has-danger' });
				}
				break;
			case 'equal':
				if (this.verifyPasswords(event.target.value, stateNameEqualTo)) {
					this.setState({ [stateName + 'State']: 'has-success' });
				} else {
					this.setState({ [stateName + 'State']: 'has-danger' });
				}
				break;
			case 'passwordV':
				if (this.verifyPasswordsData(event.target.value, stateNameEqualTo)) {
					this.setState({ [stateName + 'State']: 'has-success' });
				} else {
					this.setState({ [stateName + 'State']: 'has-danger', msg: 'Los passwords no son iguales' });
				}
				break;
			default:
				break;
		}
		this.setState({ [stateName]: event.target.value });
	};

	handlerSend = () => {
		if (this.isValidated()) {
			const data = {
				nombre: this.state.firstname,
				email: this.state.email,
				password: this.state.password,
				telefono: this.state.phone,
			};
			this.props.registrarUsuario(data);
		}
	};

	render() {
		return (
			<>
				<AuthNavbar />
				<div className="rna-container">
					<NotificationAlert ref="notificationAlert" />
				</div>
				<div className="content" style={{ marginTop: '120px' }}>
					<Container>
						<Row>
							<Col className="mr-auto" md="7">
								<Card className="card-register card-black" style={{ margin: '0px' }}>
									<div className="ml-auto mr-auto" style={{ paddingTop: '30px' }}>
										<img
											alt="..."
											src={'http://appsuy.com/images_vivi_piria/icono_vivipiria_130.png'}
											width="80px"
										/>
									</div>
									<div className="ml-auto mr-auto">
										<h3 className="info-title">Registro de usuario</h3>
									</div>
									<CardBody>
										<Form className="form">
											<InputGroup
												className={classnames(this.state.firstnameState, {
													'input-group-focus': this.state.firstnameFocus,
												})}
											>
												<InputGroupAddon addonType="prepend">
													<InputGroupText>
														<i className="tim-icons icon-single-02" />
													</InputGroupText>
												</InputGroupAddon>
												<Input
													name="firstname"
													placeholder="Nombre..."
													type="text"
													onChange={e => this.change(e, 'firstname', 'length', 1)}
													onFocus={e => this.setState({ firstnameFocus: true })}
													onBlur={e => this.setState({ firstnameFocus: false })}
												/>
											</InputGroup>

											<InputGroup
												className={classnames(this.state.emailState, {
													'input-group-focus': this.state.emailFocus,
												})}
											>
												<InputGroupAddon addonType="prepend">
													<InputGroupText>
														<i className="tim-icons icon-email-85" />
													</InputGroupText>
												</InputGroupAddon>
												<Input
													name="email"
													placeholder="Email..."
													type="email"
													onChange={e => this.change(e, 'email', 'email')}
													onFocus={e => this.setState({ emailFocus: true })}
													onBlur={e => this.setState({ emailFocus: false })}
												/>
											</InputGroup>
											<InputGroup
												className={classnames(this.state.phoneState, {
													'input-group-focus': this.state.phoneFocus,
												})}
											>
												<InputGroupAddon addonType="prepend">
													<InputGroupText>
														<i className="tim-icons icon-mobile" />
													</InputGroupText>
												</InputGroupAddon>
												<Input
													name="phone"
													placeholder="Telefono / celular..."
													type="number"
													onChange={e => this.change(e, 'phone', 'number')}
													onFocus={e => this.setState({ phoneFocus: true })}
													onBlur={e => this.setState({ phoneFocus: false })}
												/>
											</InputGroup>

											<InputGroup
												className={classnames(this.state.passwordState, {
													'input-group-focus': this.state.passwordFocus,
												})}
											>
												<InputGroupAddon addonType="prepend">
													<InputGroupText>
														<i className="tim-icons icon-lock-circle" />
													</InputGroupText>
												</InputGroupAddon>
												<Input
													name="password"
													placeholder="Contraseña..."
													type="password"
													onChange={e => this.change(e, 'password', 'passwordV')}
													onFocus={e => this.setState({ passwordFocus: true })}
													onBlur={e => this.setState({ passwordFocus: false })}
												/>
											</InputGroup>
											<InputGroup
												className={classnames(this.state.passwordConfirmState, {
													'input-group-focus': this.state.passwordConfirmFocus,
												})}
											>
												<InputGroupAddon addonType="prepend">
													<InputGroupText>
														<i className="tim-icons icon-lock-circle " />
													</InputGroupText>
												</InputGroupAddon>
												<Input
													name="passwordConfirm"
													placeholder="Repita Contraseña..."
													type="password"
													onChange={e => this.change(e, 'passwordConfirm', 'equal')}
													onFocus={e => this.setState({ passwordConfirmFocus: true })}
													onBlur={e => this.setState({ passwordConfirmFocus: false })}
												/>
											</InputGroup>
											<FormGroup check className="text-left">
												<Label check>
													<Input
														type="checkbox"
														onChange={e => {
															this.setState({
																...this.state,
																terminosConfirm: !this.state.terminosConfirm,
															});
														}}
													/>
													<span className="form-check-sign" />
													Estoy de acuerdo{' '}
													<a href="/terminos-y-condiciones" target="_blank">
														Términos y condiciónes
													</a>
													.
												</Label>
											</FormGroup>
										</Form>
									</CardBody>
									<CardFooter>
										<Button
											block
											className="btn-simple mb-3"
											color="success"
											style={{ borderRadius: '5px' }}
											onClick={e => {
												this.handlerSend();
											}}
											size="lg"
										>
											Registrarme
										</Button>
									</CardFooter>
								</Card>
							</Col>
							<Col className="ml-auto" md="5">
								<div className="info-area info-horizontal mt-5" style={{ marginTop: '0px' }}>
									<div className="icon icon-warning" style={{ marginTop: '0px' }}>
										<i className="icon-warning tim-icons icon-shape-star" />
									</div>
									<div className="description">
										<h3 className="info-title"> Regístrate ¡es gratis!</h3>
										{/* <p className="description">
											Si estas en Piriápolis o estás por viajar registrate y descarga nuestra App.
											Podrás estar informado de todo lo que pasa, y lo que puedes hacer en
											Piriápolis Nuestra App una vez descargada es accesible sin internet.
										</p> */}
									</div>
								</div>
								<div className="info-area info-horizontal" style={{ marginTop: '30px' }}>
									<div className="icon icon-primary" style={{ marginTop: '5px' }}>
										<i className="tim-icons icon-tag" />
									</div>
									<div className="description">
										<h3 className="info-title">Obtén Cupones y descuentos </h3>
										<p className="description">
											Registrandote podrás obtener Cupones de descuentos exclusivos para ahorrar y
											vivir Piriápolis a full!
										</p>
									</div>
								</div>
								<div className="info-area info-horizontal" style={{ marginTop: '30px' }}>
									<div className="icon icon-info" style={{ marginTop: '5px' }}>
										<i className="tim-icons icon-trophy" />
									</div>
									<div className="description">
										<h3 className="info-title">Se parte de nuestra comunidad</h3>
										<p className="description">
											Forma parte de la comunidad, podrás comentar y compartir tu experiencia en
											Piriápolis y{' '}
											<span className={'text-success'}>
												<b>ganar estadías y premios de nuestros Socios comerciales.</b>
											</span>
										</p>
									</div>
								</div>
							</Col>
						</Row>
					</Container>
				</div>
				<Footer />
			</>
		);
	}
}

const mapStateToProps = state => {
	return {
		...state.reducers.homeReducer,
	};
};

const mapDispatchToProps = dispatch => {
	return {
		registrarUsuario: data => dispatch(registrarUsuario(data)),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(RegisterUser);
