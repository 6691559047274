const errorList = {
	SERVICE_400: 'Service not currently available. Please retry later.',
};

export function errorMessage(error) {
	if (error.response) {
		if (error.response.data) {
			if (error.response.data.code) return `ERROR_${error.response.data.code}`;
			if (typeof error.response.data === 'string') {
				try {
					error.response.data = JSON.parse(error.response.data.split(' - ')[1]);
					// eslint-disable-next-line
				} catch (error) {}
			}
			if (error.response.data.error) {
				if (error.response.data.error.error) {
					return error.response.data.error.error;
				} else {
					return errorList.SERVICE_400;
				}
			} else if (error.response.data.message) {
				return error.response.data.message;
			} else {
				return JSON.stringify(error.response.data);
			}
		} else if (error.response.statusText) {
			return error.response.statusText;
		} else {
			return JSON.stringify(error.response);
		}
	} else if (error.message) {
		return error.message;
	} else {
		return JSON.stringify(error);
	}
}
