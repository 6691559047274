import React from 'react';
import { connect } from 'react-redux';
import { login } from '../../actions/';
import Footer from '../../components/Footer/Footer';
import AuthNavbar from '../../components/Navbars/AuthNavbar';


// reactstrap components
import {
	Button,
	Card,
	CardBody,
	CardFooter,
	Form,
	Input,
	InputGroupAddon,
	InputGroupText,
	InputGroup,
	Container,
	Col,
	Row,
} from 'reactstrap';

class Login extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			email: '',
			password: '',
		};
	}

	componentDidMount() {
		document.body.classList.toggle('login-page');
	}
	componentWillUnmount() {
		document.body.classList.toggle('login-page');
	}

	handleInput = event => {
		if (event.target.name === 'email') {
			this.setState({ ...this.state, email: event.target.value });
		} else {
			this.setState({ ...this.state, password: event.target.value });
		}
	};

	handlerSend = () => {
		const data = { email: this.state.email, password: this.state.password };
		this.props.login(data);
  };
  goToUrl = (url, target = '_self') => {
		window.open(url, target);
	};

	render() {
		return (
			<>
				<AuthNavbar />
				<div className="content" style={{ margin: '50px' }}>
					<Container>
						<Row>
							<Col className="ml-auto mr-auto" lg="4" md="6">
								<Form className="form">
									<Card className="card-login card-black">
										<div className="ml-auto mr-auto">
											<img
												alt="..."
												src={'http://appsuy.com/images_vivi_piria/icono_vivipiria_130.png'}
												width="80px"
											/>
										</div>
										<div className="ml-auto mr-auto">
											<h3 className="info-title">Iniciar Sesión Usuario</h3>
										</div>
										<CardBody>
											<InputGroup>
												<InputGroupAddon addonType="prepend">
													<InputGroupText>
														<i className="tim-icons icon-email-85 text-success" />
													</InputGroupText>
												</InputGroupAddon>
												<Input
													placeholder="Email"
													name="email"
													type="text"
													onChange={this.handleInput}
												/>
											</InputGroup>
											<InputGroup>
												<InputGroupAddon addonType="prepend">
													<InputGroupText>
														<i className="tim-icons icon-lock-circle text-success" />
													</InputGroupText>
												</InputGroupAddon>
												<Input
													placeholder="Contraseña"
													name="password"
													type="password"
													onChange={this.handleInput}
												/>
											</InputGroup>
                      <div>
                      <h6 className={'link'} style={{float:'right',marginTop:'20px'}}>Recuperar contraseña</h6>
                      </div>
										</CardBody>
										<CardFooter>
											<Button
												block
												className="btn-simple mb-3"
												color="success"
												style={{ borderRadius: '5px' }}
												onClick={e => {
													this.handlerSend();
												}}
												size="lg"
											>
												Iniciar Sesión
											</Button>
                      <Button
												block
												className="btn-simple mb-3"
												color="warning"
												style={{ borderRadius: '5px' }}
												onClick={e => {
													this.goToUrl('https://vivipiria.uy/registro-usuario');
												}}
												size="lg"
											>
												Regístrate
											</Button>
											
										</CardFooter>
									</Card>
								</Form>
							</Col>
						</Row>
					</Container>
				</div>
				<Footer />
			</>
		);
	}
}

const mapStateToProps = state => {
	return {
		msg: state.msg,
		loading: state.loading,
		user: state.user,
	};
};

const mapDispatchToProps = dispatch => {
	return {
		login: data => dispatch(login(data)),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(Login);
