import React from 'react';
import { connect } from 'react-redux';
import { updateUser } from '../../actions/';
import Footer from '../../components/Footer/Footer';
import AuthNavbar from '../../components/Navbars/AuthNavbar';
import _ from 'lodash';
//import UploadImages from './UploadImages.jsx';
import Dropzone from 'react-dropzone';
import axios from 'axios';
// reactstrap components
import {
	Button,
	Card,
	CardBody,
	CardFooter,
	Form,
	Input,
	InputGroupAddon,
	InputGroupText,
	InputGroup,
	Container,
	Row,
	Col,
} from 'reactstrap';

class CuponesPublicados extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			
		};
	}

	
	componentDidMount() {
		
	}

	
	render() {
		return (
			<>
				<AuthNavbar />
				<Container>
					<Row className="ml-auto mr-auto text-center">
						<Col md="12">
							<div style={{ paddingTop: '30px' }}>
								<img
									alt="..."
									src={'http://appsuy.com/images_vivi_piria/icono_vivipiria_130.png'}
									width="80px"
								/>
							</div>
							<div>
								<h3 className="info-title">Cupones Publicados</h3>
							</div>
						</Col>
					</Row>
				</Container>
				<div className="content" style={{ marginTop: '30px' }}>
					<Container>
						
					</Container>
				</div>
				<Footer />
			</>
		);
	}
}

const mapStateToProps = state => {
	return {
		...state.reducers.homeReducer,
		usuario: state.reducers.homeReducer.user,
	};
};

const mapDispatchToProps = dispatch => {
	return {
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(CuponesPublicados);