import { actions } from '../Constants';

const initialState = {
    loading: false,
	msg: '',
	image:'',
};

const reducerUpdateImage = (state = initialState, action) => {
	switch (action.type) {
		case actions.IMAGE_NAME:
			return {
				...state,
				loading: true,
				msg:"cosas que pasan",
				image: action.x,
			};
		case actions.IMAGE_NAME_SUCCESS:
			return {
				...state,
				loading: false,
				image: action.x,
			};
		case actions.IMAGE_NAME_ERROR:
			return {
				...state,
				loading: false,
				msg: action.x,
			};
		default:
			return state;
	}
};

export default reducerUpdateImage;