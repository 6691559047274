import React from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';

import { Button, Card, CardBody, CardFooter, Container, Row, Col, Input } from 'reactstrap';
import Select from 'react-select';
import Switch from 'react-bootstrap-switch';
import { getEmpresas, changeStatusEmpresa, borrarEmpresa,changeVerificateEmpresa,changePremiumEmpresa} from '../../actions/';
import { push } from 'react-router-redux';
import AuthNavbar from '../../components/Navbars/AuthNavbar';
import SliderImagenes from '../../views/components/SliderImagenes.jsx';
import Footer from '../../components/Footer/Footer';
import NotificationAlert from 'react-notification-alert';


class ListaComercios extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			buscar: '',
			categoria: { value: 'todas', label: 'Todas' },
			orderBy: 'desc',
			orderByVerificado: 'desc',
			orderByPremium: 'desc',
			orderByEstado: '',
			orderByEstadoValue:true,
			orderByVerificadoValue: true,
			orderByPremiumValue: true,
			orderByValue: true,
			selected: {},
			scrolling: false,
			page:1
		};
	}

	componentDidMount() {
		this.props.getEmpresas();
	}

	componentWillMount() {
		this.scrollListener = window.addEventListener('scroll',(e)=>{
			this.handleScroll(e);
		})
	}

	notify2 = (place, message) => {
		//var color = Math.floor(Math.random() * 5 + 1);

		let color = 1;
		let type;
		switch (color) {
			case 1:
				type = 'primary';
				break;
			case 2:
				type = 'success';
				break;
			case 3:
				type = 'danger';
				break;
			case 4:
				type = 'warning';
				break;
			case 5:
				type = 'info';
				break;
			default:
				break;
		}
		let options = {};
		options = {
			place: place,
			message: (
				<div>
					<div>
						<b>Tenemos algun problema </b>
						<h6>{message}</h6>
					</div>
				</div>
			),
			type: type,
			icon: 'tim-icons icon-alert-circle-exc',
			autoDismiss: 4,
		};
		this.refs.notificationAlert.notificationAlert(options);
	};

	notify = (place, message) => {
		//var color = Math.floor(Math.random() * 5 + 1);

		let color = 2;
		let type;
		switch (color) {
			case 1:
				type = 'primary';
				break;
			case 2:
				type = 'success';
				break;
			case 3:
				type = 'danger';
				break;
			case 4:
				type = 'warning';
				break;
			case 5:
				type = 'info';
				break;
			default:
				break;
		}
		let options = {};
		options = {
			place: place,
			message: (
				<div>
					<div>
						<b>Hey !!</b>
						<h6>{message}</h6>
					</div>
				</div>
			),
			type: type,
			icon: 'tim-icons icon-check-2',
			autoDismiss: 4,
		};
		this.refs.notificationAlert.notificationAlert(options);
	};

	handleScroll= (e) =>{
		const {scrolling} = this.state;
		if(scrolling) return;

		const lastLi = document.querySelector('div > div:last-child');
		const lastLiOffset = lastLi.offsetTop + lastLi.clientHeight;
		const pageOffset  = window.pageYOffset  + window.innerHeight;
		const bottomOffset = 350;
		if(pageOffset > lastLiOffset - bottomOffset) this.loadMore();

	}
	loadMore= () =>{
		this.setState(prevState=>({
			page:prevState.page + 1,
			scrolling:true,
		}),this.loadMoreResult);
	}

	loadMoreResult=()=>{
		this.setState({
			scrolling:false,
		});
	}
	handlerOnChange(e) {
		this.setState({ ...this.state, buscar: e.target.value });
	}
	handlerCategoria(value) {
		this.setState({ ...this.state, categoria: value });
	}

	handlerOrderBy(value) {
		if (value) {
			this.setState({ ...this.state, orderBy: 'desc', orderByValue: true });
		} else {
			this.setState({ ...this.state, orderBy: '', orderByValue: false });
		}
	}

	handlerOrderByVerificados(value) {
		if (value) {
			this.setState({ ...this.state, orderByVerificado: 'desc', orderByVerificadoValue: true });
		} else {
			this.setState({ ...this.state, orderByVerificado: '', orderByVerificadoValue: false });
		}
	}

	handlerOrderByPremium(value) {
		if (value) {
			this.setState({ ...this.state, orderByPremium: 'desc', orderByPremiumValue: true });
		} else {
			this.setState({ ...this.state, orderByPremium: '', orderByPremiumValue: false });
		}
	}

	handlerOrderByEstado(value) {
		if (value) {
			this.setState({ ...this.state, orderByEstado: '', orderByEstadoValue: true });
		} else {
			this.setState({ ...this.state, orderByEstado: 'desc', orderByEstadoValue: false });
		}
	}

	reemplazarAcentos = cadena => {
		const chars = {
			á: 'a',
			é: 'e',
			í: 'i',
			ó: 'o',
			ú: 'u',
			à: 'a',
			è: 'e',
			ì: 'i',
			ò: 'o',
			ù: 'u',
			ñ: 'n',
			Á: 'A',
			É: 'E',
			Í: 'I',
			Ó: 'O',
			Ú: 'U',
			À: 'A',
			È: 'E',
			Ì: 'I',
			Ò: 'O',
			Ù: 'U',
			Ñ: 'N',
		};
		const expr = /[áàéèíìóòúùñ]/gi;
		const res = cadena.replace(expr, e => {
			return chars[e];
		});
		return res;
	};

	handlerEvent = x => {
		this.setState({ ...this.state, selected: x });
		this.props.changeToEditComercios(x);
	};
	handlerStatus = x =>{
		this.notify('br', 'Se cambio  el estado correctamente');
		this.props.changeStatusEmpresa(x._id);
	}

	handlerPremium = x =>{
		this.notify('br', 'Se cambio a Premium correctamente');
		this.props.changePremiumEmpresa(x._id);
	}

	handlerVerificado = x =>{
		this.notify('br', 'Se cambio a Verificado correctamente');
		this.props.changeVerificateEmpresa(x._id);
	}

	handlerBorrar = x =>{
		if(x.status){
			this.notify2('br', 'Para eliminarlo tiene que estar status OFF');
		}else{
			this.notify('br', 'Se elimino correctamente!');
		this.props.borrarEmpresa(x._id);}
	}


	comercios() {
		const { empresas } = this.props;
		const filterByStatus = _.filter(empresas, x => {
			const empresa = x;
			return empresa;
		});
		const category = this.state.categoria.value === 'todas' ? '' : this.state.categoria.value;
		const soloComercios = _.filter(filterByStatus, x => x.categoria.toLowerCase().includes('comercio'));
		const filterByCategory = _.filter(soloComercios, x =>
			this.reemplazarAcentos(x.categoria.toLowerCase()).includes(category)
		);
		const filterByTextNombre = _.filter(filterByCategory, x =>
			this.reemplazarAcentos(x.nombre.toLowerCase()).includes(
				this.reemplazarAcentos(this.state.buscar.toLowerCase())
			)
		);

		const flOrder = this.state.orderByValue
			? _.orderBy(filterByTextNombre, 'calificacion', this.state.orderBy)
			: filterByTextNombre;


		const flOrderVerificado = this.state.orderByVerificado
			? _.orderBy(flOrder, 'verificado', this.state.orderByVerificado)
			: flOrder;

		const flOrderPremium = this.state.orderByPremium
			? _.orderBy(flOrderVerificado, 'premium', this.state.orderByPremium)
			: flOrderVerificado;

		const flOrder2 = this.state.orderByEstadoValue
			? _.orderBy(flOrderPremium, 'status', this.state.orderByEstado)
			: flOrderPremium;

		const listComerciosLoader = _.take(flOrder2 , (this.state.page * 9));

		const listComercios = _.map(listComerciosLoader, x => {
			const votos = x.cantidadvotos.split(' ');

			return (
				<div className="ml-auto mr-auto text-center" key={x._id}>
					{ !x.removed &&
					<Card
						className="card-pricing card-primary card-black"
						style={{
							width: '340px',
							height: '700px',
							margin: '20px',
							backgroundColor: '#161619',
							backgroundImage: 'url("http://appsuy.com/images_vivi_piria/bg.png")',
						}}
					>
						<SliderImagenes imagenes={x.image} padding={'95px'} />
							<div
							style={{
								float: 'right',
								marginTop: '-41px',
								height: '42px',
							}}
						>
						<div style={{width:"280px", marginTop:"-225px" }}>
					{
								x.verificado && 
								<Button className="btn-simple" style={{ border: '0px', color:"#6AFF03", float:"left"}}>
								<i className="icon-check-circle" style={{color:"#6AFF03", fontSize:"25px"}}/>
							
								</Button>
							}
							{
								x.premium && 
								<Button className="btn-simple" style={{ border: '0px', color:"#FF0348", float:"left", marginLeft:"-30px"}}>
								<i className="icon-award" style={{color:"#FF0348", fontSize:"25px"}}/>
								
								</Button>
							}
							</div>
							<div style={{width:"300px",marginTop:"240px"}}>
							<Button className="btn-simple" color="warning" style={{ border: '0px' }}>
								<i className={'tim-icons icon-shape-star'} style={{ fontSize: '15px' }} />
								{x.calificacion}
							</Button>
							<Button className="btn-simple"  style={{ border: '0px',color:"#0BF5FC " }}>
								<i className={'tim-icons icon-tap-02'} style={{ fontSize: '15px' }} />
								{votos[0]}
							</Button>
							</div>
						</div>
						<CardBody>
							<h4 style={{ margin: '10px' }}>
								<b>{x.nombre}</b>
							</h4>
							<p style={{ padding: '3px' }}>
								{_.truncate(x.descripcionCorta, { length: 80, separator: ' ...' })}
							</p>
						</CardBody>
						<CardFooter>
							<div style={{ marginTop: '-30px' }}>
								<Button
									className="btn-simple"
									color="warning"
									style={{ borderRadius: '5px', margin: '10px',width:'100%'  }}
									onClick={e => {
										this.handlerEvent(x);
									}}
								>
									Editar
								</Button>
                                <Button
									className="btn-simple"
									color={x.status ? 'success' : 'info'}
									style={{ borderRadius: '5px', margin: '10px', width:'100%' }}
									onClick={e => {
										x.status = !x.status;
										this.handlerStatus(x);
									}}
								>
									Estado: {x.status ? 'Activo' : 'Off'}
								</Button>
								<Button
									className="btn-simple"
									color={x.premium ? 'success' : 'info'}
									style={{ borderRadius: '5px', margin: '10px', width:'100%' }}
									onClick={e => {
										x.premium = !x.premium;
										this.handlerPremium(x);
									}}
								>
									Premium: {x.premium ? 'On' : 'Off'}
								</Button>
								<Button
									className="btn-simple"
									color={x.verificado ? 'success' : 'info'}
									style={{ borderRadius: '5px', margin: '10px', width:'100%' }}
									onClick={e => {
										x.verificado = !x.verificado;
										this.handlerVerificado(x);
									}}
								>
									Verificado: {x.verificado ? 'On' : 'Off'}
								</Button>
                                <Button
									className="btn-simple"
									color="primary"
									style={{ borderRadius: '5px', margin: '10px',width:'100%'  }}
									onClick={e => {
										x.removed = true;
										this.handlerBorrar(x);
									}}
								>
									Eliminar
								</Button>
							</div>
						</CardFooter>
					</Card>
					}
				</div>
			);
		});
		return listComercios;
	}

	render() {
		return (
			<div>
				<AuthNavbar />
				<div className="rna-container">
					<NotificationAlert ref="notificationAlert" />
				</div>
				<Container fluid>
					<Row className="ml-auto mr-auto text-center" md="12">
						<Col className="ml-auto mr-auto text-center" md="6" style={{ marginTop: '15px' }}>
							<h4 className="title text-left">
								<img
									alt="Maricquerias , Pizzerias, restaurantes, comida rapida, en Piriápolis"
									src="http://appsuy.com/images_vivi_piria/gastronomia_icon.png"
									width="50px"
								/>
								Comercios ADMIN
							</h4>
							<div style={{ marginLeft: '60px', marginTop: '10px' }}>
								<h6 className="title text-left text-warning">
									{this.state.buscar === '' ? '' : 'Resultados busqueda : ' + this.state.buscar}{' '}
								</h6>
							</div>
						</Col>
						<Col className="ml-auto mr-auto text-left" md="3" sm="3" style={{ marginTop: '15px' }}>
							<h6>Categorias de comercios</h6>
							<Select
								className="react-select success"
								classNamePrefix="react-select"
								name="singleSelect"
								value={this.state.categoria}
								onChange={value => this.handlerCategoria(value)}
								options={[
									{ value: '', label: 'Todas' },
									{ value: 'inmobiliaria', label: 'Inmonbiliarias' },
									{ value: 'farmacia', label: 'Farmacias' },
									{ value: 'supermercado', label: 'Supermercados' },
									{ value: 'autoservice', label: 'Autoservicios' },
									{ value: 'estacion', label: 'Estaciones de servicio' },
									{ value: 'heladeria', label: 'Heladerias' },
									{ value: 'panaderia', label: 'Panaderias' },
									{ value: 'ropa', label: 'Tiendas de ropa' },
									{ value: 'celula', label: 'Celulares y accesorios' },
									{ value: 'electro', label: 'Electrodomesticos' },
									{ value: 'mueble', label: 'Muebles' },
									{ value: 'tela', label: 'Telas' },
									{ value: 'barraca', label: 'Barracas y Ferreterias' },
								]}
								placeholder="Categorias"
							/>
						</Col>
						<Col className="ml-auto mr-auto text-left" md="3" sm="3" style={{ marginTop: '15px' }}>
							<h6>Buscar Comercio</h6>
							<Input
								className="success"
								placeholder="BUSCAR..."
								onChange={e => {
									this.handlerOnChange(e);
								}}
								style={{ borderColor: '#0AF4E9' }}
							/>
						</Col>
					</Row>
					<Row className="ml-auto mr-auto text-center" md="12">
						<Col className="ml-auto mr-auto text-left" md="6" sm="6"></Col>
						<Col className="ml-auto mr-auto" md="6" sm="6">
						<div className="title" style={{ margin: '10px',float:'right'}}>
								<p style={{fontSize:'12px'}}>Calificación</p>
								<Switch
									value={this.state.orderByValue}
									offColor=""
									offText={<i className="tim-icons icon-simple-remove" />}
									onColor=""
									onText={<i className="tim-icons icon-check-2" />}
									onChange={e => this.handlerOrderBy(e.value())}
								/>
							</div>
							<div className="title" style={{ margin: '10px',float:'right'}}>
							<p style={{fontSize:'12px'}}>Verificados</p>
								<Switch
									value={this.state.orderByVerificadoValue}
									offColor=""
									offText={<i className="tim-icons icon-simple-remove" />}
									onColor=""
									onText={<i className="tim-icons icon-check-2" />}
									onChange={e => this.handlerOrderByVerificados(e.value())}
								/>
							</div>
							<div className="title" style={{ margin: '10px',float:'right'}}>
							<p style={{fontSize:'12px'}}>Premium</p>
								<Switch
									value={this.state.orderByPremiumValue}
									offColor=""
									offText={<i className="tim-icons icon-simple-remove" />}
									onColor=""
									onText={<i className="tim-icons icon-check-2" />}
									onChange={e => this.handlerOrderByPremium(e.value())}
								/>
							</div>
							<div className="title" style={{ margin: '10px',float:'right'}}>
							<p style={{fontSize:'12px'}}>Estado</p>
								<Switch
									value={this.state.orderByEstadoValue}
									offColor=""
									offText={<i className="tim-icons icon-simple-remove" />}
									onColor=""
									onText={<i className="tim-icons icon-check-2" />}
									onChange={e => this.handlerOrderByEstado(e.value())}
								/>
							</div>
							<h6 style={{ margin: '10px',float:'right'}}>Ordenar por: </h6>
						</Col>
					</Row>
					<Row className=" marco ml-auto mr-auto text-center" style={{ marginTop: '10px' }}>
						{this.comercios()}
					</Row>
				</Container>
				<Footer/>
			</div>
		);
	}
}

const mapStateToProps = state => {
	//console.log(JSON.stringify(state));
	return {
		...state.reducers.homeReducer,
	};
};

const mapDispatchToProps = dispatch => {
	return {
		borrarEmpresa : x=>{
			dispatch(borrarEmpresa(x));
		},
		changeStatusEmpresa: x => {
			dispatch(changeStatusEmpresa(x));
		},
		changeToEditComercios: x => {
			dispatch(push('/edit-comercio-admin/' + x._id));
		},
		getEmpresas: () => {
			dispatch(getEmpresas());
		},
		changePremiumEmpresa: x => {
			dispatch(changePremiumEmpresa(x));
		},
		changeVerificateEmpresa: x => {
			dispatch(changeVerificateEmpresa(x));
		},
		goTo: path => {
			dispatch(push(path));
		},
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(ListaComercios);