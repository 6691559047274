import { actions } from '../Constants';

//Cotizaciones
export const getCotizaciones = payload => ({
	type: actions.GET_COTIZACIONES,
	payload,
});

export const cotizacionesSuccess = payload => ({
	type: actions.GET_COTIZACIONES_SUCCESS,
	payload,
});

export const cotizacionesError = payload => ({
	type: actions.GET_COTIZACIONES_ERROR,
	payload,
});

//EMPRESAS


export const getEmpresas = payload => ({
	type: actions.GET_EMPRESAS,
	payload,
});

export const empresasSuccess = payload => ({
	type: actions.GET_EMPRESAS_SUCCESS,
	payload,
});

export const empresasError = payload => ({
	type: actions.GET_EMPRESAS_ERROR,
	payload,
});

export const updateEmpresa = (data, id) => ({
	type: actions.UPDATE_EMPRESAS,
	data,
	id,
});

export const updateEmpresaSuccess = data => ({
	type: actions.UPDATE_EMPRESAS_SUCCESS,
	data,
});

export const updateEmpresaError = data => ({
	type: actions.UPDATE_EMPRESAS_ERROR,
	data,
});

export const updateUser = (data, id) => ({
	type: actions.UPDATE_USER,
	data,
	id,
});

export const updateUserSuccess = data => ({
	type: actions.UPDATE_USER_SUCCESS,
	data,
});

export const updateUserError = data => ({
	type: actions.UPDATE_USER_ERROR,
	data,
});

//Mensajes

export const getMensajesEnviados = x => ({
	type: actions.GET_MENSAJES_ENVIADOS,
	x,
});

export const getMensajesEnviadosSuccess = payload => ({
	type: actions.GET_MENSAJES_ENVIADOS_SUCCESS,
	payload,
});

export const getMensajesEnviadosError = payload => ({
	type: actions.GET_MENSAJES_ENVIADOS_ERROR,
	payload,
});

export const changeStatusMensaje = x => ({
	type: actions.CHANGE_STATUS_MENSAJE,
	x,
});

export const changeStatusMensajeSuccess = payload => ({
	type: actions.CHANGE_STATUS_MENSAJE_SUCCESS,
	payload,
});
export const changeStatusMensajeError = payload => ({
	type: actions.CHANGE_STATUS_MENSAJE_ERROR,
	payload,
});

export const getMensajes = x => ({
	type: actions.GET_MENSAJES,
	x,
});

export const getMensajesSuccess = payload => ({
	type: actions.GET_MENSAJES_SUCCESS,
	payload,
});

export const getMensajesError = payload => ({
	type: actions.GET_MENSAJES_ERROR,
	payload,
});

export const sendMensaje = data => ({
	type: actions.SEND_MENSAJE,
	data,
});

export const sendMensajeSuccess = payload => ({
	type: actions.SEND_MENSAJE_SUCCESS,
	payload,
});

export const sendMensajeError = payload => ({
	type: actions.SEND_MENSAJE_ERROR,
	payload,
});

//CUPONES
export const getCupones = payload => ({
	type: actions.GET_CUPONES,
	payload,
});

export const cuponesSuccess = payload => ({
	type: actions.GET_CUPONES_SUCCESS,
	payload,
});

export const cuponesError = payload => ({
	type: actions.GET_CUPONES_ERROR,
	payload,
});

//NOTICIAS
export const getNoticias = payload => ({
	type: actions.GET_NOTICIAS,
	payload,
});

export const borrarNoticia = x => ({
	type: actions.BORRAR_NOTICIA,
	x,
});

export const borrarNoticiaSuccess = () => ({
	type: actions.BORRAR_NOTICIA_SUCCESS,
});

export const borrarNoticiaError = x => ({
	type: actions.BORRAR_NOTICIA_ERROR,
	x,
});

export const changeStatusNoticia = x => ({
	type: actions.CHANGE_NOTICIA_STATUS,
	x,
});

export const changeStatusNoticiaSuccess = x => ({
	type: actions.CHANGE_NOTICIA_STATUS_SUCCESS,
	selected: x,
});

export const changeStatusNoticiaError = x => ({
	type: actions.CHANGE_NOTICIA_STATUS_ERROR,
	x,
});

export const noticiasSuccess = payload => ({
	type: actions.GET_NOTICIAS_SUCCESS,
	payload,
});

export const noticiasError = payload => ({
	type: actions.GET_NOTICIAS_ERROR,
	payload,
});

//userData
export const userData = () => ({
	type: actions.USER_DATA,
});

export const userDataSuccess = payload => ({
	type: actions.USER_DATA_SUCCESS,
	payload,
});

export const userDataError = payload => ({
	type: actions.USER_DATA_ERROR,
	payload,
});

//comments
export const getComments = x => ({
	type: actions.GET_COMMENTS,
	x,
});

export const getCommentsSuccess = payload => ({
	type: actions.GET_COMMENTS_SUCCESS,
	payload,
});

export const getCommentsError = payload => ({
	type: actions.GET_COMMENTS_ERROR,
	payload,
});

export const sendComment = data => ({
	type: actions.SEND_COMMENTS,
	data,
});

export const sendCommentSuccess = payload => ({
	type: actions.SEND_COMMENTS_SUCCESS,
	payload,
});

export const sendCommentError = payload => ({
	type: actions.SEND_COMMENTS_ERROR,
	payload,
});

//EmpresaData
export const empresaData = () => ({
	type: actions.EMPRESA_DATA,
});

export const empresaDataSuccess = payload => ({
	type: actions.EMPRESA_DATA_SUCCESS,
	payload,
});

export const empresaDataError = payload => ({
	type: actions.EMPRESA_DATA_ERROR,
	payload,
});

//login
export const login = payload => ({
	type: actions.LOGIN,
	payload,
});

export const loginSuccess = payload => ({
	type: actions.LOGIN_SUCCESS,
	payload,
});

export const loginError = payload => ({
	type: actions.LOGIN_ERROR,
	payload,
});

//Registrar Empresa
export const registrarEmpresa = payload => ({
	type: actions.REGISTRAR_EMPRESA,
	payload,
});

export const registrarEmpresaSuccess = payload => ({
	type: actions.REGISTRAR_EMPRESA_SUCCESS,
	payload,
});

export const registrarEmpresaError = payload => ({
	type: actions.REGISTRAR_EMPRESA_ERROR,
	payload,
});

//Registrar Usuario
export const registrarUsuario = payload => ({
	type: actions.REGISTRAR_USUARIO,
	payload,
});

export const registrarUsuarioSuccess = payload => ({
	type: actions.REGISTRAR_USUARIO_SUCCESS,
	payload,
});

export const registrarUsuarioError = payload => ({
	type: actions.REGISTRAR_USUARIO_ERROR,
	payload,
});

//login Empresa
export const loginEmpresa = payload => ({
	type: actions.LOGIN_EMPRESA,
	payload,
});

export const loginEmpresaSuccess = payload => ({
	type: actions.LOGIN_EMPRESA_SUCCESS,
	payload,
});

export const loginEmpresaError = payload => ({
	type: actions.LOGIN_EMPRESA_ERROR,
	payload,
});

export const borrarEmpresa = x => ({
	type: actions.BORRAR_EMPRESA,
	x,
});

export const borrarEmpresaSuccess = () => ({
	type: actions.BORRAR_EMPRESA_SUCCESS,
});

export const borrarEmpresaError = x => ({
	type: actions.BORRAR_EMPRESA_ERROR,
	x,
});

export const changeStatusEmpresa = x => ({
	type: actions.CHANGE_EMPRESA_STATUS,
	x,
});

export const changeStatusEmpresaSuccess = x => ({
	type: actions.CHANGE_EMPRESA_STATUS_SUCCESS,
	selected: x,
});

export const changeStatusEmpresaError = x => ({
	type: actions.CHANGE_EMPRESA_STATUS_ERROR,
	x,
});

export const verificarEmpresa= data=>({
	type: actions.VERIFICAR_EMPRESA,
	data,
});

export const verificarEmpresaError= data=>({
	type: actions.VERIFICAR_EMPRESA_ERROR,
	data,
});

export const verificarEmpresaSuccess= data=>({
	type: actions.VERIFICAR_EMPRESA_SUCCESS,
	data,
});

export const changeVerificateEmpresa = x => ({
	type: actions.CHANGE_EMPRESA_VERIFICATE,
	x,
});

export const changeVerificateEmpresaSuccess = x => ({
	type: actions.CHANGE_EMPRESA_VERIFICATE_SUCCESS,
	selected: x,
});

export const changeVerificateEmpresaError = x => ({
	type: actions.CHANGE_EMPRESA_VERIFICATE_ERROR,
	x,
});

export const changePremiumEmpresa = x => ({
	type: actions.CHANGE_EMPRESA_PREMIUM,
	x,
});

export const changePremiumEmpresaSuccess = x => ({
	type: actions.CHANGE_EMPRESA_PREMIUM_SUCCESS,
	selected: x,
});

export const changePremiumEmpresaError = x => ({
	type: actions.CHANGE_EMPRESA_PREMIUM_ERROR,
	x,
});

export const alojamientoById = x => ({
	type: actions.ALOJAMIENTO_BY_ID,
	x,
});

export const alojamientoByIdSuccess = x => ({
	type: actions.ALOJAMIENTO_BY_ID_SUCCESS,
	selected: x,
});

export const alojamientoByIdError = x => ({
	type: actions.ALOJAMIENTO_BY_ID_ERROR,
	x,
});

export const gastronomiaById = x => ({
	type: actions.GASTRONOMIA_BY_ID,
	x,
});

export const gastronomiaByIdSuccess = x => ({
	type: actions.GASTRONOMIA_BY_ID_SUCCESS,
	selected: x,
});

export const gastronomiaByIdError = x => ({
	type: actions.GASTRONOMIA_BY_ID_ERROR,
	x,
});

export const guiaById = x => ({
	type: actions.GUIA_BY_ID,
	x,
});

export const guiaByIdSuccess = x => ({
	type: actions.GUIA_BY_ID_SUCCESS,
	selected: x,
});

export const guiaByIdError = x => ({
	type: actions.GUIA_BY_ID_ERROR,
	x,
});

export const inmobiliariaById = x => ({
	type: actions.INMOBILIARIA_BY_ID,
	x,
});

export const inmobiliariaByIdSuccess = x => ({
	type: actions.INMOBILIARIA_BY_ID_SUCCESS,
	selected: x,
});

export const inmobiliariaByIdError = x => ({
	type: actions.INMOBILIARIA_BY_ID_ERROR,
	x,
});

export const paseoById = x => ({
	type: actions.PASEO_BY_ID,
	x,
});

export const paseoByIdSuccess = x => ({
	type: actions.PASEO_BY_ID_SUCCESS,
	selected: x,
});

export const paseoByIdError = x => ({
	type: actions.PASEO_BY_ID_ERROR,
	x,
});

export const playaById = x => ({
	type: actions.PLAYAS_BY_ID,
	x,
});

export const playaByIdSuccess = x => ({
	type: actions.PLAYAS_BY_ID_SUCCESS,
	selected: x,
});

export const playaByIdError = x => ({
	type: actions.PLAYAS_BY_ID_ERROR,
	x,
});

export const eventoById = x => ({
	type: actions.EVENTO_BY_ID,
	x,
});

export const eventoByIdSuccess = x => ({
	type: actions.EVENTO_BY_ID_SUCCESS,
	selected: x,
});

export const eventoByIdError = x => ({
	type: actions.EVENTO_BY_ID_ERROR,
	x,
});

export const imageNames = x => ({
	type: actions.IMAGE_NAME,
	x,
});

export const imageNamesSuccess = x => ({
	type: actions.IMAGE_NAME_SUCCESS,
	x,
});

export const imageNamesError = x => ({
	type: actions.IMAGE_NAME_ERROR,
	x,
});
