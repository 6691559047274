import React from 'react';
import { connect } from 'react-redux';

import { Button, Container, Row, Col} from 'reactstrap';

import { goBack } from 'react-router-redux';
import AuthNavbar from '../../components/Navbars/AuthNavbar';

import { playaById } from '../../actions/';
import { withScriptjs, withGoogleMap, GoogleMap, Marker } from 'react-google-maps';
import SliderImagenes from '../../views/components/SliderImagenes.jsx';
import YouTube from 'react-youtube';
import _ from 'lodash';
import ReactBSAlert from 'react-bootstrap-sweetalert';
import Footer from '../../components/Footer/Footer';
import Comments from '../../components/comments/comments';

import Shared from '../../views/components/Shared.jsx';

const opts = {
	width: '100%',
	playerVars: {
		// https://developers.google.com/youtube/player_parameters
		autoplay: 1,
	},
};

const SatelliteMap = withScriptjs(
	withGoogleMap(props => {
		return (
			<GoogleMap
				defaultZoom={16}
				defaultCenter={{ lat: Number(props.selected.latitude), lng: Number(props.selected.longitude) }}
				defaultOptions={{
					scrollwheel: false,
				}}
			>
				<Marker position={{ lat: Number(props.selected.latitude), lng: Number(props.selected.longitude) }} />
			</GoogleMap>
		);
	})
);

class PaseoById extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			selected: {},
			alert: null,
		};
	}

	static getDerivedStateFromProps(nextProps, prevState) {
		if (nextProps.selected !== prevState.selected) {
			return { selected: nextProps.selected };
		} else return null;
	}

	componentDidUpdate(prevProps, prevState) {
		if (prevProps.selected !== this.props.selected) {
			this.setState({ selected: this.props.selected });
		}
	}

	componentDidMount() {
		const { ids } = this.props;
		this.props.changeToPlayaById(ids);
	}

	componentWillUnmount() {}

	handlerClick() {
		//const { goTo } = this.props;
		//goTo();
		//this.goToUrl('http://localhost:3000/paseos', '_self');
		this.goToUrl('https://vivipiria.uy/playas', '_self');
	}

	goToUrl = (url, target = '_blank') => {
		window.open(url, target);
	};

	getImage = (imagenes) =>{
		try{
		const imagenesXseparado = imagenes.split(',');
		return imagenesXseparado[0];
	}catch(err){
		console.log(err);
	}
	}

	htmlAlert = (title, idVideo) => {
		this.setState({
			alert: (
				<ReactBSAlert
					style={{ display: 'block'}}
					title={title}
					onConfirm={() => this.hideAlert()}
					onCancel={() => this.hideAlert()}
					showConfirm={false}
					btnSize=""
				>
					<YouTube videoId={idVideo} opts={opts} onReady={this._onReady} />
				</ReactBSAlert>
			),
		});
	};

	hideAlert = () => {
		this.setState({
			alert: null,
		});
	};

	render() {
		const { selected } = this.state;
		let videos = [];
		if (selected && selected.videos) {
			videos = selected.videos.split(',');
		}

		return this.props.loading ? (
			<>
				<Container fluid style={{ marginTop: '200px' }}>
					<Row className="ml-auto mr-auto text-center" md="12">
						<Col className="ml-auto mr-auto text-center" md="12" style={{ marginTop: '50px' }}>
							<img alt="..." src={require('../../assets/img/loader.gif')} width={'60px'} />
						</Col>
					</Row>
				</Container>
			</>
		) : (
			<>
				<div className="content">{this.state.alert}</div>
				<AuthNavbar />
				<Container fluid style={{marginTop:"20px"}}>
					<Row className="ml-auto mr-auto text-left" md="12">
						<Col className="ml-auto mr-auto text-left" md="9">
							<h4 className="title text-left">
								<img
									alt="Paseos ,que hacer, Piriapolis, donde ir"
									src="http://appsuy.com/images_vivi_piria/paseos_icon.png"
									width="50px"
								/>
								{selected ? ' ' + selected.titulo : ''}
							</h4>
						</Col>
					</Row>

					<Row className="ml-auto mr-auto text-center" md="12" style={{marginTop:"20px"}}>
						<Col className="ml-auto mr-auto text-center" md="9">
							{selected ? (
								selected.imagen ? (
									<SliderImagenes imagenes={selected.imagen} big={true} event={true}/>
								) : (
									require('../../assets/img/loader.gif')
								)
							) : (
								require('../../assets/img/loader.gif')
							)}
						</Col>
					</Row>
					<Row className="ml-auto mr-auto text-left" md="9">
					<Col className="ml-auto mr-auto text-left" md="9" style={{ marginTop: '-20px' }}>
					<p className="text-right">
							<i className={'tim-icons icon-tap-02 text-success'} style={{ fontSize: '25px' }} />
								{selected ? selected.cantidadVotos : ''}

							<i className={'tim-icons icon-shape-star text-warning'} style={{ marginLeft:"10px",fontSize: '25px' }} />
								{selected ? selected.rating : ''}
							</p>
							</Col>
						<Col className="ml-auto mr-auto text-left" md="9" style={{ marginTop: '10px' }}>
							<h6>Descripción: </h6>
							<p>{selected ? ' ' + selected.descripcionLarga : ''}</p>
						</Col>
					</Row>
					<Row className="ml-auto mr-auto text-left" md="9">
						<Col className="ml-auto mr-auto text-left" md="3" style={{ marginTop: '10px' }}>
							{selected ? (
								selected.telefono ? (
									<p className="text-left">
										<i
											className={'tim-icons icon-mobile text-primary'}
											style={{ fontSize: '25px' }}
										/>
										{selected ? ' ' + selected.telefono : ''}
									</p>
								) : (
									''
								)
							) : (
								''
							)}

							{selected ? (
								selected.web ? (
									<p className="text-left">
										<a href={selected ? ' ' + selected.web : ''} target={'_blank'}>
											<i
												className={'tim-icons icon-world text-info'}
												style={{ fontSize: '25px' }}
											/>
											{selected ? (selected.web ? ' Pagina web: ' + selected.titulo : '') : ''}
										</a>
									</p>
								) : (
									''
								)
							) : (
								''
							)}

							{selected ? (
								selected.email ? (
									<p className="text-left">
										<i
											className={'tim-icons icon-email-85 text-warning'}
											style={{ fontSize: '25px' }}
										/>
										{selected ? (selected.email ? '  ' + selected.email : '') : ''}
									</p>
								) : (
									''
								)
							) : (
								''
							)}

							{selected ? (
								selected.horarios ? (
									<p className="text-left">
										<i
											className={'tim-icons icon-watch-time text-success'}
											style={{ fontSize: '25px' }}
										/>
										{selected ? (selected.horarios ? '  ' + selected.horarios : '') : ''}
									</p>
								) : (
									''
								)
							) : (
								''
							)}

							{selected ? (
								selected.whatsapp ? (
									<p className="text-left">
										<img
											alt=""
											src={'http://appsuy.com/images_vivi_piria/icono-whatsapp.png'}
											width="30px"
										/>
										<a
											href={
												selected
													? 'https://api.whatsapp.com/send?phone=598' +
													  selected.whatsapp.replace(/ /g, '') +
													  ''
													: ''
											}
											target={'_blank'}
										>
											{selected ? (selected.whatsapp ? ' Contacto via WhatsApp ' : '') : ''}
										</a>
									</p>
								) : (
									''
								)
							) : (
								''
							)}
						</Col>
						<Col className="ml-auto mr-auto text-right" md="3" style={{ marginTop: '10px' }}>
							{selected ? (
								selected.facebook || selected.twitter || selected.instagram ? (
									<h6>Redes sociales de {selected ? ' ' + selected.nombre : ''}</h6>
								) : (
									''
								)
							) : (
								''
							)}
							{selected ? (
								selected.facebook ? (
									<Button
										className="btn-icon btn-simple"
										color="facebook"
										onClick={e => {
											this.goToUrl(selected ? selected.facebook : '');
										}}
									>
										<i className="fab fa-facebook-square ml-auto" />
									</Button>
								) : (
									''
								)
							) : (
								''
							)}

							{selected ? (
								selected.twitter ? (
									<Button
										className="btn-icon btn-simple"
										color="twitter"
										onClick={e => {
											this.goToUrl(selected ? selected.twitter : '');
										}}
									>
										<i className="fab fa-twitter ml-auto" />
									</Button>
								) : (
									''
								)
							) : (
								''
							)}

							{selected ? (
								selected.instagram ? (
									<Button
										className="btn-icon btn-simple"
										color="primary"
										onClick={e => {
											this.goToUrl(selected ? selected.instagram : '');
										}}
									>
										<i className="fab fa-instagram ml-auto mr-auto" />
									</Button>
								) : (
									''
								)
							) : (
								''
							)}
						</Col>
					</Row>
					<Row className="ml-auto mr-auto text-center" md="12">
						<Col className="ml-auto mr-auto text-left" md="9" style={{ marginTop: '10px' }}>
						{selected ? (
								selected.videos ? (
									<div>
										<i
											className={'tim-icons icon-video-66 text-info'}
											style={{ fontSize: '25px', float: 'left' , marginRight:'15px'}}
										/>
										<h6> Videos de {selected.titulo}</h6>
									</div>
								) : (
									''
								)
							) : (
								''
							)}
						</Col>
						</Row>
						<Row className="ml-auto mr-auto text-center" md="12">
						<Col className="ml-auto mr-auto text-left" md="9" style={{ marginTop: '10px' }}>
							
							{selected
								? selected.videos
									? _.map(videos, x => {
											return (
												<>
												<Button
													key={x}
													className="btn-simple"
													style={{ padding: '0px', border: '0' }}
													onClick={e => {
														this.htmlAlert(selected.titulo, x);
													}}
												>
													<img
														key={x}
														alt={x}
														src={
															'https://d39etc8ensw3fs.cloudfront.net/fit-in/200x100/' +
															x +
															'.png'
														}
														width="200px"
													/>
												</Button>
												</>
											);
									  })
									: ''
								: ''}
						</Col>
					</Row>
					<Row className="ml-auto mr-auto text-center" md="12">
						<Col className="ml-auto mr-auto text-center" md="9" style={{ marginTop: '50px' }}>
							<h6 className="text-left">
								<i className={'tim-icons icon-square-pin text-success'} style={{ fontSize: '25px' }} />
								Mapa ubicación
							</h6>
							<p className="text-left">{selected ? 'Dirección: ' + selected.direccion : ''}</p>
							<SatelliteMap
								selected={selected}
								googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyCuRvqGwte0YrAD0azdqKRO1FbvNlvHBTs"
								loadingElement={<div style={{ height: `100%` }} />}
								containerElement={
									<div
										style={{
											height: `500px`,
											borderRadius: `.2857rem`,
											overflow: `hidden`,
										}}
									/>
								}
								mapElement={<div style={{ height: `100%` }} />}
							/>
						</Col>
					</Row>
					<Row className="ml-auto mr-auto text-center" md="12">
						<Col className="ml-auto mr-auto text-center" md="9" style={{ marginTop: '50px' }} >
						{selected ?
							<div>
							<h6 className="text-left">
								<i className={'tim-icons icon-send text-info'} style={{ fontSize: '25px' }} />
								Compartir en tus redes sociales
							</h6>
							<Shared shareUrl={"https://vivipiria.uy/playa/"+this.props.ids} title={selected.nombre} exampleImage={this.getImage(selected.imagen)}/></div> : "" }
						
						</Col>
					</Row>
				</Container>
				<Container fluid style={{marginTop:"20px"}}>
				<Row className="ml-auto mr-auto text-center" md="9">
						<Col className="ml-auto mr-auto text-center" md="9" style={{ marginTop: '50px' }}>
							<Comments ids={this.props.ids}/>
						</Col>
					</Row>
				</Container>
				<Footer/>
			</>
		);
	}
}

const mapStateToProps = state => {
	//console.log(JSON.stringify(state));
	return {
		...state.reducers.homeReducer,
	};
};

const mapDispatchToProps = dispatch => {
	return {
		changeToPlayaById: x => {
			dispatch(playaById(x));
		},
		goTo: () => {
			dispatch(goBack());
		},
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(PaseoById);
